import { Company } from '../../data_model/Company';
import { User } from '../../data_model/User';
import { createFeatureSelector, createSelector} from '@ngrx/store';
import { AppState } from '../reducers';
import {CompanyState} from '../reducers/company.reducer';
import {AuthState} from '../reducers/auth.reducer';




// Auth Selectors //

export const selectAuthState = createFeatureSelector<AuthState>('auth');

export const isLoggedIn = createSelector(
  selectAuthState,
  auth => auth.loggedIn
);

export const isLoggedOut =  createSelector(
  isLoggedIn,
  loggedIn => !loggedIn
);


// User Selectors //

export const loggedInUser = createSelector(
  selectAuthState,
  auth => auth.user
);

export const loggedInUserRole = createSelector(
  loggedInUser,
  usr => usr.Role
);

export const loggedInUserIsSystemAdmin = createSelector (
  loggedInUserRole,
  usrRole => usrRole === '/Roles/1'
);

