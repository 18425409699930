import { Component, OnChanges, OnInit, Input, AfterViewInit, ChangeDetectionStrategy } from '@angular/core';
import { SwiperConfigInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';
import { Slide } from '../../common/data_model/Slide';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';


@Component({
  selector: 'app-main-carousel',
  templateUrl: './main-carousel.component.html',
  styleUrls: ['./main-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class MainCarouselComponent implements OnInit, AfterViewInit {
  @Input() slides$: Observable<Slide[]>;
  @Input() langArrCode: string;

  public config: SwiperConfigInterface = {};


  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true
  };

  constructor(public router: Router) { }

  ngOnInit() {

  }

  ngAfterViewInit() {

    this.config = {
      slidesPerView: 1,
      spaceBetween: 0,
      keyboard: true,
      navigation: true,
      pagination: this.pagination,
      grabCursor: true,
      loop: false,
      preloadImages: false,
      lazy: true,
      autoplay: {
        delay: 6000,
        disableOnInteraction: false
      },
      speed: 500,
      effect: 'slide'
    };
  }

  findCurrLanguagePos(someArray: any[]): number {
    return someArray.findIndex(e => e.lang === this.langArrCode);
  }

  gotoRooms() {
    this.router.navigateByUrl('/rooms');
  }


}
