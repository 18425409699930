import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { User } from '../../data_model/User';


export enum UsersActionTypes {

  UserRequested = '[Users] Specific User Requested',
  UserLoaded = '[Users API] Specific User Loaded',
  UsersRequested = '[Users] All Users Requested',
  UsersLoaded = '[Users API] All Users Loaded',

  UsersOfCompanyRequested = '[Users] Users for a specific Company Requested',
  UsersOfCompanyLoaded = '[Users API] Users for a specific Company Loaded',

  UserSaved =  '[Users] User Saved',
}


export class UserRequested implements Action {

  readonly type = UsersActionTypes.UserRequested;

  constructor(public payload: { userId: string }) {
  }

}


export class UserLoaded implements Action {

  readonly type = UsersActionTypes.UserLoaded;

  constructor(public payload: { user: User }) {

  }
}


export class UsersRequested implements Action {

  readonly type = UsersActionTypes.UsersRequested;

}


export class UsersLoaded implements Action {

  readonly type = UsersActionTypes.UsersLoaded;

  constructor(public payload: { users: User[] }) {

  }
}


export class UsersOfCompanyRequested implements Action {

  readonly type = UsersActionTypes.UsersOfCompanyRequested;

  constructor(public payload: { companyId: string }) {
  }

}

export class UsersOfCompanyLoaded implements Action {

  readonly type = UsersActionTypes.UsersOfCompanyLoaded;

  constructor(public payload: { users: User[] }) {

  }
}


export class UserSaved implements Action {

  readonly type = UsersActionTypes.UserSaved;

  constructor(public payload: { user: Update<User> }) {
  }

}


export type UsersActions =
  UserRequested |
  UserLoaded |
  UsersRequested |
  UsersLoaded |
  UsersOfCompanyRequested |
  UsersOfCompanyLoaded |
  UserSaved;

