import { Injectable } from '@angular/core';

import {withLatestFrom, mergeMap, map, filter, tap} from 'rxjs/operators';

import {select, Store} from '@ngrx/store';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { AppState } from '../reducers';
import { RoomsActionTypes, AllRoomsRequested, AllRoomsLoaded } from '../actions/rooms.actions';
import { RoomsService } from '../../services/rooms.service';

import { selectHotelId } from '../selectors/company.selectors';
import { allRoomsLoaded } from '../selectors/rooms.selectors';
import {HotelRoomTypesRequested} from '../actions/hotelroomtypes.actions';



@Injectable()
export class RoomsEffects {

  @Effect()
  loadAllHotelRooms$ = this.actions$
    .pipe(
        ofType<AllRoomsRequested>(RoomsActionTypes.AllRoomsRequested),
        withLatestFrom(this.store.pipe(select(allRoomsLoaded))),
        filter(([action, roomsLoaded]) => !roomsLoaded),
        // withLatestFrom(this.store.pipe(select(selectHotelId))),
        // mergeMap((action, cId) => this.roomsService.getAllRooms(cId.toString())),
        mergeMap(action => this.roomsService.getAllRoomsForCompany('2')),
        map(rooms => new AllRoomsLoaded({rooms}))
    );

  /*
  loadHoteRoomTypes$ = this.actions$
    .pipe(
      ofType<AllRoomsRequested>(RoomsActionTypes.AllRoomsRequested),
      withLatestFrom(this.store.pipe(select(selectHotelId))),
      // map(([noop, cId]) => new HotelRoomTypesRequested({ companyId: cId }))
      map(rms => new HotelRoomTypesRequested({ companyId: '2' }))
    );

  */


  constructor(private actions$: Actions,
              private store: Store<AppState>,
              private roomsService: RoomsService) {}

}
