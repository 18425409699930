import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { MatDialog } from '@angular/material/dialog';

import {NgxSpinnerService} from 'ngx-spinner';
import {FirestoreService} from '../../../../core/firestore.service';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../common/store/reducers';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {Reservation} from '../../../../common/data_model/Reservation';
import {ActivatedRoute} from '@angular/router';
import {selectReservationById} from '../../../../common/store/selectors/reservations.selectors';
import { selectSystemCurrency } from '../../../../common/store/selectors/company.selectors';

import { ReservationDialogComponent } from './../reservation-dialog/reservation-dialog.component';


@Component({
  selector: 'app-view-edit-reservation',
  templateUrl: './view-edit-reservation.component.html',
  styleUrls: ['./view-edit-reservation.component.scss'],
})

export class ViewEditReservationComponent implements OnInit {

  reservationId: string;

  public reservation$: Observable<Reservation>;

  public systemCurrency$: Observable<string>;


  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public store: Store<AppState>,
    public dialog: MatDialog,
    public fs: FirestoreService,
    public spinner: NgxSpinnerService,
    public translate: TranslateService
  ) {}

  ngOnInit() {

    this.reservationId = this.route.snapshot.params.id;

    this.reservation$ = this.store
      .pipe(
        select(selectReservationById(this.reservationId))
      );

    this.systemCurrency$ = this.store
      .pipe(
        select(selectSystemCurrency)
      );

    this.showReservation();
  }

  showReservation() {
    const dialogRef = this.dialog.open(ReservationDialogComponent, {
      width: '90%',
      height: '90%',
      data: {
        mode: 'view',
        reservationId: this.reservationId
      }
    });
  }

}
