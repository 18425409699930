import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'booleanToIcon'
})
export class BooleanToIconPipe implements PipeTransform {

  transform(value: boolean): string {
    return value === true ? 'Yes' : 'No'
  }

}
