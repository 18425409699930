import { Action } from '@ngrx/store';
import { Interval } from '../../data_model/Inventory';



export enum IntervalsActionTypes {
  IntervalRequested = '[Setup] Interval Requested',
  IntervalLoaded = '[Inventory API] Interval Loaded',
  AllIntervalsForOpPeriodRequested = '[Setup] All Intervals Requested',
  AllIntervalsForOpPeriodLoaded = '[Inventory API] All Intervals Loaded'
}

export class IntervalRequested implements Action {

  readonly type = IntervalsActionTypes.IntervalRequested;

  constructor(public payload: { intervalId: string }) {

  }

}


export class IntervalLoaded implements Action {

  readonly type = IntervalsActionTypes.IntervalLoaded;

  constructor(public payload: { interval: Interval }) {

  }

}


export class AllIntervalsForOpPeriodRequested implements Action {

  readonly type = IntervalsActionTypes.AllIntervalsForOpPeriodRequested;

  constructor(public payload: { compId: string, opPeriodId: string }) {

  }

}


export class AllIntervalsForOpPeriodLoaded implements Action {

  readonly type = IntervalsActionTypes.AllIntervalsForOpPeriodLoaded;

  constructor(public payload: { intervals: Interval[] }) {

  }

}


export type IntervalsActions =
  IntervalRequested |
  IntervalLoaded |
  AllIntervalsForOpPeriodRequested |
  AllIntervalsForOpPeriodLoaded ;

