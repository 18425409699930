
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../reducers';
import { CompanyState } from '../reducers/company.reducer';
import { AuthState } from '../reducers/auth.reducer';
import { AvailabilitySearchState } from '../reducers/availabilitysearch.reducer';




// Auth Selectors //

export const selectAvailabilitySearchState = createFeatureSelector<AvailabilitySearchState>('availabilitySearch');

export const selectCheckInDate = createSelector(
  selectAvailabilitySearchState,
  scrit => scrit.checkIn
);


export const selectCheckOutDate = createSelector(
  selectAvailabilitySearchState,
  scrit => scrit.checkOut
);

export const selectAdults = createSelector(
  selectAvailabilitySearchState,
  scrit => scrit.adults
);

export const selectChildren = createSelector(
  selectAvailabilitySearchState,
  scrit => scrit.children
);

