import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BookingSourcesState } from '../reducers/bookingsources.reducer';
import * as fromBookingSources from '../reducers/bookingsources.reducer';

export const selectBookingSourcesState = createFeatureSelector<BookingSourcesState>('bookingsources');


export const selectBookingSourceByCode = (bookingSourceCode: string) => createSelector(
  selectBookingSourcesState,
  bookingSourcesState => bookingSourcesState.entities[bookingSourceCode]
);


export const selectAllBookingSources = createSelector(
  selectBookingSourcesState,
  fromBookingSources.selectAll
);

export const allBookingSourcesLoaded = createSelector(
  selectBookingSourcesState,
  bookingSourcesState => bookingSourcesState.allBookingSourcesLoaded
);


