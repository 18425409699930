import { Injectable } from '@angular/core';

import { withLatestFrom, mergeMap, map, filter } from 'rxjs/operators';

import {select, Store} from '@ngrx/store';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { AppState } from '../reducers';
import { PropertyTypesActionTypes, AllPropertyTypesRequested, AllPropertyTypesLoaded } from '../actions/propertytypes.actions';
import { RoomsService } from '../../services/rooms.service';

import { allPropertyTypesLoaded } from '../selectors/propertytypes.selectors';


@Injectable()
export class PropertyTypesEffects {

  @Effect()
  loadAllPropertyTypes$ = this.actions$
    .pipe(
        ofType<AllPropertyTypesRequested>(PropertyTypesActionTypes.AllPropertyTypesRequested),
        withLatestFrom(this.store.pipe(select(allPropertyTypesLoaded))),
        filter(([action, amenitiesLoaded]) => !amenitiesLoaded),
        mergeMap(action => this.roomsService.getAllPropertyTypes()),
        map(propertytypes => new AllPropertyTypesLoaded({propertytypes}))
    );

  constructor(private actions$: Actions,
              private store: Store<AppState>,
              private roomsService: RoomsService) {}

}
