import { Injectable } from '@angular/core';

import { mergeMap, map } from 'rxjs/operators';

import {Store} from '@ngrx/store';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { AppState } from '../reducers';
import { InventoryService } from '../../services/inventory.service';

import {AllIntervalsForOpPeriodLoaded, AllIntervalsForOpPeriodRequested, IntervalsActionTypes} from '../actions/intervals.actions';


@Injectable()
export class IntervalsEffects {

  @Effect()
  loadAllIntervalsForOpPeriod$ = this.actions$
    .pipe(
      ofType<AllIntervalsForOpPeriodRequested>(IntervalsActionTypes.AllIntervalsForOpPeriodRequested),
      mergeMap(action => this.inventoryService.getAllIntervalsForOpPeriod(action.payload.compId, action.payload.opPeriodId)),
      map(intervals => new AllIntervalsForOpPeriodLoaded({ intervals }))
    );

  constructor(private actions$: Actions,
              private inventoryService: InventoryService) {}

}
