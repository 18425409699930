import { Injectable } from '@angular/core';

import { withLatestFrom, mergeMap, map, filter } from 'rxjs/operators';

import {select, Store} from '@ngrx/store';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { AppState } from '../reducers';
import { RoomTypesActionTypes, AllRoomTypesRequested, AllRoomTypesLoaded } from '../actions/roomtypes.actions';
import { RoomsService } from '../../services/rooms.service';

import { allRoomTypesLoaded } from '../selectors/roomtypes.selectors';


@Injectable()
export class RoomTypesEffects {

  @Effect()
  loadAllRoomTypes$ = this.actions$
    .pipe(
        ofType<AllRoomTypesRequested>(RoomTypesActionTypes.AllRoomTypesRequested),
        withLatestFrom(this.store.pipe(select(allRoomTypesLoaded))),
      filter(([action, allRoomTypesLoaded]) => !allRoomTypesLoaded),
        mergeMap(action => this.roomsService.getAllRoomTypes()),
        map(roomtypes => new AllRoomTypesLoaded({roomtypes}))
    );

  constructor(private actions$: Actions,
              private store: Store<AppState>,
              private roomsService: RoomsService) {}

}
