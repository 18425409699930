import { createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import { HotelRoomType } from '../../data_model/HotelRoomType';
import { HotelRoomTypesActions, HotelRoomTypesActionTypes } from '../actions/hotelroomtypes.actions';
import { AppState } from '.';



export interface HotelRoomTypesState extends EntityState<HotelRoomType> {
  allHotelRoomTypesLoaded: boolean;
}

function sortById(h1: HotelRoomType, h2: HotelRoomType) {
  return h1.id.localeCompare(h2.id);
}

export const HotelRoomTypesAdapter: EntityAdapter<HotelRoomType> = createEntityAdapter<HotelRoomType>({
  sortComparer: sortById
});

export const initialHotelRoomTypesState: HotelRoomTypesState = HotelRoomTypesAdapter.getInitialState({
  allHotelRoomTypesLoaded: false
});

export function HotelRoomTypesReducer(state = initialHotelRoomTypesState, action: HotelRoomTypesActions): HotelRoomTypesState {

  switch (action.type) {

    case HotelRoomTypesActionTypes.HotelRoomTypeLoaded:
      return HotelRoomTypesAdapter.addOne(action.payload.hotelRoomType, state);

    case HotelRoomTypesActionTypes.HotelRoomTypesLoaded:
      return HotelRoomTypesAdapter.addAll(action.payload.hotelRoomTypes, { ...state, allHotelRoomTypesLoaded: true });

    default:
      return state;
  }
}


export const {
  selectAll,
  selectIds,
  selectEntities,
  selectTotal
} = HotelRoomTypesAdapter.getSelectors();
