import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {NgxSpinnerService} from 'ngx-spinner';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../common/store/reducers';
import {TranslateService} from '@ngx-translate/core';
import { _TRANSLATE } from '../../../../../common/services/translation-marker';
import {HistoryInfo, Reservation} from '../../../../../common/data_model/Reservation';
import {SelectionModel} from '@angular/cdk/collections';

@Component({
  selector: 'app-view-edit-reservation-notes',
  templateUrl: './view-edit-reservation-notes.component.html',
  styleUrls: ['./view-edit-reservation-notes.component.scss'],
})


export class ViewEditReservationNotesComponent implements OnInit {

  @Input() reservation: Reservation;

  public reservationGuestNotes: string;
  public reservationHotelNotes: string;


  constructor(
    public store: Store<AppState>,
    public spinner: NgxSpinnerService,
    public translate: TranslateService
  ) {}

  ngOnInit() {

    const reservationid = this.reservation.id;

    this.reservationGuestNotes = this.reservation.Remarks_Guest;

    this.reservationHotelNotes = this.reservation.Remarks_Hotel;

  }
}
