import { HotelFeatureImage } from './../../data_model/HotelFeatureImage';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CompanyState } from '../reducers/company.reducer';
import { map, flatMap, mergeMap } from 'rxjs/operators';


export const selectCompanyState = createFeatureSelector<CompanyState>('company');

export const selectCompany =  createSelector (
    selectCompanyState,
    comp => comp
);

export const selectCompanyAccountInfo = createSelector (
  selectCompanyState,
  comp => comp.Account_Info
);


export const selectCompanySetup = createSelector (
  selectCompanyState,
  comp => comp.Setup
);

export const selectSystemSettings = createSelector(
  selectCompanyState,
  comp => comp.System_Settings
);

export const selectSystemCurrency = createSelector(
  selectSystemSettings,
  systSett => systSett.Currency
);

export const selectHotelId = createSelector (
    selectCompanyState,
    comp => comp.id
);

export const selectHotelName = createSelector (
    selectCompanyState,
    comp => comp.Trade_Name
);

export const selectHotelUILanguages = createSelector(
  selectSystemSettings,
  systSett => systSett.UI_Languages
);

export const selectFrontPage = createSelector(
  selectCompanyState,
  comp => comp.Front_Page
);

export const selectHotelAboutUs = createSelector (
  selectCompanyState,
  comp => comp.About_us
);


export const selectHotelFeatureImages = createSelector (
    selectCompanyState,
    comp => comp.Feature_images
);


export const selectHotelBannerImages = createSelector(
  selectCompanyState,
  comp => comp.Banner_Images
);

export const selectEatNDrink = createSelector(
  selectCompanyState,
  comp => comp.Eat_N_Drink
);

export const selectHotelRoomFeatureImage = createSelector(
  selectCompanyState,
  comp => comp.Room_Feature_Image_url
);


export const selectHasPublicWebSite = createSelector(
  selectCompanyState,
  comp => comp.Account_Info.Has_Public_Website
);

export const selectHasBackoffice = createSelector(
  selectCompanyState,
  comp => comp.Account_Info.Has_Backoffice
);

export const selectCompAllSetup = createSelector(
  selectCompanyState,
  comp => comp.Setup.AllSetup
);


export const selectProfileHasBeenSetup = createSelector(
  selectCompanyState,
  comp => comp.Setup.step1.profile
);

export const selectHasWebSiteAndIsSetup = createSelector(
  selectHasPublicWebSite, selectProfileHasBeenSetup,
  (webs, stup) => (webs === true && stup === true)
);


