import {filter, first, map, mapTo} from 'rxjs/operators';
import { Injectable, OnInit } from '@angular/core';

import { User, Role } from '../data_model/User';
import { Company } from '../data_model/Company';
import { AuthService } from '../../core/auth.service';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { FirestoreService } from '../../core/firestore.service';
import { Observable ,  BehaviorSubject } from 'rxjs';

import { Settings, AppSettings } from '../../app.settings';


@Injectable()
export class UsersService {

  public static SESSION_STORAGE_KEY = 'accessToken';

  private static readonly  CURRENT_USER_COMPANY = 0;
  private static readonly  CURRENT_USER_DOCTOR = 1;

  private _currentUser: BehaviorSubject<any>;
  private _currentUserMedicalData: BehaviorSubject<AngularFirestoreDocument<User>[]>;

  // private googleUser: GoogleUser;
  public currentUserId: string;
  public user: any;
  public comp: any;
  public doctor: any;
  public role: any;
  public settings: Settings;


  constructor(
    private _auth: AuthService,
    private _fsSrvc: FirestoreService,
    private db: AngularFirestore,
    public appSettings: AppSettings) {

    this.settings = this.appSettings.settings;
  }

  public changeTheme(theme) {
    this.settings.theme = theme;
  }

  public getToken(): string {
    const token: string = sessionStorage.getItem(UsersService.SESSION_STORAGE_KEY);
    if (!token) {
        throw new Error('no token set , authentication required');
    }
    return sessionStorage.getItem(UsersService.SESSION_STORAGE_KEY);
  }

  public updateUserData(userKey: string, newData: User): boolean {
    console.log(userKey);
    console.log(newData);
    let opSuccess;
    this._fsSrvc.update('/Users/' + userKey, newData)
      .then(
        () => {
          this.showMessage('success', 'Success', 'User profile updated successfully');
          opSuccess = true;
        },
        () => {
          this.showMessage('error', 'Error', 'Error updating user profile');
          opSuccess = false;
        }
      );
    return opSuccess;
  }


  public getUILanguages(): Observable<any[]> {
    return this._fsSrvc.colWithIds$('/Languages');
  }

  public getCompanies(): Observable<any[]> {
    const companies = this._fsSrvc.colWithIds$('/Companies');
    if (this.getCurrentUserCompId() !== 'WAD') {
      companies.pipe(
        filter((company, index) => company[index].id === this.getCurrentUserCompId() )
      );
    }
    return companies;
  }

  private signInSuccessHandler(res: any) {
        this.user = res;
        sessionStorage.setItem(
            UsersService.SESSION_STORAGE_KEY, res.getAuthResponse().access_token
        );
  }

  public getAllUsers(): Observable<User[]> {
    return this._fsSrvc.colWithIds$<User>('/Users');
  }

  public getAllUsersForCompany(companyId: string): Observable<User[]> {
    return this._fsSrvc.colWithIds$<User>('/Users', ref => ref
      .where('Company', '==', 'Company/' + companyId)
    );
  }

  public getUserData(userId: string): Observable<User> {
    return this._fsSrvc.doc$<User>('Users/' + userId);
  }



  public getCurrentUserCompRef(): string {
    console.log('Entered in function');
    if (this.user) {
      return this.user.Company ;
    }
  }

  public getCurrentUserData(): Observable<User> {
    return this._auth.authState.User;
  }

  public getCurrentUserUserId(): string {
    if (this.user) {
      return this.user.id;
    }
  }

  public getCurrentUserCompId(): string {
    if (this.user) {
      return this.user.Company.id ;
    }
  }

  public getCurrentUserCompName(): string {
    if (this.user) {
      return this.user.Company.Legal_Name ;
    }
  }

  public getCurrentUserDoctorRef(): string {
    if (this.user) {
      return this.user.Doctor ;
    }
  }

  public getCurrentUserDoctorId(): string {
    if (this.user) {
      return this.user.Doctor.id ;
    }
  }

  public getCurrentUserLanguageRef(): string {
    console.log(this.user.value);
    if (this.user) {
      return this.user.Language ;
    }
  }

  public isCurrentUserCompanyAdmin(): boolean {
    if (this.user) {
      return this.user.CompanyAdmin ;
    }
  }


  public getUsersByCompRef(compRef: string): Observable<User[]> {
    return this._fsSrvc.colWithIds$<User[]>('Users',
        ref => ref
          .where('Company', '==', compRef)) ;
  }


  public getUsersByDocRef(docRef: string): Observable<User[]> {
    return this._fsSrvc.colWithIds$<User[]>('Users',
        ref => ref
          .where('Doctor', '==', docRef)) ;
  }

  public getUsersByCompRefAndDocRef(compRef: string, docRef: string): Observable<User[]> {
    return this._fsSrvc.colWithIds$<User[]>('Users',
        ref => ref
          .where('Company', '==', compRef)
          .where('Doctor', '==', docRef)) ;
  }

  public getRoleNameByRoleRef(roleRef: string): Observable<String> {
    return this._fsSrvc.doc$<Role>(roleRef)
      .pipe(
        map( role => role.Name )
      );
  }


  public getAllRoles(): Observable<Role[]> {
    return this._fsSrvc.colWithIds$<Role[]>('Roles');
  }

  showMessage(type: string, summary: string, msg: string) {
    alert(msg);
    // his.messageService.add({severity: type, summary: summary, detail: msg});
  }


}
