import { createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import { BookingSource } from '../../data_model/BookingSources';
import { BookingSourcesActions, BookingSourcesActionTypes } from '../actions/bookingsources.actions';


export interface BookingSourcesState extends EntityState<BookingSource> {
  allBookingSourcesLoaded: boolean;
}

function sortByCode(b1: BookingSource, b2: BookingSource) {
  return +b1.Code.localeCompare(b2.Code);
}

export const bookingSourcesAdapter: EntityAdapter<BookingSource> = createEntityAdapter<BookingSource>({
  sortComparer: sortByCode
});

export const initialBookingSourcesState: BookingSourcesState = bookingSourcesAdapter.getInitialState({
  allBookingSourcesLoaded: false
});

export function bookingSourcesReducer( state = initialBookingSourcesState, action: BookingSourcesActions): BookingSourcesState {

    switch (action.type) {

      case BookingSourcesActionTypes.BookingSourceLoaded:
        return bookingSourcesAdapter.addOne(action.payload.bookingSource, state);

      case BookingSourcesActionTypes.AllBookingSourcesLoaded:
        return bookingSourcesAdapter.addAll(action.payload.bookingSources, {...state, allBookingSourcesLoaded: true});


      default:
        return state;
    }
}


export const {
  selectAll,
  selectIds,
  selectEntities,
  selectTotal
} = bookingSourcesAdapter.getSelectors();
