import { createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import { RoomType } from '../../data_model/RoomType';
import { RoomTypesActionTypes, RoomTypesActions } from '../actions/roomtypes.actions';
import { AppState } from '.';


export interface RoomTypesState extends EntityState<RoomType> {
  allRoomTypesLoaded: boolean;
}

function sortById(bt1: RoomType, bt2: RoomType) {
  return +bt1.id - +bt2.id;
}

export const roomtypesAdapter: EntityAdapter<RoomType> = createEntityAdapter<RoomType>({
  sortComparer: sortById
});

export const initialRoomTypesState: RoomTypesState = roomtypesAdapter.getInitialState({
  allRoomTypesLoaded: false
});

export function roomtypesReducer( state = initialRoomTypesState, action: RoomTypesActions): RoomTypesState {

    switch (action.type) {

      case RoomTypesActionTypes.RoomTypeLoaded:
        return roomtypesAdapter.addOne(action.payload.roomtype, state);

      case RoomTypesActionTypes.AllRoomTypesLoaded:
        return roomtypesAdapter.addAll(action.payload.roomtypes, {...state, allRoomTypesLoaded: true});


      default:
        return state;
    }
}


export const {
  selectAll,
  selectIds,
  selectEntities,
  selectTotal
} = roomtypesAdapter.getSelectors();
