import { Action } from '@ngrx/store';
import { SearchAvailabilityCriteria } from '../../data_model/Inventory';


export enum AvailabilitySearchActionTypes {
  SetCtiteria = '[Availability Search] Search Criteria'
}


export class SetCtiteria implements Action {

  readonly type = AvailabilitySearchActionTypes.SetCtiteria;

   constructor(public payload: { searchCriteria: SearchAvailabilityCriteria }) {

  }
}


export type AvailabilitySearchhActions = SetCtiteria;
