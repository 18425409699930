import { HotelRoomType } from './../data_model/HotelRoomType';
import { Setup } from './../data_model/Company';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import {map, take, tap, delay} from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { FirestoreService } from '../../core/firestore.service';

import { Company } from '../data_model/Company';
import {selectCompany} from '../store/selectors/company.selectors';
import {AppState} from '../store/reducers';
import {select, Store} from '@ngrx/store';
import { selectAllHotelRoomTypes } from '../store/selectors/hotelroomtypes.selectors';
import { RoomsService } from './rooms.service';




@Injectable()
export class CompanyService {

  companyHotelRoomTypes: HotelRoomType[];

  constructor(
    private _fsSrvc: FirestoreService,
    private db: AngularFirestore,
    private store: Store<AppState>,
    private roomsService: RoomsService,
    private router: Router) {
  }

  createCompany(newCompany: Company) {
    delete newCompany.id;
    return this.db.collection('/Company').add(newCompany);
  }

  public getCompanyByUrl(webUrl: string): Observable<Company[]> {
    return this._fsSrvc.colWithIds$<Company>('/Company', ref => ref
      .where('website_url', '==', webUrl));
  }

  public getCompanyById(cId: string): Observable<Company> {
    return this._fsSrvc.doc$<Company>('Company/' + cId);
  }

  public getCompanyIdByDbPath(dbPath: string): string {
    const firstSeparator = dbPath.indexOf('/');
    const secondSeparator = dbPath.indexOf('/', firstSeparator + 1);
    const compCode = dbPath.substr(firstSeparator + 1, secondSeparator - firstSeparator - 1);
    return compCode;
  }


  public getCompanyByUserCompanyRef(companyRef: string): Observable<Company> {
    return this._fsSrvc.doc$<Company>(companyRef);
  }


  public getAllCompanies(): Observable<Company[]> {
    return this._fsSrvc.col$<Company>('Company');
  }

  public checkSetupParamaters(company: Company) {

    const companyId = company.id;

    this.roomsService.getAllHotelRoomTypesForCompany(companyId)
      .pipe(
        delay(0),
        take(1)
      )
      .subscribe(hrts => this.companyHotelRoomTypes = hrts);

    const companySetup = company.Setup;

    // Check if setup has been completted
    if (!companySetup.AllSetup) {

      // Check if different elements of step 1 have been setup

      const step1SystemProfileOk =
        company.System_Settings.Currency !== '' &&
        company.System_Settings.UI_Languages.length > 1 &&
        company.System_Settings.Color_scheme !== '';


      const step1ProfileOk =
        company.Property_Type !== '' &&
        company.Trade_Name !== '' &&
        company.About_us.length >= 1 &&
        (company.Address.Country !== '' && company.Address.City !== '' && company.Address.Street1 !== '') &&
        company.eMail !== '' &&
        company.website_url !== '';

      let step1AccTypesOk =
        this.companyHotelRoomTypes.length > 0;

      if (step1AccTypesOk) {
        let amenitiesOk = true;
        let bedsOk = true;
        let descriptionOk = false;
        let photosOk = true;
        let roomsOk = true;

        let i;
        for (i = 0; i < this.companyHotelRoomTypes.length; i++) {

          if (this.companyHotelRoomTypes[i].Amenities.length === 0) {
            amenitiesOk = false;
            break;
          }

          if (this.companyHotelRoomTypes[i].Beds.length === 0) {
            bedsOk = false;
            break;
          }

          let j;
          for (j = 0; j < this.companyHotelRoomTypes[i].Description.length; j++) {
            if (this.companyHotelRoomTypes[i].Description[j].text !== '') {
              descriptionOk = true;
              break;
            }
          }

          if (this.companyHotelRoomTypes[i].Photos.length === 0) {
            photosOk = false;
            break;
          }

          if (this.companyHotelRoomTypes[i].Rooms.length === 0) {
            roomsOk = false;
            break;
          }

        }

        step1AccTypesOk = amenitiesOk && bedsOk && descriptionOk && photosOk && roomsOk;
      }

      // const step1AvailabilityMatrixOk =

      if (!companySetup.step1.profile) {
        this.router.navigateByUrl('/coreinfo/propertyprofile');
      }
      if (!companySetup.step1.accommodation_types) {
        this.router.navigateByUrl('/coreinfo/accommodationtypes');
      }
      if (!companySetup.step1.availability_matrix) {
        this.router.navigateByUrl('/coreinfo/availabilitymatrix');
      }
      if (!companySetup.step1.taxes_fees) {
        this.router.navigateByUrl('/coreinfo/taxesandfees');
      }
      if (!companySetup.step1.other_products_services) {
        this.router.navigateByUrl('/coreinfo/otherproductsandservices');
      }
      if (!companySetup.step1.reservation_sources) {
        // this.router.navigateByUrl('/coreinfo/otherproductsandservices');
      }
      if (!companySetup.step1.policies) {
        // this.router.navigateByUrl('/coreinfo/otherproductsandservices');
      }

      // Check if different elements of step 2 have been setup
      if (!companySetup.step2.mapping) {
        // this.router.navigateByUrl('/coreinfo/propertyprofile');
      }
      if (!companySetup.step2.allotment) {
        // this.router.navigateByUrl('/coreinfo/propertyprofile');
      }
      if (!companySetup.step2.channels) {
        // this.router.navigateByUrl('/coreinfo/propertyprofile');
      }
    }
  }

}

