import { selectCompany, selectHasWebSiteAndIsSetup, selectHasPublicWebSite } from './../selectors/company.selectors';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild} from '@angular/router';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import { AuthService } from '../../../core/auth.service';

import { AppState } from '../reducers';
import { isLoggedIn, loggedInUser, loggedInUserIsSystemAdmin, loggedInUserRole } from '../selectors/auth.selectors';
import { selectCompanySetup } from '../selectors/company.selectors';


@Injectable()
export class AuthGuard implements CanActivate {

  constructor ( private store: Store<AppState>, private router: Router ) {}

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> {

    return this.store
      .pipe(
        select(isLoggedIn),
        tap(loggedIn => {
          if (!loggedIn) {
            this.router.navigateByUrl('/sign-in');
          }
        })
      );
  }

}

@Injectable()
export class SystemAdminGuard implements CanActivate {

  constructor( private store: Store<AppState>, private router: Router ) {}

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> {

    return this.store
      .pipe(
        select(loggedInUserIsSystemAdmin),
        tap(isSystemAdmin => {
          if (!isSystemAdmin) {
            this.router.navigateByUrl('/not-found');
          }
        })
      );
  }

}

@Injectable()
export class PublicWebSiteGuard implements CanActivate {

  constructor(private store: Store<AppState>, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> {

    return this.store
      .pipe(
        select(selectHasPublicWebSite),
        tap(isOk => {
          if (isOk) {
            this.router.navigateByUrl('home');
          }
        })
      );
  }

}


@Injectable()
export class DummyTrueGuard implements CanActivate {

  constructor(private store: Store<AppState>, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> {

    return of(true);
  }

}
