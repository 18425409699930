import { createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import { Room } from '../../data_model/Room';
import { RoomsActionTypes, RoomsActions } from '../actions/rooms.actions';
import { AppState } from '.';


export interface RoomsState extends EntityState<Room> {
  allRoomsLoaded: boolean;
}

function sortByName(r1: Room, r2: Room) {
  return +r1.Name.localeCompare(r2.Name);
}

export const roomsAdapter: EntityAdapter<Room> = createEntityAdapter<Room>({
  sortComparer: sortByName
});

export const initialRoomsState: RoomsState = roomsAdapter.getInitialState({
  allRoomsLoaded: false
});

export function roomsReducer( state = initialRoomsState, action: RoomsActions): RoomsState {

    switch (action.type) {

      case RoomsActionTypes.RoomLoaded:
        return roomsAdapter.addOne(action.payload.room, state);

      case RoomsActionTypes.AllRoomsLoaded:
        return roomsAdapter.addAll(action.payload.rooms, {...state, allRoomsLoaded: true});

      default:
        return state;
    }
}


export const {
  selectAll,
  selectIds,
  selectEntities,
  selectTotal
} = roomsAdapter.getSelectors();
