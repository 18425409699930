import { Component, OnInit} from '@angular/core';
import { Observable } from 'rxjs';

import { Store, select } from '@ngrx/store';
import { AppState } from '../../common/store/reducers';
import { selectCompany } from './../../common/store/selectors/company.selectors';

import { Company } from '../../common/data_model/Company';

import { TranslateService } from '@ngx-translate/core';
import { _TRANSLATE } from '../../common/services/translation-marker';



@Component({
  selector: 'app-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.scss']
})
export class ComingSoonComponent implements OnInit {
  company$: Observable<Company>;

  constructor(
    private store: Store<AppState>,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.company$ = this.store
      .pipe(
        select(selectCompany)
      );
  }

}
