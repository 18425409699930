import { Action } from '@ngrx/store';
import {Reservation, ReservationsFilters} from '../../data_model/Reservation';
import {Update} from '@ngrx/entity';


export interface PageQuery {
  pageIndex: number;
  pageSize: number;
}

export enum ReservationsActionTypes {
  ReservationRequested = '[Reservations] Specific Reservation Requested',
  ReservationLoaded = '[Reservations API] Specific Reservation Loaded',
  ReservationsRequested = '[Reservations] All Reservations Requested',
  ReservationsLoaded = '[Reservations API] All Reservations Loaded',

  ReservationsForPeriodRequested = '[Reservations] Reservations for a period of time Requested',
  ReservationsForPeriodLoaded = '[Reservations API] Reservations for a period of time Loaded',

  ReservationSaved =  '[Reservations] Reservation Saved',

  ReservationsPageRequested = '[Reservations] Reservations Page Requested',
  ReservationsPageLoaded = '[Reservations API] Reservations Page Requested',
  ReservationsPageCancelled = '[Reservations API] Reservations Page Cancelled'
}



export class ReservationRequested implements Action {

  readonly type = ReservationsActionTypes.ReservationRequested;

  constructor(public payload: { companyId: string, reservationId: string }) {
  }

}


export class ReservationLoaded implements Action {

  readonly type = ReservationsActionTypes.ReservationLoaded;

  constructor(public payload: { reservation: Reservation }) {

  }
}


export class ReservationsRequested implements Action {

  readonly type = ReservationsActionTypes.ReservationsRequested;

  constructor(public payload: { companyId: string, filterCriteria: ReservationsFilters }) {
  }

}


export class ReservationsLoaded implements Action {

  readonly type = ReservationsActionTypes.ReservationsLoaded;

  constructor(public payload: { reservations: Reservation[] }) {

  }
}


export class ReservationsForPeriodRequested implements Action {

  readonly type = ReservationsActionTypes.ReservationsForPeriodRequested;

  constructor(public payload: { companyId: string, fromDate: Date, toDate: Date }) {
  }

}

export class ReservationsForPeriodLoaded implements Action {

  readonly type = ReservationsActionTypes.ReservationsForPeriodLoaded;

  constructor(public payload: { reservations: Reservation[] }) {

  }
}


export class ReservationSaved implements Action {

  readonly type = ReservationsActionTypes.ReservationSaved;

  constructor(public payload: { reservation: Update<Reservation> }) {
  }

}


export class ReservationsPageRequested implements Action {

  readonly type = ReservationsActionTypes.ReservationsPageRequested;

  constructor(public payload: { companyId: string; page: PageQuery }) {
  }

}

export class ReservationsPageLoaded implements Action {

  readonly type = ReservationsActionTypes.ReservationsPageLoaded;

  constructor(public payload: { reservations: Reservation[] }) {

  }
}


export class ReservationsPageCancelled implements Action {

  readonly type = ReservationsActionTypes.ReservationsPageCancelled;

}



export type ReservationsActions =
  ReservationRequested |
  ReservationLoaded |
  ReservationsRequested |
  ReservationsLoaded |
  ReservationsForPeriodRequested |
  ReservationsForPeriodLoaded |
  ReservationSaved |
  ReservationsPageRequested |
  ReservationsPageLoaded |
  ReservationsPageCancelled;

