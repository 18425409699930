import { NgModule, ModuleWithProviders,  } from '@angular/core';
// import { RouterModule } from '@angular/router';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects' ;

import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule, FirestoreSettingsToken } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';

import { FirestoreService } from './firestore.service';
import { AuthService } from './auth.service';
import { AuthGuard, SystemAdminGuard, PublicWebSiteGuard, DummyTrueGuard } from '../common/store/guards/guards';
import { UsersService } from '../common/services/users.service';
import { LanguageService } from '../common/services/language.service';

import { environment } from '../../environments/environment';


@NgModule({
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule
  ],
  providers: [
    FirestoreService,
    { provide: FirestoreSettingsToken, useValue: {} },
    AuthService,
    AuthGuard,
    SystemAdminGuard,
    PublicWebSiteGuard,
    DummyTrueGuard,
    UsersService,
    LanguageService,
  ]
})

export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        AuthService,
        AuthGuard,
        SystemAdminGuard,
        PublicWebSiteGuard,
        DummyTrueGuard,
      ]
    };
  }
}
