import { Injectable } from '@angular/core';

import { withLatestFrom, mergeMap, map, filter } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { AppState } from '../reducers';
import { BookingSourcesActionTypes, AllBookingSourcesRequested, AllBookingSourcesLoaded } from '../actions/bookingsources.actions';
import { SupportService } from '../../services/support.service';

import { allBookingSourcesLoaded } from '../selectors/bookingsources.selectors';
import {
  AllSourceSubcategoriesLoaded,
  AllSourceSubcategoriesRequested,
  BookingSourcesSubcategoryActionTypes, SourceSubcategoriesForCategoryLoaded, SourceSubcategoriesForCategoryRequested
} from '../actions/bookingsourcessubcategories.actions';
import {allBookingSourcesSubCategoriesLoaded} from '../selectors/bookingsourcessubcategories.selectors';


@Injectable()
export class BookingsourcessubcategoriesEffects {

  @Effect()
  loadAllBookingSourcesSubCategoriesForCategory$ = this.actions$
    .pipe(
      ofType<SourceSubcategoriesForCategoryRequested>(BookingSourcesSubcategoryActionTypes.SourceSubcategoriesForCategoryRequested),
      mergeMap(action => this.supportService.getBookingSourcesSubCategoriesForCategoryCode(action.payload.BookingSourceCode)),
      map(sourceSubcategories => new AllSourceSubcategoriesLoaded({sourceSubcategories}))
    );

  @Effect()
  loadAllBookingSourcesSubCategories$ = this.actions$
    .pipe(
        ofType<AllSourceSubcategoriesRequested>(BookingSourcesSubcategoryActionTypes.AllSourceSubcategoriesRequested),
        withLatestFrom(this.store.pipe(select(allBookingSourcesSubCategoriesLoaded))),
        filter(([action, allBkgSourcesSubCategoriesLoaded]) => !allBkgSourcesSubCategoriesLoaded),
        mergeMap(action => this.supportService.getBookingSourcesSubCategoriesList()),
        map(sourceSubcategories => new AllSourceSubcategoriesLoaded({sourceSubcategories}))
    );

  constructor(private actions$: Actions,
              private store: Store<AppState>,
              private supportService: SupportService) {}

}
