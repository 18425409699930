import { Injectable, Output, EventEmitter } from '@angular/core';
import {take, finalize, tap} from 'rxjs/operators';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import {Observable} from 'rxjs';



export class Upload {

  $key: string;
  file: File;
  name: string;
  url: string;
  progress: number;
  createdAt: Date = new Date();

  constructor(file: File) {
    this.file = file;
  }
}

@Injectable()
export class FileUploadService {

  constructor(private storage: AngularFireStorage) { }

  // Main task
  private task: AngularFireUploadTask;

  uploadPercent: Observable<number>;
  downloadURL: Observable<string> | null = null;
  fileURL: Observable<string>;

  // Progress monitoring
  private color = 'green';
  mode: 'determinate';
  percentage: Observable<number>;

  snapshot: Observable<any>;

  @Output() fileUrl: EventEmitter<Observable<string>>;

  public pushUpload(fileList: FileList, path: string): Observable<string>  {


    const file = fileList.item(0);
    // Client-side validation example
    if (file.type.split('/')[0] !== 'image') {
      console.error('unsupported file type :( ');
      return;
    }

    // Change the file name
    // const fileName = file.name;

    const filePath = path;

    // Totally optional metadata
    const customMetadata = { app: 'Hotac' };

    // Get the file reference
    const fileRef = this.storage.ref(filePath);

    // The main task
    // const task = this.storage.upload(path, file, { customMetadata });
    this.task = this.storage.upload(filePath, file);

    this.uploadPercent = this.task.percentageChanges();

    // Progress monitoring

    this.task.snapshotChanges()
      .pipe(
        finalize(() => {
          this.downloadURL = <Observable<string>>fileRef.getDownloadURL();
        return this.downloadURL;
        })
      )
      .subscribe();

  }

  isActive(snapshot) {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
  }

}

