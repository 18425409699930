import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import {FormBuilder} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {FirestoreService} from '../../../../../core/firestore.service';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../common/store/reducers';
import { _TRANSLATE } from '../../../../../common/services/translation-marker';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {Reservation} from '../../../../../common/data_model/Reservation';
import {ActivatedRoute} from '@angular/router';
import {selectReservationById} from '../../../../../common/store/selectors/reservations.selectors';
import { ReservationsService } from '../../../../../common/services/reservations.service';
import { selectSystemCurrency } from '../../../../../common/store/selectors/company.selectors';

@Component({
  selector: 'app-view-edit-reservation-main',
  templateUrl: './view-edit-reservation-main.component.html',
  styleUrls: ['./view-edit-reservation-main.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ViewEditReservationMainComponent implements OnInit, OnDestroy {

  @Input() reservation: Reservation;

  public systemCurrency$: Observable<string>;

  public title: string;


  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public store: Store<AppState>,
    public fs: FirestoreService,
    public spinner: NgxSpinnerService,
    private reservationsService: ReservationsService,
    public translate: TranslateService
  ) {}

  ngOnInit() {

    const mainGuestTitle = this.reservation.Primary_contact.Title;

    this.title = this.translate.instant(_TRANSLATE('Common.Title-' + mainGuestTitle)) ;

    this.systemCurrency$ = this.store
      .pipe(
        select(selectSystemCurrency)
      );


  }

   timestampToDate(timestampObj: any): Date {
    return timestampObj.hasOwnProperty('nanoseconds') ?
      new Date(timestampObj.seconds * 1000 + timestampObj.nanoseconds / 1e6) :
      timestampObj;
  }

  timeArrivalKnown(dateObj: any): boolean {
    const convertedDate = new Date(this.timestampToDate(dateObj));

    const time = convertedDate.getHours() + ':' + convertedDate.getMinutes();

    return time !== '00:0' ;

  }

  reservationBalanceDue(reservation: Reservation): number {
    return this.reservationsService.getReservationBalanceDue(reservation);
  }

  ngOnDestroy() {

  }

}
