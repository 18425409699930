import { Injectable } from '@angular/core';

import {mergeMap, map, filter, withLatestFrom} from 'rxjs/operators';

import {select, Store} from '@ngrx/store';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { AppState } from '../reducers';

import {AllProductsServicesLoaded, AllProductsServicesRequested, ProductsServicesActionTypes} from '../actions/productsservices.actions';

import {PaymentService} from '../../services/payment.service';



@Injectable()
export class ProductsServicesEffects {

  @Effect()
  loadAllProductsServices$ = this.actions$
    .pipe(
        ofType<AllProductsServicesRequested>(ProductsServicesActionTypes.AllProductsServicesRequested),
        mergeMap(action => this.paymentService.getProductsServicesForCompany(action.payload.companyId)),
        map(productsServices => new AllProductsServicesLoaded({productsServices}))
    );

  constructor(private actions$: Actions,
              private store: Store<AppState>,
              private paymentService: PaymentService) {}

}
