
import {first} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { FirestoreService } from '../../core/firestore.service';
import { Observable } from 'rxjs';

@Pipe({
  name: 'specialityData'
})
export class SpecialityDataPipe implements PipeTransform {

  constructor(private _afsSrvc: FirestoreService) {}

  transform(specialityId: string, special_ActionsId: string): Observable<any> {
    return this._afsSrvc.doc('Specialities/' + specialityId + '/Special_Actions/' + special_ActionsId).valueChanges().pipe(first());
  }

}


