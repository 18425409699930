import { Pipe, PipeTransform } from '@angular/core';
import * as firebase from 'firebase';
import 'firebase/firestore';

@Pipe({
  name: 'tsToDate'
})
export class TsToDatePipe implements PipeTransform {

  transform(value: any, args?: any): Date {
    return value !== null ? value.toDate() : null;
  }

}
