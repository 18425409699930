import { Component, OnInit, Inject, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';

import { Observable } from 'rxjs';
import {take} from 'rxjs/operators';

import { Store, select } from '@ngrx/store';
import { AppState } from '../../../../common/store/reducers';

import * as _ from 'lodash';

import { NgxSpinnerService } from 'ngx-spinner';

import { FirestoreService } from '../../../../core/firestore.service';
import { RoomsService } from '../../../../common/services/rooms.service';
import { ReservationsService } from './../../../../common/services/reservations.service';
import { AppService } from '../../../../app.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { TranslateService } from '@ngx-translate/core';
import { InventoryService } from '../../../../common/services/inventory.service';
import { CompanyService } from '../../../../common/services/company.service';

import { Reservation } from '../../../../common/data_model/Reservation';
import { selectReservationById } from '../../../../common/store/selectors/reservations.selectors';


@Component({
  selector: 'app-reservation-dialog',
  templateUrl: './reservation-dialog.component.html',
  styleUrls: ['./reservation-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReservationDialogComponent implements OnInit {

  mode: string;
  roomsTabInEditMode = false;
  notesTabInEditMode = false;
  historyTabInEditMode = false;

  someTabInEditMode = false;


  reservation: Reservation;
  reservationSelected: any;
  reservationForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ReservationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      mode: string,
      reservationId: string
    },
    public dialog: MatDialog,
    private store: Store<AppState>,
    private db: FirestoreService,
    private reservationsService: ReservationsService,
    private roomsService: RoomsService,
    private inventoryService: InventoryService,
    private companyService: CompanyService,
    private appService: AppService,
    private fb: FormBuilder,
    private storage: AngularFireStorage,
    private spinner: NgxSpinnerService,
    private translate: TranslateService) { }


  ngOnInit() {

    this.mode = this.data.mode ;

    this.store
      .pipe(
        select(selectReservationById(this.data.reservationId)),
        take(1)
      )
      .subscribe(res => {
        this.reservation = _.cloneDeep(res);
      });

    this.reservationForm = this.reservationsService.createReservationForm(this.reservation);
    this.reservationForm.controls.Source = this.fb.group({
      Category: [this.mode === 'web' ? '/Booking_Sources/2519lwySRUh7iD4xvrRq' : '', Validators.required],
      SubCategory: ['web' ? '/Booking_Sources_Sub_Categories/smBzxTW1ruPjiU8ZVQgx' : '', Validators.required]
    });
  }

  editReservation() {
    this.mode = 'edit';
  }

  cancelReservation(reservationSelected: any) {
    console.log();
  }

  updateReservation() {
    console.log();
  }

  cancelUpdateReservation() {
    console.log();
  }

  toggleRoomsTabMode($event: boolean) {
    this.roomsTabInEditMode = $event;
    this.checkSomeTabInEditMode();
  }

  checkSomeTabInEditMode() {
    this.someTabInEditMode = this.roomsTabInEditMode || this.notesTabInEditMode || this.historyTabInEditMode;
  }

}
