import { Action } from '@ngrx/store';
import { RoomType } from '../../data_model/RoomType';



export enum RoomTypesActionTypes {
  RoomTypeRequested = '[Setup] RoomType Requested',
  RoomTypeLoaded = '[Rooms API] RoomType Loaded',
  AllRoomTypesRequested = '[Setup] All RoomTypes Requested',
  AllRoomTypesLoaded = '[Rooms API] All RoomTypes Loaded'
}

export class RoomTypeRequested implements Action {

  readonly type = RoomTypesActionTypes.RoomTypeRequested;

  constructor(public payload: { roomtypeId: string }) {

  }

}


export class RoomTypeLoaded implements Action {

  readonly type = RoomTypesActionTypes.RoomTypeLoaded;

  constructor(public payload: { roomtype: RoomType }) {

  }

}


export class AllRoomTypesRequested implements Action {

  readonly type = RoomTypesActionTypes.AllRoomTypesRequested;

}


export class AllRoomTypesLoaded implements Action {

  readonly type = RoomTypesActionTypes.AllRoomTypesLoaded;

  constructor(public payload: { roomtypes: RoomType[] }) {

  }

}


export type  RoomTypesActions =
  RoomTypeRequested |
  RoomTypeLoaded |
  AllRoomTypesRequested |
  AllRoomTypesLoaded ;

