import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RoomTypesState } from '../reducers/roomtypes.reducer';
import * as fromRoomTypes from '../reducers/roomtypes.reducer';

export const selectRoomTypesState = createFeatureSelector<RoomTypesState>('roomtypes');


export const selectRoomTypeById = (roomtypeId: string) => createSelector(
  selectRoomTypesState,
  roomtypesState => roomtypesState.entities[roomtypeId]
);


export const selectAllRoomTypes = createSelector(
  selectRoomTypesState,
  fromRoomTypes.selectAll
);

export const allRoomTypesLoaded = createSelector(
  selectRoomTypesState,
  roomtypesState => roomtypesState.allRoomTypesLoaded
);


