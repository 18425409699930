import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timestampToDate'
})
export class TimestampToDatePipe implements PipeTransform {

  transform(value: any): Date {
    return new Date(value.seconds * 1000 + value.nanoseconds / 1e6);
  }

}
