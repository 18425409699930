import { Action } from '@ngrx/store';
import { Room } from '../../data_model/Room';



export enum RoomsActionTypes {
  RoomRequested = '[Reservation] Room Requested',
  RoomLoaded = '[Rooms API] Room Loaded',
  AllRoomsRequested = '[Rooms] All Rooms Requested',
  AllRoomsLoaded = '[Rooms API] All Rooms Loaded'
}

export class RoomRequested implements Action {

  readonly type = RoomsActionTypes.RoomRequested;

  constructor(public payload: { companyId: number, roomName: string }) {

  }

}


export class RoomLoaded implements Action {

  readonly type = RoomsActionTypes.RoomLoaded;

  constructor(public payload: { room: Room }) {

  }

}


export class AllRoomsRequested implements Action {

  readonly type = RoomsActionTypes.AllRoomsRequested;

}


export class AllRoomsLoaded implements Action {

  readonly type = RoomsActionTypes.AllRoomsLoaded;

  constructor(public payload: { rooms: Room[] }) {

  }

}




export type RoomsActions =
  RoomRequested |
  RoomLoaded |
  AllRoomsRequested |
  AllRoomsLoaded ;

