import { Action } from '@ngrx/store';
import { HotelRoomType } from '../../data_model/HotelRoomType';
import {Update} from '@ngrx/entity';
import {Reservation} from '../../data_model/Reservation';
import {ReservationsActionTypes} from './reservations.actions';


export enum HotelRoomTypesActionTypes {
  HotelRoomTypeRequested = '[Rooms] Specific Hotel Room Type Requested',
  HotelRoomTypeLoaded = '[Rooms API] Specific Room Type Loaded',
  HotelRoomTypesRequested = '[Rooms] All Hotel Room Types Requested',
  HotelRoomTypesLoaded = '[Rooms API] All Hotel Room Types Loaded',
  HotelRoomTypeSaved = '[Rooms API] Hotel Room Type Saved',
}


export class HotelRoomTypeRequested implements Action {

  readonly type = HotelRoomTypesActionTypes.HotelRoomTypeRequested;

  constructor(public payload: { companyId: string, hotelRoomTypeId: string }) {
  }

}


export class HotelRoomTypeLoaded implements Action {

  readonly type = HotelRoomTypesActionTypes.HotelRoomTypeLoaded;

  constructor(public payload: { hotelRoomType: HotelRoomType }) {

  }

}


export class HotelRoomTypesRequested implements Action {

  readonly type = HotelRoomTypesActionTypes.HotelRoomTypesRequested;

  constructor(public payload: { companyId: string }) {
  }

}


export class HotelRoomTypesLoaded implements Action {

  readonly type = HotelRoomTypesActionTypes.HotelRoomTypesLoaded;

  constructor(public payload: { hotelRoomTypes: HotelRoomType[] }) {

  }

}


export class HotelRoomTypeSaved implements Action {

  readonly type = HotelRoomTypesActionTypes.HotelRoomTypeSaved;

  constructor(public payload: { hotelRoomType: Update<HotelRoomType> }) {
  }

}




export type HotelRoomTypesActions =
  HotelRoomTypeRequested |
  HotelRoomTypeLoaded |
  HotelRoomTypesRequested |
  HotelRoomTypesLoaded |
  HotelRoomTypeSaved;

