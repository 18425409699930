import { Action } from '@ngrx/store';
import { Role } from '../../data_model/User';



export enum RolesActionTypes {
  RoleRequested = '[Setup] Role Requested',
  RoleLoaded = '[User API] Role Loaded',
  AllRolesRequested = '[Setup] All Roles Requested',
  AllRolesLoaded = '[User API] All Roles Loaded'
}

export class RoleRequested implements Action {

  readonly type = RolesActionTypes.RoleRequested;

  constructor(public payload: { RoleId: string }) {

  }

}


export class RoleLoaded implements Action {

  readonly type = RolesActionTypes.RoleLoaded;

  constructor(public payload: { Role: Role }) {

  }

}


export class AllRolesRequested implements Action {

  readonly type = RolesActionTypes.AllRolesRequested;

}


export class AllRolesLoaded implements Action {

  readonly type = RolesActionTypes.AllRolesLoaded;

  constructor(public payload: { Roles: Role[] }) {

  }

}


export type RolesActions =
  RoleRequested |
  RoleLoaded |
  AllRolesRequested |
  AllRolesLoaded;

