import { createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import { Amenity } from '../../data_model/Amenity';
import { AmenitiesActionTypes, AmenitiesActions } from '../actions/amenities.actions';
import { AppState } from '.';


export interface AmenitiesState extends EntityState<Amenity> {
  allAmenitiesLoaded: boolean;
}

function sortById(a1: Amenity, a2: Amenity) {
  return +a1.id - +a2.id;
}

export const amenitiesAdapter: EntityAdapter<Amenity> = createEntityAdapter<Amenity>({
  sortComparer: sortById
});

export const initialAmenitiesState: AmenitiesState = amenitiesAdapter.getInitialState({
  allAmenitiesLoaded: false
});

export function amenitiesReducer( state = initialAmenitiesState, action: AmenitiesActions): AmenitiesState {

    switch (action.type) {

      case AmenitiesActionTypes.AmenityLoaded:
        return amenitiesAdapter.addOne(action.payload.amenity, state);

      case AmenitiesActionTypes.AllAmenitiesLoaded:
        return amenitiesAdapter.addAll(action.payload.amenities, {...state, allAmenitiesLoaded: true});


      default:
        return state;
    }
}


export const {
  selectAll,
  selectIds,
  selectEntities,
  selectTotal
} = amenitiesAdapter.getSelectors();
