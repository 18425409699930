import { createFeatureSelector, createSelector } from '@ngrx/store';

import { HotelRoomTypesState } from '../reducers/hotelroomtypes.reducer';
import * as fromHotelRoomTypes from '../reducers/hotelroomtypes.reducer';
import {map} from 'rxjs/operators';

export const selectHotelRoomTypesState = createFeatureSelector<HotelRoomTypesState>('hotelroomtypes');


export const selectAllHotelRoomTypes = createSelector (
  selectHotelRoomTypesState,
  fromHotelRoomTypes.selectAll
);

export const allHotelRoomTypesLoaded = createSelector(
  selectHotelRoomTypesState,
  hotelRoomTypesState => hotelRoomTypesState.allHotelRoomTypesLoaded
);




