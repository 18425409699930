import { Injectable } from '@angular/core';

export class Settings {
    constructor(public name: string,
                public theme: string) { }
}

@Injectable()
export class AppSettings {
  public settings = new Settings(
    'hotac',  // theme name
    'hotac'     // brown, green, blue, red, pink, purple, grey, hotac
  );

  public changeTheme(theme) {
    this.settings.theme = theme;
  }
}
