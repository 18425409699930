import { createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import { ProductService } from '../../data_model/OtherProductsNServices';
import {AllOtherProductsServicesLoaded, ProductsServicesActions, ProductsServicesActionTypes} from '../actions/productsservices.actions';


export interface ProductsServicesState extends EntityState<ProductService> {
  allProductsServicesLoaded: boolean;
}

function sortById(ps1: ProductService, ps2: ProductService) {
  return ps1.id.localeCompare(ps2.id);
}

export const productsServicesAdapter: EntityAdapter<ProductService> = createEntityAdapter<ProductService>({
  sortComparer: sortById
});

export const initialProductsServicesState: ProductsServicesState = productsServicesAdapter.getInitialState({
  allProductsServicesLoaded: false
});

export function productsServicesReducer( state = initialProductsServicesState, action: ProductsServicesActions): ProductsServicesState {

    switch (action.type) {

      case ProductsServicesActionTypes.ProductServiceLoaded:
        return productsServicesAdapter.addOne(action.payload.productService, state);

      case ProductsServicesActionTypes.AllProductsServicesLoaded:
        return productsServicesAdapter.addAll(action.payload.productsServices, {...state, allProductsServicesLoaded: true});

      default:
        return state;
    }
}


export const {
  selectAll,
  selectIds,
  selectEntities,
  selectTotal
} = productsServicesAdapter.getSelectors();
