import {Component, OnInit, Input, AfterViewInit, ChangeDetectionStrategy} from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { MatDialog } from '@angular/material/dialog';
import { RoomDialogComponent } from './room-dialog/room-dialog.component';
import { AppState } from '../../common/store/reducers';
import { Store } from '@ngrx/store';
import { HotelFeatureImage } from '../../common/data_model/HotelFeatureImage';

@Component({
  selector: 'app-rooms-carousel',
  templateUrl: './rooms-carousel.component.html',
  styleUrls: ['./rooms-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class RoomsCarouselComponent implements OnInit, AfterViewInit {

  @Input() feature_images: Array<HotelFeatureImage>;

  public config: SwiperConfigInterface = {};

  constructor(public dialog: MatDialog,
              private store: Store<AppState>) { }

  ngOnInit() {
   }

  ngAfterViewInit() {
    this.config = {
      observer: true,
      slidesPerView: 5,
      spaceBetween: 16,
      width: 250,
      height: 200,
      breakpoints: {
        480: {
          slidesPerView: 1
        },
        740: {
          slidesPerView: 2,
        },
        960: {
          slidesPerView: 3,
        },
        1280: {
          slidesPerView: 4,
        },
        1500: {
          slidesPerView: 5,
        }
      }
    };
  }

  public openRoomDialog(room) {
    const dialogRef = this.dialog.open(RoomDialogComponent, {
        data: room,
        panelClass: 'room-dialog'
    });
    dialogRef.afterClosed().subscribe(rm => {
      if (rm) {
        // this.router.navigate(['/products', room.id, room.name]);
      }
    });
  }

}
