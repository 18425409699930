import { Action } from '@ngrx/store';
import { OperationPeriod } from '../../data_model/Inventory';



export enum OperationPeriodsActionTypes {
  OperationPeriodRequested = '[Setup] Operation Period Requested',
  OperationPeriodLoaded = '[Inventory API] Operation Period Loaded',
  AllOperationPeriodsRequested = '[Setup] All Operation Periods Requested',
  AllOperationPeriodsLoaded = '[Inventory API] All Operation Periods Loaded'
}

export class OperationPeriodRequested implements Action {

  readonly type = OperationPeriodsActionTypes.OperationPeriodRequested;

  constructor(public payload: { operationPeriodId: string }) {

  }

}


export class OperationPeriodLoaded implements Action {

  readonly type = OperationPeriodsActionTypes.OperationPeriodLoaded;

  constructor(public payload: { operationPeriod: OperationPeriod }) {

  }

}


export class AllOperationPeriodsRequested implements Action {

  readonly type = OperationPeriodsActionTypes.AllOperationPeriodsRequested;

  constructor(public payload: { companyId: string }) {
  }

}


export class AllOperationPeriodsLoaded implements Action {

  readonly type = OperationPeriodsActionTypes.AllOperationPeriodsLoaded;

  constructor(public payload: { operationPeriods: OperationPeriod[] }) {

  }

}


export type OperationPeriodsActions =
  OperationPeriodRequested |
  OperationPeriodLoaded |
  AllOperationPeriodsRequested |
  AllOperationPeriodsLoaded ;

