import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { FirestoreService } from '../../core/firestore.service';

import { Room } from '../data_model/Room';
import { Amenity } from '../data_model/Amenity';
import { BedType } from '../data_model/BedType';
import { PropertyType } from '../data_model/PropertyType';
import { RoomType } from '../data_model/RoomType';
import {HotelRoomType, RatePlan, RateType} from '../data_model/HotelRoomType';
import {flatMap, map, take, tap, filter} from 'rxjs/operators';


import * as _ from 'lodash';
import {stringify} from '@angular/compiler/src/util';
import {Inventory, SearchAvailabilityCriteria} from '../data_model/Inventory';
import {AvailabilitySearchResults, Reservation, ReservationsFilters} from '../data_model/Reservation';



@Injectable()
export class RoomsService {

  private ratePlanInventory: Inventory;

  constructor( private _fsSrvc: FirestoreService, private db: AngularFirestore) {
  }

  public getHotelRoomTypeNameByType(code: string): Observable<string> {
    return this._fsSrvc.doc$<RoomType>('Room_Types/' + code)
      .pipe(
        map(hrt => hrt.Name),
        take(1)
      );
  }

  public getRoomById(companyId: string, roomId: string): Observable<Room> {
    return this._fsSrvc.doc$<Room>('Company/' + companyId + '/Rooms/' + roomId);
  }

  public getAllRoomsForCompany(companyId: string): Observable<Room[]> {
    return this._fsSrvc.colWithIds$<Room[]>('Company/' + companyId + '/Room_Types')
      .pipe(
        map((res, idx) => res[idx].Rooms)
      );
  }

  public getAllRoomsForAllHotelRoomTypesForCompany(companyId: string): Observable<any> {
    return this._fsSrvc.colWithIds$<HotelRoomType[]>('Company/' + companyId + '/Room_Types')
      .pipe(
        map(res => {
          res.map( rs => {
              return {
                id: rs.id,
                Type: rs.Type,
                Rooms: rs.Rooms
              };
          });
        })
      );
  }

  public getAllRoomsForAllHotelRoomTypesForCompanyAsCalendarResources(companyId: string): Observable<HotelRoomType[]> {
    return this._fsSrvc.colWithIds$<HotelRoomType[]>('Company/' + companyId + '/Room_Types')
      .pipe(
        map((res, idx) => res[idx].map(rs => {
          return {
            id: rs.id,
            Type: rs.Type,
            Rooms: rs.Rooms
          };
        }))
      );
  }

  public getAllHotelRoomTypesForCompany(companyId: string): Observable<HotelRoomType[]> {
    console.log('CompanyId = ', companyId);
    return this._fsSrvc.colWithIds$<HotelRoomType[]>('Company/' + companyId + '/Room_Types');
  }

  public getAllRoomsForHotelRoomType(companyId: string, hotelroomtypeId: string): Observable<Room[]> {
    return this._fsSrvc.colWithIds$<Room[]>('Company/' + companyId + '/Room_Type/' + hotelroomtypeId);
  }

  public getHotelRoomTypeByCode(companyId: string, hotelroomtypeCode: string): Observable<HotelRoomType> {
    return this._fsSrvc.colWithIds$<HotelRoomType>('Company/' + companyId + '/Room_Types', ref => ref
      .where('Type', '==', hotelroomtypeCode)
    ).pipe(
      map(hrts => hrts[0])
    );
  }

  public getAllAmenities(): Observable<Amenity[]> {
    return this._fsSrvc.colWithIds$<Amenity>('Amenities');
  }

  public getAllBedTypes(): Observable<BedType[]> {
    return this._fsSrvc.colWithIds$<BedType>('Bed_Types');
  }

  public getAllPropertyTypes(): Observable<PropertyType[]> {
    return this._fsSrvc.colWithIds$<PropertyType>('Property_Types');
  }

  public getAllRoomTypes(): Observable<RoomType[]> {
    return this._fsSrvc.colWithIds$<RoomType>('Room_Types');
  }

  public getRoomsByType(companyId: string): Observable<HotelRoomType[]> {
     return this.getAllRoomsForCompany(companyId)
      .pipe(
        map(rms => this.groupRoomsByType(rms))
      );
  }

  private groupRoomsByType(grp: Array<Room>): any {
    const result = _.chain(grp)
      .groupBy('Type')
      .toPairs()
      .map(function(currentItem) {
        return _.fromPairs(_.zip(['id', 'Rooms'], currentItem));
      })
      .value();
    return result;
  }

  public getHotelRoomTypeForRatePlan(hrts: HotelRoomType[], ratePlanCode: string): HotelRoomType {
    let foundPos: number;
    let i: number;
    let rslt: HotelRoomType;

    for (i = 0; i < hrts.length; i++) {
      foundPos = hrts[i].Rate_plans.findIndex(e => e.Code === ratePlanCode);
      if (foundPos !== -1) {
        rslt = hrts[i];
        break;
      }
    }
    return rslt;
  }

  public hotelRoomTypeAvailableWholeInv(hrtCode: string, invData: Inventory[]): boolean {
    let i: number;
    let rslt = true;

    for (i = 0; i < invData.length; i++) {
      const posFound = invData[i].Rate_plans.findIndex(e => e.Hotel_Room_Type === hrtCode);
      rslt = posFound !== -1 && +invData[i].Rate_plans[posFound].Alloted_for_sale > 0;
      if (!rslt) {
        break;
      }
    }
    return rslt;
  }

  public getRoomsBedsCount(room: Room): number {
    let nbrOfBeds = 0;
    room.Beds.forEach(bed => {
      nbrOfBeds = nbrOfBeds + bed.Quantity;
    });
    return nbrOfBeds;
  }

  public getMinAvailability(rmTypeType: string, invArr: Inventory[]): number {
    let minAvailab = 1000000;

    invArr.forEach(invDate => {
      const pos = invDate.Rooms_availability.findIndex(e => e.Room_type === rmTypeType);
      const av = invDate.Rooms_availability[pos].Available;
      if (av < minAvailab) {
        minAvailab = av;
      }
    });

    return minAvailab;
  }

  public calcOvernights(fDate: Date, tDate: Date): number {
    // eslint-disable-next-line max-len
    return Math.floor((Date.UTC(tDate.getFullYear(), tDate.getMonth(), tDate.getDate()) - Date.UTC(fDate.getFullYear(), fDate.getMonth(), fDate.getDate())) / (1000 * 60 * 60 * 24));
  }

  findCurrRatePlanPos(someArray: any[], val: string): number {
    return someArray.findIndex(e => e.Code === val);
  }

  findCurrRoomTypePos(someArray: any[], val: string): number {
    return someArray.findIndex(e => e.Room_type === val);
  }


  findCurrRoomTypeAndRatePlanPos(someArray: any[], val: string): number {
    return someArray.findIndex(e => e.id === val);
  }

  getRoomTypeNameByCode(someArray: any[], val: string): string {
    const id = val.substr(val.lastIndexOf('/') + 1);
    console.log(someArray);
    console.log(val);
    console.log(id);
    return someArray[someArray.findIndex(e => e.id === id)].Name;
  }

  // eslint-disable-next-line max-len
  public prepareSearchResults(allHotelRoomTypes: HotelRoomType[], searchCriteria: SearchAvailabilityCriteria, inventoryResults: Inventory[], mode: string): AvailabilitySearchResults {

    let checkInDay = searchCriteria.checkIn.getDay();
    checkInDay = checkInDay === 0 ? 7 : checkInDay;

    let checkOutDay = searchCriteria.checkOut.getDay();
    checkOutDay = checkOutDay === 0 ? 7 : checkOutDay;

    const availableHotelRoomTypes = [];

    const cellDatesArr = [];

    let hrtCtr: number;

    // Iterate trough all Hotel Room Types
    for (hrtCtr = 0; hrtCtr < allHotelRoomTypes.length; hrtCtr++) {
      const hrt = allHotelRoomTypes[hrtCtr];
      const availableWholePeriod = this.hotelRoomTypeAvailableWholeInv(hrt.Type, inventoryResults);

      // Jf room type is available add it to the available room types list
      if (availableWholePeriod || mode === 'backend') {
        availableHotelRoomTypes.push(hrt);
        const minAvailability = this.getMinAvailability(hrt.Type, inventoryResults);
        availableHotelRoomTypes[availableHotelRoomTypes.length - 1].Units = minAvailability;
        availableHotelRoomTypes[availableHotelRoomTypes.length - 1].Rate_plans = [];

        cellDatesArr.push([]);

        let cellDate = new Date(searchCriteria.checkIn);

        let cellDatePreCtr;
        for (cellDatePreCtr = checkInDay - 1; cellDatePreCtr > 0; cellDatePreCtr--) {
          const currDate = new Date(cellDate);
          currDate.setDate(searchCriteria.checkIn.getDate() - cellDatePreCtr);

          const currCell = {
            dte: currDate,
            amount: 0,
            available: 0
          };

          cellDatesArr[availableHotelRoomTypes.length - 1].push(currCell);
        }

        let cellDateStayCtr;
        // eslint-disable-next-line max-len
        for (cellDateStayCtr = 0; checkOutDay > checkInDay ? cellDateStayCtr < (checkOutDay - checkInDay) : cellDateStayCtr <= ((checkInDay + checkOutDay) - checkInDay); cellDateStayCtr++) {
          const currDate = new Date(cellDate);
          currDate.setDate(searchCriteria.checkIn.getDate() + cellDateStayCtr);
          const currCell = {
            dte: currDate,
            amount: 0,
            available: 0
          };

          cellDatesArr[availableHotelRoomTypes.length - 1].push(currCell);
        }

        if (checkOutDay > 1) {
          cellDate = new Date(searchCriteria.checkOut);

          let cellDatePostCtr;
          for (cellDatePostCtr = 0; cellDatePostCtr <= 7 - checkOutDay; cellDatePostCtr++) {
            const currDate = new Date(cellDate);
            currDate.setDate(searchCriteria.checkOut.getDate() + cellDatePostCtr);
            const currCell = {
              dte: currDate,
              amount: 0,
              available: 0
            };

            cellDatesArr[availableHotelRoomTypes.length - 1].push(currCell);
          }
        }

        let invDataCtr;
        for (invDataCtr = 0; invDataCtr < inventoryResults.length; invDataCtr++) {

          let ratePlanCtr;
          for (ratePlanCtr = 0; ratePlanCtr < inventoryResults[invDataCtr].Rate_plans.length; ratePlanCtr++) {

            const rPlan = inventoryResults[invDataCtr].Rate_plans[ratePlanCtr];
            if (rPlan.Hotel_Room_Type === availableHotelRoomTypes[availableHotelRoomTypes.length - 1].Type) {

              const availableQty = rPlan.Alloted_for_sale > 0;

              const availableForWeb = rPlan.Available_for_website;

              const dynamDiskOk =
                (!rPlan.Payment.Dynamic_discount.applicable) ||
                // eslint-disable-next-line max-len
                (rPlan.Payment.Dynamic_discount.applicable && rPlan.Payment.Dynamic_discount.stay_days <= this.calcOvernights(searchCriteria.checkIn, searchCriteria.checkOut));

              const restrSellFromToOk = true;
              // eslint-disable-next-line max-len
              /* eslint-disable-next-line , , , , ,  */

              const restrLeadtimeOk =
                +rPlan.Restrictions.Lead_time === 0 ? true :
                  // eslint-disable-next-line max-len
                  +rPlan.Restrictions.Lead_time <= this.calcOvernights(new Date(), searchCriteria.checkIn) ? true : false;

              const minStay =
                +rPlan.Restrictions.Min_stay === 0 ? true :
                  // eslint-disable-next-line max-len
                  +rPlan.Restrictions.Min_stay <= this.calcOvernights(searchCriteria.checkIn, searchCriteria.checkOut) ? true : false;

              const maxStay =
                +rPlan.Restrictions.Max_stay === 0 ? true :
                  // eslint-disable-next-line max-len
                  +rPlan.Restrictions.Max_stay <= this.calcOvernights(searchCriteria.checkIn, searchCriteria.checkOut) ? true : false;

              const ctaOk =
                !rPlan.Restrictions.CTA ||
                (rPlan.Restrictions.CTA && inventoryResults[invDataCtr].Applicable_date !== searchCriteria.checkIn && invDataCtr === 0);

              const ctdOk =
                !rPlan.Restrictions.CTD ||
                // eslint-disable-next-line max-len
                (rPlan.Restrictions.CTD && inventoryResults[invDataCtr].Applicable_date !== searchCriteria.checkOut && invDataCtr === inventoryResults.length - 1);

              // eslint-disable-next-line max-len
              if (availableQty && availableForWeb && dynamDiskOk && restrSellFromToOk && restrLeadtimeOk && minStay && maxStay && ctaOk && ctdOk) {
                // eslint-disable-next-line max-len
                if (this.findCurrRatePlanPos(availableHotelRoomTypes[availableHotelRoomTypes.length - 1].Rate_plans, rPlan.Code) === -1) {
                  availableHotelRoomTypes[availableHotelRoomTypes.length - 1].Rate_plans.push(rPlan);
                }

                let rate = 0;

                const roomrate = rPlan.Payment.Hot_deal.applicable ? +rPlan.Payment.Hot_deal.new_base_rate : +rPlan.Payment.Base_rate;

                rate = +rPlan.Payment.Rate_per === 1 ? roomrate : roomrate * searchCriteria.adults;

                if (+availableHotelRoomTypes[availableHotelRoomTypes.length - 1].Max_adults < searchCriteria.adults) {
                  // eslint-disable-next-line max-len
                  rate = rate + ((searchCriteria.adults - +availableHotelRoomTypes[availableHotelRoomTypes.length - 1].Max_adults) * +rPlan.Payment.Extra_adult_rate);
                }

                if (+availableHotelRoomTypes[availableHotelRoomTypes.length - 1].Max_kids < searchCriteria.children) {
                  // eslint-disable-next-line max-len
                  rate = rate + ((searchCriteria.children - +availableHotelRoomTypes[availableHotelRoomTypes.length - 1].Max_kids) * +rPlan.Payment.Extra_child_rate);
                }

                cellDatesArr[availableHotelRoomTypes.length - 1][checkInDay - 1 + invDataCtr].amount = rate;
                // eslint-disable-next-line max-len
                cellDatesArr[availableHotelRoomTypes.length - 1][checkInDay - 1 + invDataCtr].available = rPlan.Alloted_for_sale;
              }
            }
          }
        }
      }
    }

    // Calculate total amount for tis rate plan
    cellDatesArr.forEach((hrt, hrtIdx) => {
      let rpTotalAmount = 0;
      hrt.forEach(cellDte => {
        rpTotalAmount = rpTotalAmount + cellDte.amount;
      });

      hrt.push({
        dte: searchCriteria.checkOut,
        amount: rpTotalAmount
      });
    });

    const returnRes = {
      hotelRoomTypesList: availableHotelRoomTypes,
      daysBreakDownArr: cellDatesArr
    };

    return returnRes;
  }


}



