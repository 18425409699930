import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FirestoreService } from '../../core/firestore.service';
import { pipe, SubscriptionLike as ISubscription ,  Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Language } from '../data_model/Language';



@Injectable()
export class LanguageService {


     private languageSubscription: ISubscription;

     constructor (private _fsSrvc: FirestoreService,
                  private _afs: AngularFirestore) {

     }

     getUserData(userId: string): Observable<any> {
        return this._fsSrvc.doc$<any>('Users/' + userId);
    }

     getAllLanguages(): Observable<Language[]> {
          return this._fsSrvc.colWithIds$<Language[]>('Languages', ref => ref
            .orderBy('priority'));
     }


     getLanguageData(langId: string): Observable<Language>  {
          return this._fsSrvc.doc$<any>('Languages/' + langId);
     }


  getLanguageDataBylanguageRef(langRef: any): Observable<Language>  {
        return this._fsSrvc.doc$<any>(langRef);
   }


     getLanguageDataByUserId(userId: string): Observable<any> {
        const Lang$ = this.getUserData(userId);

        const LangData$ = Lang$.pipe(
            map (results => results = results.Language),
            switchMap((res) => {
                return this.getLanguageData(res.id);
        }));

        return LangData$;
    }
}
