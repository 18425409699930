import { Injectable } from '@angular/core';

import { withLatestFrom, mergeMap, map, filter } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { AppState } from '../reducers';
import { AllOperationPeriodsLoaded, AllOperationPeriodsRequested, OperationPeriodsActionTypes} from '../actions/operationperiods.actions';

import { InventoryService } from '../../services/inventory.service';


@Injectable()
export class OperationPeriodsEffects {

  @Effect()
  loadAllOperationPeriods$ = this.actions$
    .pipe(
        ofType<AllOperationPeriodsRequested>(OperationPeriodsActionTypes.AllOperationPeriodsRequested),
        mergeMap(action => this.inventoryService.getOperationPeriodsList(action.payload.companyId)),
        map(operationPeriods => new AllOperationPeriodsLoaded({operationPeriods}))
    );


  constructor(private actions$: Actions,
              private store: Store<AppState>,
              private inventoryService: InventoryService) {}

}
