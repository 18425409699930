import { createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import { TaxFee } from '../../data_model/TaxesFees';
import { TaxesFeesActions, TaxesFeesActionTypes } from '../actions/taxesfees.actions';


export interface TaxesFeesState extends EntityState<TaxFee> {
  allTaxesFeesLoaded: boolean;
}

function sortByName(tf1: TaxFee, tf2: TaxFee) {
  return tf1.Name.localeCompare(tf2.Name);
}

export const taxesFeesAdapter: EntityAdapter<TaxFee> = createEntityAdapter<TaxFee>({
  sortComparer: sortByName
});

export const initialTaxesFeesState: TaxesFeesState = taxesFeesAdapter.getInitialState({
  allTaxesFeesLoaded: false
});

export function taxesFeesReducer( state = initialTaxesFeesState, action: TaxesFeesActions): TaxesFeesState {

    switch (action.type) {

      case TaxesFeesActionTypes.TaxFeeLoaded:
        return taxesFeesAdapter.addOne(action.payload.taxFee, state);

      case TaxesFeesActionTypes.AllTaxesFeesLoaded:
        return taxesFeesAdapter.addAll(action.payload.taxesFees, {...state, allTaxesFeesLoaded: true});


      default:
        return state;
    }
}


export const {
  selectAll,
  selectIds,
  selectEntities,
  selectTotal
} = taxesFeesAdapter.getSelectors();
