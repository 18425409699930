import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class BannersComponent implements OnInit {
  @Input() banners: Array<any>;

  constructor() { }

  ngOnInit() { }

  public getBanner(index) {
    return this.banners[index];
  }

  public getBgImage(index) {
    const bgImage = {
      'background-image': index != null
        ? 'url(' + this.banners[index].image_url + ')'
        : 'url(https://via.placeholder.com/600x400/ff0000/fff/)'
    };
    return bgImage;
  }

}
