import { loggedInUser } from './../../../../../common/store/selectors/auth.selectors';
import { User } from './../../../../../common/data_model/User';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';

import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NgxSpinnerService } from 'ngx-spinner';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../common/store/reducers';
import {Reservation, ReservationRoom} from '../../../../../common/data_model/Reservation';
import {selectReservationByIdRooms} from '../../../../../common/store/selectors/reservations.selectors';
import { selectSystemCurrency } from '../../../../../common/store/selectors/company.selectors';

import { TranslateService } from '@ngx-translate/core';

import { ReservationsService } from '../../../../../common/services/reservations.service';

@Component({
  selector: 'app-view-edit-reservation-rooms',
  templateUrl: './view-edit-reservation-rooms.component.html',
  styleUrls: ['./view-edit-reservation-rooms.component.scss'],
})


export class ViewEditReservationRoomsComponent implements OnInit, OnDestroy {

  @Input() reservation: Reservation;
  @Input() mode: string;

  @Output() roomsTabInEditMode: EventEmitter<boolean> = new EventEmitter();

  public reservationRooms$: Observable<ReservationRoom[]>;

  public reservationRooms: ReservationRoom[];

  public reservationForm: FormGroup;

  public ngDatasourceUnsubscribe$: Subject<any> = new Subject();

  public user$: Observable<User>;

  public systemCurrency$: Observable<string>;

  public dataSource = new MatTableDataSource<ReservationRoom>();

  dColumnsReservationRooms: Array<string>;

  selection = new SelectionModel<any>(true, []);

  searchFilter: string;

  reservationRoomHasBeenSelected: boolean;

  reservationRoomSelected: ReservationRoom;

  previousRowSelected: any = null;

  selectedRowIndex = -1;

  opMode: string;

  matSortColumn: string;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;


  constructor(
    public store: Store<AppState>,
    public fb: FormBuilder,
    public spinner: NgxSpinnerService,
    public reservationsService: ReservationsService,
    public translate: TranslateService
  ) {}

  ngOnInit() {

    const reservationid = this.reservation.id;

    this.opMode = this.mode;

    this.emitStatus();

    this.user$ = this.store
      .pipe(
        select(loggedInUser),
      );

    this.reservationRooms$ = this.store
      .pipe(
        select(selectReservationByIdRooms(reservationid))
      );

    this.reservationRooms$
      .pipe(
        takeUntil(this.ngDatasourceUnsubscribe$)
      )
      .subscribe( resRooms => {
        this.reservationRooms = resRooms;
        this.prepareTable();
      });


    this.systemCurrency$ = this.store
      .pipe(
        select(selectSystemCurrency)
    );

    this.reservationForm = this.reservationsService.createReservationForm(this.reservation);
    this.reservationForm.controls.Source = this.fb.group({
      Category: [this.mode === 'web' ? '/Booking_Sources/2519lwySRUh7iD4xvrRq' : '', Validators.required],
      SubCategory: ['web' ? '/Booking_Sources_Sub_Categories/smBzxTW1ruPjiU8ZVQgx' : '', Validators.required]
    });

  }


  prepareTable() {
    this.dataSource.data = this.reservationRooms as ReservationRoom[];
    // eslint-disable-next-line max-len
    this.dColumnsReservationRooms = ['select', 'Room_type', 'Room_number', 'Name', 'Occupancy', 'CheckIn_date', 'CheckOut_date', 'Price', 'Occupied', 'Actions'];
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filter: any) {
    const filterValue = filter.value;
    this.noReservationSelected();
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  trackByUid(index, item) {
    return item.uid;
  }

  rowchecked($event, row) {
    this.selection.clear();
    $event.stopPropagation();
    if (this.previousRowSelected !== row) {
      this.previousRowSelected = row;
      this.selectedRow(row);
      this.highlight(row);
    } else {
      this.reservationRoomHasBeenSelected = true;
      this.highlight(row);
    }
  }

  selectedRow(newReservationRoom: ReservationRoom) {
    this.reservationRoomHasBeenSelected = true;
    this.reservationRoomSelected = newReservationRoom ;
    console.log(newReservationRoom);
  }

  noReservationSelected() {
    this.selection.clear();
    this.reservationRoomHasBeenSelected = false;
    this.reservationRoomSelected = null;
  }

  highlight(row) {
    this.selectedRowIndex = row.id;
  }

  findCurrLanguagePos(someArray: any[], val: string): number {
    return someArray.findIndex(e => e.lang === val);
  }

  get Rooms() {
    return this.reservationForm.get('Rooms') as FormArray;
  }

  editRooms() {
    this.opMode = 'edit';
    this.emitStatus();
  }

  emitStatus() {
    this.roomsTabInEditMode.emit(this.opMode === 'edit');
  }

  ngOnDestroy() {
    this.ngDatasourceUnsubscribe$.next();
    this.ngDatasourceUnsubscribe$.complete();
  }

}
