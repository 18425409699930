import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Role } from '../../data_model/User';
import { RolesActionTypes, RolesActions } from '../actions/roles.actions';
import { AppState } from '.';


export interface RolesState extends EntityState<Role> {
  allRolesLoaded: boolean;
}

function sortById(r1: Role, r2: Role) {
  return +r1.id - +r2.id;
}

export const rolesAdapter: EntityAdapter<Role> = createEntityAdapter<Role>({
  sortComparer: sortById
});

export const initialRolesState: RolesState = rolesAdapter.getInitialState({
  allRolesLoaded: false
});

export function rolesReducer(state = initialRolesState, action: RolesActions): RolesState {

  switch (action.type) {

    case RolesActionTypes.RoleLoaded:
      return rolesAdapter.addOne(action.payload.Role, state);

    case RolesActionTypes.AllRolesLoaded:
      return rolesAdapter.addAll(action.payload.Roles, { ...state, allRolesLoaded: true });


    default:
      return state;
  }
}


export const {
  selectAll,
  selectIds,
  selectEntities,
  selectTotal
} = rolesAdapter.getSelectors();
