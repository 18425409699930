import { Component, OnInit, Inject, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';

import { Observable } from 'rxjs';
import {take} from 'rxjs/operators';

import { Store, select } from '@ngrx/store';
import { AppState } from '../../../../common/store/reducers';

import * as _ from 'lodash';

import { NgxSpinnerService } from 'ngx-spinner';

import { FirestoreService } from '../../../../core/firestore.service';
import { RoomsService } from '../../../../common/services/rooms.service';
import { AppService } from '../../../../app.service';
import { UsersService } from './../../../../common/services/users.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { TranslateService } from '@ngx-translate/core';
import {InventoryService} from '../../../../common/services/inventory.service';
import {CompanyService} from '../../../../common/services/company.service';

import { User } from '../../../../common/data_model/User';
import { Login } from './../../../../common/store/actions/auth.actions';





@Component({
  selector: 'app-user-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserDialogComponent implements OnInit {

  user: User;

  constructor(
    public dialogRef: MatDialogRef<UserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      mode: string,
      user: User
    },
    public dialog: MatDialog,
    private store: Store<AppState>,
    private usersService: UsersService,
    private fb: FormBuilder,
    private storage: AngularFireStorage,
    private spinner: NgxSpinnerService,
    private translate: TranslateService) { }


  ngOnInit() {
    this.user = _.cloneDeep(this.data.user);
    this.checkUserObject();
  }


  checkUserObject() {
    if (!this.user.Permissions) {
      const permissions = {
        AdminMenu: this.user.Role === '/Roles/1',
        Dashboard: {
          access: true,
          financial: this.user.Role === '/Roles/2'
        },
        Calendar: {
          access: true,
          add: true,
          view: true,
          edit: true,
          cancel: true,
        },
        Reservations: {
          access: true,
          add: true,
          view: true,
          basicEdit: true,
          allowPriceChange: this.user.Role === '/Roles/1' || this.user.Role === '/Roles/2',
          folioEdit: true,
          cancel: true,
        },
        Rates_N_Availability: {
          access: true,
          edit: true,
        },
        Guests: {
          access: true,
          view: true,
          edit: true,
          delete: true,
          cc: {
            add: true,
            view_full: this.user.Role === '/Roles/1' || this.user.Role === '/Roles/2',
            edit: true,
            delete: this.user.Role === '/Roles/1' || this.user.Role === '/Roles/2',
          }
        },
        Setup: {
          access: this.user.Role === '/Roles/1' || this.user.Role === '/Roles/2',
          coreInfo: {
            access: true,
            step1: {
              systemProfile: {
                access: true,
                edit: true
              },
              propertyProfile: {
                access: true,
                edit: true
              },
              roomTypes: {
                access: true,
                edit: true
              },
              availabilityMatrix: {
                access: true,
                edit: true
              },
              taxes_n_fees: {
                access: true,
                edit: true
              },
              productsServices: {
                access: true,
                edit: true
              },
              reservationsSources: {
                access: true,
                edit: true
              },
              policies: {
                access: true,
                edit: true
              },
            },
            step2: {

            }
          },
          Account: {
            access: true,
            subscription: {
              access: true,
              edit: this.user.Role === '/Roles/1' || this.user.Role === '/Roles/2',
            },
            users: {
              access: true,
              add: true,
              view: true,
              edit: this.user.Role === '/Roles/1' || this.user.Role === '/Roles/2',
              delete: this.user.Role === '/Roles/1' || this.user.Role === '/Roles/2',
            }
          }
        },
      };

      this.user.Permissions = permissions;

      console.log(this.user);

      if (this.usersService.updateUserData(this.user.id, this.user)) {
        this.store.dispatch(new Login({ user: this.user }));
      }

    }
  }


  editUserProfile() {
    console.log();
  }

  cancelUserProfile() {
    console.log();
  }
}
