import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { defer } from 'rxjs/observable/defer';

import { Actions, Effect, ofType } from '@ngrx/effects';

import { AuthActionTypes, Login, Logout } from '../actions/auth.actions';

import { getCircularReplacer } from '../utils';



@Injectable()
export class AuthEffects {

  @Effect({dispatch: false})
  login$ = this.actions$
    .pipe(
      ofType<Login>(AuthActionTypes.LoginAction),
      tap(action => {
        return localStorage.setItem('user', JSON.stringify(action.payload.user, getCircularReplacer()));
      })
    );


  @Effect({dispatch: false})
  logout$ = this.actions$
    .pipe(
      ofType<Logout>(AuthActionTypes.LogoutAction),
      tap(() => {
        localStorage.removeItem('user');
        this.router.navigate(['/']);
      })
    );


  // noinspection TypeScriptValidateTypes
  @Effect()

  init$ = defer(() => {
    const userData = localStorage.getItem('user');

    if (userData) {
      /*
      return of(new Login({user: JSON.parse(userData)}));
      */
    } else {
      return of(new Logout());
    }
  });

  constructor(private actions$: Actions,
              private router: Router
  ) {}

}

