import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { Banner } from '../../common/data_model/Banner';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class BannerComponent implements OnInit {
  @Input() banner: Array<Banner>;
  @Input() imgs: Array<number>;
  @Input() languageIndex: number;

  constructor() { }

  ngOnInit() { }

  public getBanner(index) {
    return this.banner[index];
  }

  public getBgImage(index) {
    const bgImage = {
      'background-image': index != null ?
        'url(' + this.banner[index].image_url + ')' :
        'url(https://via.placeholder.com/600x400/ff0000/fff/)'
    };
    return bgImage;
  }

}
