// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.


export const environment = {
  production: false,
  stripeKey: 'pk_test_1Z0igiE7en9bphYsGgBXnQ7k',
  apiBase: 'https://c0cqk221.caspio.com',
  authBody: 'grant_type=client_credentials' +
           '&client_id=ee14862eae584615569d2012f2f35dcc1c6544feca71223dfe' +
           '&client_secret=25588b10624c4f9aa0066f3f3961886192a9e4abc8748b8cbb',
  firebase: {
    apiKey: 'AIzaSyARydH_BtsjhLJf0C_56R_ikjY2-qUxKi8',
    authDomain: 'hotac-test.firebaseapp.com',
    databaseURL: 'https://hotac-test.firebaseio.com',
    projectId: 'hotac-test',
    storageBucket: 'hotac-test.appspot.com',
    messagingSenderId: '65549918081'
  }
};

export const environmentProd = {
  production: false,
  stripeKey: 'pk_test_1Z0igiE7en9bphYsGgBXnQ7k',
  apiBase: 'https://c0cqk221.caspio.com',
  authBody: 'grant_type=client_credentials' +
    '&client_id=ee14862eae584615569d2012f2f35dcc1c6544feca71223dfe' +
    '&client_secret=25588b10624c4f9aa0066f3f3961886192a9e4abc8748b8cbb',
  firebase: {
    apiKey: 'AIzaSyBK-mQMlP-5SLXMLiPxG3ij_dCNByu21nE',
    authDomain: 'hotac-1.firebaseapp.com',
    databaseURL: 'https://hotac-1.firebaseio.com',
    projectId: 'hotac-1',
    storageBucket: 'hotac-1.appspot.com',
    messagingSenderId: '568408644302'
  }
};


/*
export const environment = {
  production: false,
  stripeKey: 'pk_test_1Z0igiE7en9bphYsGgBXnQ7k',
  apiBase: 'https://c0cqk221.caspio.com',
  authBody: 'grant_type=client_credentials' +
    '&client_id=ee14862eae584615569d2012f2f35dcc1c6544feca71223dfe' +
    '&client_secret=25588b10624c4f9aa0066f3f3961886192a9e4abc8748b8cbb',
  firebase: {
    apiKey: 'AIzaSyBK-mQMlP-5SLXMLiPxG3ij_dCNByu21nE',
    authDomain: 'hotac-1.firebaseapp.com',
    databaseURL: 'https://hotac-1.firebaseio.com',
    projectId: 'hotac-1',
    storageBucket: 'hotac-1.appspot.com',
    messagingSenderId: '568408644302'
  }
};

*/
