import { createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import { Country } from '../../data_model/Country';
import { CountriesActionTypes, CountriesActions } from '../actions/countries.actions';
import { AppState } from '.';


export interface CountriesState extends EntityState<Country> {
  allCountriesLoaded: boolean;
}

function sortByISOName(c1: Country, c2: Country) {
  return +c1.Code.localeCompare(c2.Code);
}

export const countriesAdapter: EntityAdapter<Country> = createEntityAdapter<Country>({
  sortComparer: sortByISOName
});

export const initialCountriesState: CountriesState = countriesAdapter.getInitialState({
  allCountriesLoaded: false
});

export function countriesReducer( state = initialCountriesState, action: CountriesActions): CountriesState {

    switch (action.type) {

      case CountriesActionTypes.CountryLoaded:
        return countriesAdapter.addOne(action.payload.country, state);

      case CountriesActionTypes.AllCountriesLoaded:
        return countriesAdapter.addAll(action.payload.countries, {...state, allCountriesLoaded: true});


      default:
        return state;
    }
}


export const {
  selectAll,
  selectIds,
  selectEntities,
  selectTotal
} = countriesAdapter.getSelectors();
