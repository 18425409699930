import { Injectable } from '@angular/core';

import { withLatestFrom, mergeMap, map, filter } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { AppState } from '../reducers';
import { BookingSourcesActionTypes, AllBookingSourcesRequested, AllBookingSourcesLoaded } from '../actions/bookingsources.actions';
import { SupportService } from '../../services/support.service';
import { allBookingSourcesLoaded } from '../selectors/bookingsources.selectors';


@Injectable()
export class BookingSourcesEffects {

  @Effect()
  loadAllBookingSources$ = this.actions$
    .pipe(
        ofType<AllBookingSourcesRequested>(BookingSourcesActionTypes.AllBookingSourcesRequested),
        withLatestFrom(this.store.pipe(select(allBookingSourcesLoaded))),
        filter(([action, allBkgSourcesLoaded]) => !allBkgSourcesLoaded),
        mergeMap(action => this.supportService.getBookingSourcesList()),
        map(bookingSources => new AllBookingSourcesLoaded({bookingSources}))
    );

  constructor(private actions$: Actions,
              private store: Store<AppState>,
              private supportService: SupportService) {}

}
