import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';

import { Observable, Subject } from 'rxjs';
import { tap, take, takeUntil, map } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';
import { AppState } from '../../common/store/reducers';
import { selectCompany, selectSystemCurrency } from '../../common/store/selectors/company.selectors';
import { selectAllTaxesFees } from '../../common/store/selectors/taxesfees.selectors';
import { selectAllHotelRoomTypes } from './../../common/store/selectors/hotelroomtypes.selectors';
import { selectCurrentLanguageCode } from './../../common/store/selectors/languages.selectors';
import { selectAvailabilitySearchState } from './../../common/store/selectors/availabilitysearch.selectors';


import {Address, Company} from '../../common/data_model/Company';
import { TaxFee } from '../../common/data_model/TaxesFees';
import { HotelRoomType, RatePlan} from './../../common/data_model/HotelRoomType';
import { Inventory, SearchAvailabilityCriteria } from '../../common/data_model/Inventory';

import { FirestoreService } from '../../core/firestore.service';
import { AppService } from '../../app.service';
import { InventoryService } from '../../common/services/inventory.service';
import { RoomsService } from '../../common/services/rooms.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LangChangeEvent, TranslateService} from '@ngx-translate/core';

import * as _ from 'lodash';
import { _TRANSLATE } from '../../common/services/translation-marker';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import {HistoryInfo, Reservation} from '../../common/data_model/Reservation';
import { selectProductServiceById} from '../../common/store/selectors/productsservices.selectors';
import { ProductService } from '../../common/data_model/OtherProductsNServices';
import { Country } from '../../common/data_model/Country';
import { selectAllCountries } from '../../common/store/selectors/countries.selectors';
import {ReservationsService} from '../../common/services/reservations.service';
import {BookingSource, BookingSourceSubCategory} from '../../common/data_model/BookingSources';
import {selectAllBookingSources} from '../../common/store/selectors/bookingsources.selectors';
import {selectAllBookingSourcesSubCategories} from '../../common/store/selectors/bookingsourcessubcategories.selectors';
import {
  SourceSubcategoriesForCategoryRequested
} from '../../common/store/actions/bookingsourcessubcategories.actions';
import {User} from '../../common/data_model/User';
import {loggedInUser} from '../../common/store/selectors/auth.selectors';


@Component({
  selector: 'app-new-reservation',
  templateUrl: './new-reservation.component.html',
  styleUrls: ['./new-reservation.component.scss']
})

export class NewReservationComponent implements OnInit, OnDestroy {

  @Input() mode: string;

  public searchcriteria: SearchAvailabilityCriteria;

  public checkInDay: number;
  public checkOutDay: number;

  public cellDatesArr: Array<any> = [];

  public cellDate: Date;

  public user$: Observable<User>;
  public currentLanguage$: Observable<string>;
  public systemCurrency$: Observable<string>;
  public countries$: Observable<Country[]>;
  public categories$: Observable<BookingSource[]>;
  public subCategories$: Observable<BookingSourceSubCategory[]>;

  public operatioPeriodId: string;

  public viewAmenities = false;

  public user: User;
  public company: Company;

  public allHotelRoomTypes: HotelRoomType[] = [];
  public availableHotelRoomTypes: HotelRoomType[] = [];
  public availableHotelRoomTypesListReady: boolean;

  public nbrOfRows: number;

  public firstGap: number;
  public resInfo: number;
  public availabilityDisplaySize: any;
  public lastGap: number;

  public inventoryResults: Inventory[];

  public taxesFees: TaxFee[];

  public reservationProduct: ProductService;

  public roomTypesSelected = false;

  public reservation: Reservation;

  public reservationForm: FormGroup;
  public reservationAmountForm: FormGroup;

  public editPriceMode =  false;

  public paymentDepositAmount = 0;

  public paymentDepositText1 = '';

  public paymentDepositText2 = '';

  public paymentCancellationAmount = 0;

  public paymentCancellationText1 = '';

  public paymentCancellationText2 = '';

  public paymentCancellationText3 = '';

  public paymentCancellationDays = 0;

  public reservationTOC = false;

  public reservationFinalized = false;

  public historyMsgReservationCreated: string;
  public historyMsgByUser: string;
  public historyMsgByCustomer: string;



  public taxApplyKeys = [
    'Property.TaxesNFees.Applies-totAm',
    'Property.TaxesNFees.Applies-pppn',
    'Property.TaxesNFees.Applies-pr',
    'Property.TaxesNFees.Applies-prpn',
    'Property.TaxesNFees.Applies-prpu',
    'Property.TaxesNFees.Applies-pps'
  ];
  public taxApplyTransl: Array<string>;

  public ngLanguageChangeUnsubscribe$: Subject<any> = new Subject();
  public ngTaxesFeesUnsubscribe$: Subject<any> = new Subject();
  public ngSearchCriteriaUnsubscribe$: Subject<any> = new Subject();

  constructor(
    public dialog: MatDialog,
    public router: Router,
    public store: Store<AppState>,
    public fb: FormBuilder,
    public db: FirestoreService,
    public appService: AppService,
    public inventoryService: InventoryService,
    public reservationsService: ReservationsService,
    public roomsService: RoomsService,
    public spinner: NgxSpinnerService,
    public translate: TranslateService
  ) { }

  ngOnInit() {

    this.firstGap = this.mode === 'web' ? 10 : 0;
    this.resInfo = 20;
    this.availabilityDisplaySize = this.mode === 'web' ? 50 : 'auto';
    this.lastGap = this.mode === 'web' ? 10 : 0;

    this.createReservationForm();

    this.store
      .pipe(
        select(selectCompany),
        tap(comp => this.company  = comp),
        take(1)
      )
      .subscribe();

    this.store
      .pipe(
        select(loggedInUser),
        take(1)
      )
      .subscribe(usr => this.user = usr);

    this.currentLanguage$ = this.store
      .pipe(
        select(selectCurrentLanguageCode)
      );


    this.systemCurrency$ = this.store
      .pipe(
        select(selectSystemCurrency)
      );

    this.countries$ = this.store
      .pipe(
        select(selectAllCountries)
      );

    this.categories$ = this.store
      .pipe(
        select(selectAllBookingSources)
      );


    this.store
      .pipe(
       select(selectAvailabilitySearchState),
       takeUntil(this.ngSearchCriteriaUnsubscribe$)
      )
      .subscribe(srchres => {
        this.prepareSearch(srchres);
      });

    this.store
      .pipe(
        select(selectAllHotelRoomTypes),
        take(1)
      )
      .subscribe(allHRMTs => this.allHotelRoomTypes = _.cloneDeep(allHRMTs));

    this.store
      .pipe(
        select(selectAllTaxesFees),
        tap(txsFss => {
          this.taxesFees = txsFss;
        }),
        takeUntil(this.ngTaxesFeesUnsubscribe$)
      )
      .subscribe();

    this.store
      .pipe(
        select(selectProductServiceById('Reservation')),
        tap(rsrv => this.reservationProduct = rsrv),
        take(1)
      )
      .subscribe();

    this.translate.onLangChange
      .subscribe((event: LangChangeEvent) => this.translatePolicyTexts());

  }

  private prepareSearch(searchResults) {
    this.spinner.show();
    this.searchcriteria = _.cloneDeep(searchResults);
    this.inventoryService.getOperationPeriodsforDateRange(this.company.id, this.searchcriteria.checkIn, this.searchcriteria.checkOut)
      .pipe(
        take(1)
      )
      .subscribe(res => {
        this.operatioPeriodId = res.id;
        const searchResults$ =
          this.inventoryService.getInventoryForCalendarPeriod(
            this.company.id,
            this.operatioPeriodId,
            this.searchcriteria.checkIn,
            this.searchcriteria.checkOut
          ).pipe(
              take(1)
            );

        this.checkInDay = this.searchcriteria.checkIn.getDay();
        this.checkInDay = this.checkInDay === 0 ? 7 : this.checkInDay;

        this.checkOutDay = this.searchcriteria.checkOut.getDay();
        this.checkOutDay = this.checkOutDay === 0 ? 7 : this.checkOutDay;

        searchResults$.subscribe(invData => {
          setTimeout(() => {
            this.inventoryResults = _.cloneDeep(invData);

            invData.forEach(invResult => {
              console.log(this.appService.timestampToDate(invResult.Applicable_date));
            });
            // this.prepareSearchResults(invData);
            this.prepareResults();
          }, 2000);
        });
      });
  }


  public prepareResults() {
    this.availableHotelRoomTypesListReady = false;

    const rslts = this.roomsService.prepareSearchResults(this.allHotelRoomTypes, this.searchcriteria, this.inventoryResults, this.mode);

    this.availableHotelRoomTypes = rslts.hotelRoomTypesList;
    this.cellDatesArr = rslts.daysBreakDownArr;

    this.initializeReservationForm();

    console.log(this.cellDatesArr);

    const nbrOfNights = this.reservationForm.value.Dates.Nights;

    this.nbrOfRows = nbrOfNights / 7 + nbrOfNights / 7 === Math.trunc(nbrOfNights / 7) ? 0 : 1;

    this.availableHotelRoomTypesListReady = true;

    this.spinner.hide();
  }

  findCurrLanguagePos(someArray: any[], val: string): number {
    return someArray.findIndex(e => e.lang === val);
  }

  findCurrRatePlanPos(someArray: any[], val: string): number {
    return someArray.findIndex(e => e.Code === val);
  }

  findAvailHotelRoomTypePos(someArray: any[], val: string): number {
    return someArray.findIndex(e => e.Type === val);
  }



  toggleViewAmenities() {
    this.viewAmenities = !this.viewAmenities;
  }

  public createReservationForm() {
    this.reservationForm = this.reservationsService.createReservationForm(this.reservation);

    // Create the Amount Form as well
    this.reservationAmountForm = this.fb.group({
      newAmount: [0]
    });
  }


  public initializeReservationForm() {

    const resDates = {
      Booked_date: new Date(),
      CheckIn_date: new Date(this.searchcriteria.checkIn),
      CheckOut_date: new Date(this.searchcriteria.checkOut),
      // eslint-disable-next-line max-len
      Nights: Math.floor((Date.UTC(this.searchcriteria.checkOut.getFullYear(), this.searchcriteria.checkOut.getMonth(), this.searchcriteria.checkOut.getDate()) - Date.UTC(this.searchcriteria.checkIn.getFullYear(), this.searchcriteria.checkIn.getMonth(), this.searchcriteria.checkIn.getDate())) / (1000 * 60 * 60 * 24)),
      Preferred_Arrival_Time: new Date(this.searchcriteria.checkIn)
    };

    const resOccupancy = {
      Adults: this.searchcriteria.adults,
      Children: this.searchcriteria.children,
      Infants: 0
    };

    const resSource = {
      Category: this.reservationForm.value.Source.Category,
      SubCategory: this.reservationForm.value.Source.SubCategory
    };


    this.reservationForm.get(['Dates']).setValue(resDates);
    this.reservationForm.get('Occupancy').setValue(resOccupancy);
    this.reservationForm.get('Source').setValue(resSource);
  }


  public addReservationRooms(rTypePos: number, rP: RatePlan, occ: {ad: number, chd: number, inf?: number}) {

    const hotelRT = this.availableHotelRoomTypes[rTypePos];

    this.availableHotelRoomTypes[rTypePos].Units--;
    const rpPos = this.availableHotelRoomTypes[rTypePos].Rate_plans.findIndex(e => e.Code === rP.Code);

    this.availableHotelRoomTypes[rTypePos].Rate_plans[rpPos].Alloted_for_sale--;

    const infants = occ.inf ? occ.inf : 0;

    const resRoom = this.fb.group({
      Adults: [occ.ad, [Validators.required, Validators.min(1), Validators.max(hotelRT.Max_adults)]],
      Children: [occ.chd, [Validators.required, Validators.max(hotelRT.Max_kids)]],
      Infants: [infants, Validators.max(hotelRT.Max_infants)],
      Guests: (this.reservationForm.get('Primary_contact') as FormGroup),
      Room_type: hotelRT.Type,
      Rate_plan: rP.Code,
      Room_number: [''],
      Occupied: [false],
      Price: [this.cellDatesArr[rTypePos][this.cellDatesArr[rTypePos].length - 1].amount]
    });

    (this.reservationForm.get('Rooms') as FormArray).push(resRoom);

    // Find if there is any deposit required.
    if (rP.Payment.Deposit.required) {
      // eslint-disable-next-line max-len
      this.paymentDepositAmount = this.paymentDepositAmount + +rP.Payment.Deposit.fixed !== 0 ? +rP.Payment.Deposit.fixed : resRoom.value.Price * +rP.Payment.Deposit.percent / 100;
    }

    // Find if there is any cancellation fee.
    if (rP.Payment.Cancellation.allowed) {
      this.paymentCancellationAmount = this.paymentCancellationAmount + +resRoom.value.Price * rP.Payment.Cancellation.Amount_perc / 100;
      this.paymentCancellationDays = rP.Payment.Cancellation.Days_before_arrival;
    }

    // Update Price field of Reservation
    let roomSubTotal = 0;

    this.reservationForm.get('Rooms').value.forEach(rm => {
      roomSubTotal = roomSubTotal + rm.Price;
    });

    // Find aplicable taxes for Reservations
    const applicableTaxes = this.reservationProduct.Applicable_taxes;

    const taxesSubtotal = this.reservationForm.value.Price.Taxes_subtotal;
    const accPrice = +this.reservationForm.value.Price.Accommodation;
    let roomPrice = +resRoom.value.Price;
    let roomTaxes = 0;
    let taxCode = '';

    (this.reservationForm.get(['Price', 'Accommodation_taxes']) as FormArray).removeAt(0); // remove the dummy entry

    applicableTaxes.forEach(applTax => {
      const chargeType = applTax.Charge_type; // Fixed or Percent
      const chargeValue = applTax.Charge_value; // Fixed amount or Percentage value
      const chargeValidFor = applTax.Valid_for.substr(applTax.Valid_for.length - 4);
      const chargeIncludedInPrice = applTax.Included_in_price;

      let chargeAmount = 0;
      if (chargeValidFor === 'pppn') {
        if (chargeType === 'Fixed') {
          chargeAmount = chargeValue * resRoom.value.Adults * this.reservationForm.value.Dates.Nights;
        } else {
          chargeAmount = chargeValue * resRoom.value.Adults * this.reservationForm.value.Dates.Nights / 100;
        }
      }

      if (chargeValidFor === 'prpn') {
        if (chargeType === 'Fixed') {
          chargeAmount = chargeValue * this.reservationForm.value.Rooms.length * this.reservationForm.value.Dates.Nights;
        } else {
        }
      }

      if (chargeValidFor === '-pps') {
        if (chargeType === 'Fixed') {
          chargeAmount = chargeValue;
        } else {
          chargeAmount = chargeValue * roomPrice / 100;
        }
      }

      if (chargeIncludedInPrice) {
        roomPrice = roomPrice - chargeAmount;
      } else {
        roomPrice = roomPrice;
      }


      roomTaxes = roomTaxes + chargeAmount;

      taxCode = applTax.Name;

      const arrEntry = this.fb.group({
        Tax_Code: taxCode,
        Tax_Amount: chargeAmount,
        Tax_IncludedInPrice: chargeIncludedInPrice
      });

      (this.reservationForm.get(['Price', 'Accommodation_taxes']) as FormArray).push(arrEntry);

    });

    const resPrice = {
      Accommodation: accPrice + roomPrice,
      Taxes_subtotal: +taxesSubtotal + roomTaxes,
      Accommodation_subtotal: accPrice + roomPrice + taxesSubtotal + roomTaxes,
      Extras: this.reservationForm.value.Price.Extras,
      Extras_subtotals: this.reservationForm.value.Price.Extras_subtotals,
      Total: accPrice + roomPrice + taxesSubtotal + roomTaxes + this.reservationForm.value.Price.Extras_subtotals
    };

    this.reservationForm.get(['Price']).patchValue(resPrice);

    this.reservationAmountForm.get(['newAmount']).setValue(resPrice.Accommodation_subtotal);

    this.spinner.show();
    // eslint-disable-next-line max-len
    this.inventoryService.UpdateInventoryAvailability('Remove', this.company.id, this.operatioPeriodId, this.inventoryResults, hotelRT.Type, rP.Code);
    this.spinner.hide();

    this.translatePolicyTexts();

    this.roomTypesSelected = true;
  }


  public removeReservationRooms(rTypePos: number) {

    const roomTypeType = this.reservationForm.value.Rooms[rTypePos].Room_type;
    const ratePlanCode = this.reservationForm.value.Rooms[rTypePos].Rate_plan;

    const posRoomType = this.availableHotelRoomTypes.findIndex(e => e.Type === roomTypeType);
    const posRatePlan = this.availableHotelRoomTypes[posRoomType].Rate_plans.findIndex(e => e.Code === ratePlanCode);

    this.availableHotelRoomTypes[posRoomType].Units++;
    this.availableHotelRoomTypes[posRoomType].Rate_plans[posRatePlan].Alloted_for_sale++;

    const totAdults = this.reservationForm.value.Occupancy.Adults;
    const totChildren = this.reservationForm.value.Occupancy.Children;
    const totInfants = this.reservationForm.value.Occupancy.Infants;



    // Find aplicable taxes for Reservations
    const applicableTaxes = this.reservationProduct.Applicable_taxes;

    const taxesSubtotal = this.reservationForm.value.Price.Taxes_subtotal;
    const accTaxes = +this.reservationForm.value.Price.Accommodation_taxes;
    const accPrice = +this.reservationForm.value.Price.Accommodation;
    let roomPrice = this.reservationForm.value.Rooms[rTypePos].Price;
    let roomTaxes = 0;
    let taxCode = '';

    applicableTaxes.forEach(applTax => {
      const chargeType = applTax.Charge_type; // Fixed or Percent
      const chargeValue = applTax.Charge_value; // Fixed amount or Percentage value
      const chargeValidFor = applTax.Valid_for.substr(applTax.Valid_for.length - 4);
      const chargeIncludedInPrice = applTax.Included_in_price;

      let chargeAmount = 0;
      if (chargeValidFor === 'pppn') {
        if (chargeType === 'Fixed') {
          chargeAmount = chargeValue * totAdults * this.reservationForm.value.Dates.Nights;
        } else {
          chargeAmount = chargeValue * totAdults * this.reservationForm.value.Dates.Nights / 100;
        }
      }

      if (chargeValidFor === 'prpn') {
        if (chargeType === 'Fixed') {
          chargeAmount = chargeValue * this.reservationForm.value.Rooms.length - 1 * this.reservationForm.value.Dates.Nights;
        } else {
        }
      }

      if (chargeValidFor === '-pps') {
        if (chargeType === 'Fixed') {
          chargeAmount = chargeValue;
        } else {
          chargeAmount = chargeValue * roomPrice / 100;
        }
      }


      if (chargeIncludedInPrice) {
        roomPrice = roomPrice - chargeAmount;
      } else {
        roomPrice = roomPrice;
      }

      taxCode = applTax.Name;
      roomTaxes = roomTaxes - chargeAmount;

      const pos = this.reservationForm.value.Price.Accommodation_taxes.findIndex(e => e.Tax_Code === taxCode);
      (this.reservationForm.get(['Price', 'Accommodation_taxes']) as FormArray).removeAt(pos);
    });



    const resPrice = {
      Accommodation: accPrice - roomPrice,
      Taxes_subtotal: +taxesSubtotal - roomTaxes,
      Accommodation_subtotal: (accPrice - roomPrice) + (taxesSubtotal - roomTaxes),
      Extras: this.reservationForm.value.Price.Extras,
      Extras_subtotals: this.reservationForm.value.Price.Extras_subtotals,
      Total: (accPrice - roomPrice) + (taxesSubtotal - roomTaxes) + this.reservationForm.value.Price.Extras_subtotals
    };

    this.reservationForm.get(['Price']).patchValue(resPrice);

    this.reservationAmountForm.get(['newAmount']).setValue(resPrice.Accommodation_subtotal);

    this.spinner.show();
    // eslint-disable-next-line max-len
    this.inventoryService.UpdateInventoryAvailability('Add', this.company.id, this.operatioPeriodId, this.inventoryResults, roomTypeType, ratePlanCode);
    this.spinner.hide();

    (this.reservationForm.get('Rooms') as FormArray).removeAt(rTypePos);

  }

  public toggleRoomTypesSelected()  {
    this.roomTypesSelected = true;
  }

  private translatePolicyTexts() {
    if (this.paymentDepositAmount !== 0) {
      this.paymentDepositText1 =  this.translate.instant(_TRANSLATE('Property.Policies.Deposit-text1')) ;
      this.paymentDepositText2 =  this.translate.instant(_TRANSLATE('Property.Policies.Deposit-text2')) ;
    } else {
      this.paymentDepositText1 =  this.translate.instant(_TRANSLATE('Property.Policies.Deposit-no-text')) ;
    }

    if (this.paymentCancellationAmount !== 0) {
      this.paymentCancellationText1 =  this.translate.instant(_TRANSLATE('Property.Policies.Cancellation-text1')) ;
      this.paymentCancellationText2 =  this.translate.instant(_TRANSLATE('Property.Policies.Cancellation-text2')) ;
      this.paymentCancellationText3 =  this.translate.instant(_TRANSLATE('Property.Policies.Cancellation-text3')) ;
    } else {
      this.paymentCancellationText1 =  this.translate.instant(_TRANSLATE('Property.Policies.Cancellation-no-text')) ;
    }

    this.historyMsgReservationCreated = this.translate.instant(_TRANSLATE('Common.Reservation-Created')) ;
    this.historyMsgByUser = this.translate.instant(_TRANSLATE('Common.ByUser')) ;
    this.historyMsgByCustomer = this.translate.instant(_TRANSLATE('Common.ByCustomer')) ;
  }

  public finalizeReservation(rFormValue: Reservation, userSurname: string | null) {

    const primaryContact = rFormValue.Primary_contact;
    rFormValue.Rooms.forEach(room => {
      room.Guests = primaryContact;
    });

    let historyLogActionBy;

    if (rFormValue.Source.SubCategory === '/Booking_Sources_Sub_Categories/smBzxTW1ruPjiU8ZVQgx') {
      historyLogActionBy =  'Customer';
    }

    if (rFormValue.Source.Category === '/Booking_Sources/51D3yZatQEmxbXWcAuFy') {
      historyLogActionBy =  rFormValue.Source.SubCategory;
    }

    if (rFormValue.Source.Category === '/Booking_Sources/2519lwySRUh7iD4xvrRq' && rFormValue.Source.SubCategory !== '/Booking_Sources/2519lwySRUh7iD4xvrRq') {
      historyLogActionBy =  userSurname;
    }

    const historyLogInfo: HistoryInfo = {
      Action_Date: new Date().toDateString(),
      Action: this.historyMsgReservationCreated,
      Action_By: historyLogActionBy
    };

    const updatedResFormValue = this.updateReservationHistory(rFormValue, historyLogInfo);

    this.reservationsService.saveReservation(this.company.id, updatedResFormValue)
      .then(() => this.reservationFinalized = true)
      .catch(err => {
        const delMsgNoSuccess =
          this.translate.instant(_TRANSLATE('Messages.Error.Error')) +
          this.translate.instant(_TRANSLATE('Messages.Error.NewReservation-save')) + ' ' + err;

        this.appService.msg('error', delMsgNoSuccess);
      });
  }


  getSubCategoriesForSelectedCategory($event: MatSelectChange) {
    const selectedBookingSourceCategory = $event.value;

    this.store.dispatch(new SourceSubcategoriesForCategoryRequested({BookingSourceCode: selectedBookingSourceCategory}));

    this.subCategories$ = this.store
      .pipe(
        select(selectAllBookingSourcesSubCategories)
      );
  }

  editPrice() {
    this.editPriceMode = true;
  }

  RegisterNewPrice() {
    this.reservationForm.get(['Price', 'Accommodation_subtotal']).setValue(this.reservationAmountForm.value.newAmount);
    this.editPriceMode = false;
  }

  ResetInitialPrice() {
    this.editPriceMode = false;
  }

  updateReservationHistory(resFormValue: Reservation, historyLogEntry: HistoryInfo): Reservation {
    resFormValue.History.push(historyLogEntry);
    return resFormValue;
  }


  ngOnDestroy(): void {
    this.ngLanguageChangeUnsubscribe$.next();
    this.ngLanguageChangeUnsubscribe$.complete();

    this.ngTaxesFeesUnsubscribe$.next();
    this.ngTaxesFeesUnsubscribe$.complete();

    this.ngSearchCriteriaUnsubscribe$.next();
    this.ngSearchCriteriaUnsubscribe$.complete();

    if (!this.reservationFinalized) {
      if (this.reservationForm.value.Rooms.length > 0) {
        this.spinner.show();
        this.reservationForm.value.Rooms.forEach(room => {
          const roomType = room.Room_type;
          const ratePlanId = room.Rate_plan;
          // eslint-disable-next-line max-len
          this.inventoryService.UpdateInventoryAvailability('Add', this.company.id, this.operatioPeriodId, this.inventoryResults, roomType, ratePlanId);
        });
        this.spinner.hide();
      }
    }
  }

}

