import { Action } from '@ngrx/store';
import { AuthActions, AuthActionTypes } from '../actions/auth.actions';
import { User } from '../../data_model/User';
import { AvailabilitySearchhActions, AvailabilitySearchActionTypes } from '../actions/availabilitysearch.actions';


const todayDate = new Date();
const tomorrowDate = new Date();
tomorrowDate.setDate(todayDate.getDate() + 1);

export interface AvailabilitySearchState {
  checkIn: Date;
  checkOut: Date;
  adults: number;
  children: number;
}

export const initialAvailabilitySearchState: AvailabilitySearchState = {
  checkIn: new Date(todayDate),
  checkOut: new Date(tomorrowDate),
  adults: 2,
  children: 0
};

// eslint-disable-next-line max-len
export function availabilitySearchReducer(state = initialAvailabilitySearchState, action: AvailabilitySearchhActions): AvailabilitySearchState {
  switch (action.type) {

    case AvailabilitySearchActionTypes.SetCtiteria:
      return action.payload.searchCriteria;

    default:
      return state;
  }
}
