import { createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {BookingSourceSubCategory} from '../../data_model/BookingSources';
import {BookingSourcesSubCategoryActions, BookingSourcesSubcategoryActionTypes} from '../actions/bookingsourcessubcategories.actions';


export interface BookingSourcesSubCategoriesState extends EntityState<BookingSourceSubCategory> {
  allBookingSourcesSubCategoriesLoaded: boolean;
}

function sortByCode(b1: BookingSourceSubCategory, b2: BookingSourceSubCategory) {
  return +b1.Code.localeCompare(b2.Code);
}

export const bookingSourcesSubCategoriesAdapter: EntityAdapter<BookingSourceSubCategory> = createEntityAdapter<BookingSourceSubCategory>({
  sortComparer: sortByCode
});

// eslint-disable-next-line max-len
export const initialBookingSourcesSubCategoriesState: BookingSourcesSubCategoriesState = bookingSourcesSubCategoriesAdapter.getInitialState({
  allBookingSourcesSubCategoriesLoaded: false
});

// eslint-disable-next-line max-len
export function bookingSourcesSubCategoriesReducer( state = initialBookingSourcesSubCategoriesState, action: BookingSourcesSubCategoryActions): BookingSourcesSubCategoriesState {

    switch (action.type) {

      case BookingSourcesSubcategoryActionTypes.SourceSubcategoryLoaded:
        return bookingSourcesSubCategoriesAdapter.addOne(action.payload.BookingSourceSubcategory, state);

      case BookingSourcesSubcategoryActionTypes.SourceSubcategoriesForCategoryLoaded:
        return bookingSourcesSubCategoriesAdapter.addMany(action.payload.sourceSubcategories, state);

      case BookingSourcesSubcategoryActionTypes.AllSourceSubcategoriesLoaded:
        // eslint-disable-next-line max-len
        return bookingSourcesSubCategoriesAdapter.addAll(action.payload.sourceSubcategories, {...state, allBookingSourcesSubCategoriesLoaded: true});


      default:
        return state;
    }
}


export const {
  selectAll,
  selectIds,
  selectEntities,
  selectTotal
} = bookingSourcesSubCategoriesAdapter.getSelectors();
