import { Injectable } from '@angular/core';

import { withLatestFrom, mergeMap, map, filter } from 'rxjs/operators';

import {select, Store} from '@ngrx/store';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { AppState } from '../reducers';

import {
  UserRequested,
  UserLoaded,
  UsersActionTypes,
  UsersLoaded,
  UsersRequested, UsersOfCompanyRequested, UsersOfCompanyLoaded
} from '../actions/users.actions';

import { UsersService } from '../../services/users.service';


@Injectable()
export class UsersEffects {

  @Effect()
  loadAllUsers$ = this.actions$
    .pipe(
        ofType<UsersRequested>(UsersActionTypes.UsersRequested),
        // eslint-disable-next-line max-len
        mergeMap(actions => this.usersService.getAllUsers()),
        map(users => new UsersLoaded({ users }))
    );

  loadAllUsersOfCompany$ = this.actions$
    .pipe(
      ofType<UsersOfCompanyRequested>(UsersActionTypes.UsersOfCompanyRequested),
      // eslint-disable-next-line max-len
      mergeMap(actions => this.usersService.getAllUsersForCompany(actions.payload.companyId)),
      map(users => new UsersLoaded({ users }))
    );


  @Effect()
  loadUser$ = this.actions$
    .pipe(
      ofType<UserRequested>(UsersActionTypes.UserRequested),
      mergeMap(action => this.usersService.getUserData(action.payload.userId)),
      map(user => new UserLoaded({ user }))
    );


  constructor(private actions$: Actions,
              private store: Store<AppState>,
              private usersService: UsersService) {}

}
