import { Injectable } from '@angular/core';

import { mergeMap, map } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { AppState } from '../reducers';
import {IdsActionTypes, IdsRequested, IdsLoaded, SetNewCompanyId} from '../actions/ids.actions';
import { AppService } from '../../../app.service';


@Injectable()
export class IdsEffects {

  @Effect()
  loadAllId$ = this.actions$
    .pipe(
        ofType<IdsRequested>(IdsActionTypes.IdsRequested),
        mergeMap(action => this.appService.getAllIds()),
        map(ids => new IdsLoaded({ids}))
    );

  setNewCompId$ = this.actions$
    .pipe(
      ofType<SetNewCompanyId>(IdsActionTypes.SetNewCompanyId),
      mergeMap(action => this.appService.updateNewCompId(action.payload.newCompId))
    );

  constructor(private actions$: Actions,
              private store: Store<AppState>,
              private appService: AppService) {}

}
