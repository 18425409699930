import { Action } from '@ngrx/store';
import { Amenity } from '../../data_model/Amenity';



export enum AmenitiesActionTypes {
  AmenityRequested = '[Setup] Amenity Requested',
  AmenityLoaded = '[Rooms API] Amenity Loaded',
  AllAmenitiesRequested = '[Setup] All Amenities Requested',
  AllAmenitiesLoaded = '[Rooms API] All Amenities Loaded'
}

export class AmenityRequested implements Action {

  readonly type = AmenitiesActionTypes.AmenityRequested;

  constructor(public payload: { amenityId: string }) {

  }

}


export class AmenityLoaded implements Action {

  readonly type = AmenitiesActionTypes.AmenityLoaded;

  constructor(public payload: { amenity: Amenity }) {

  }

}


export class AllAmenitiesRequested implements Action {

  readonly type = AmenitiesActionTypes.AllAmenitiesRequested;

}


export class AllAmenitiesLoaded implements Action {

  readonly type = AmenitiesActionTypes.AllAmenitiesLoaded;

  constructor(public payload: { amenities: Amenity[] }) {

  }

}


export type  AmenitiesActions =
  AmenityRequested |
  AmenityLoaded |
  AllAmenitiesRequested |
  AllAmenitiesLoaded ;

