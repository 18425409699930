import {Component, OnInit, Input, ChangeDetectionStrategy, ViewChild, AfterViewInit, OnDestroy, Output, EventEmitter} from '@angular/core';

import {Observable, Subject} from 'rxjs';

import { Store, select } from '@ngrx/store';
import { AppState } from '../../common/store/reducers';
import { selectSystemCurrency } from '../../common/store/selectors/company.selectors';

import { AppService } from '../../app.service';

import {Reservation} from '../../common/data_model/Reservation';
import {selectCurrentLanguageCode} from '../../common/store/selectors/languages.selectors';
import {SelectionModel} from '@angular/cdk/collections';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

import {takeUntil, tap} from 'rxjs/operators';
import {PageQuery} from '../../common/store/actions/reservations.actions';


@Component({
  selector: 'app-view-reservations-list-brief',
  templateUrl: './view-reservations-list-brief.component.html',
  styleUrls: ['./view-reservations-list-brief.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ViewReservationsListBriefComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() reservations$: Observable<Reservation[]>;
  @Input() reservationType: string;
  @Input() pageSize: number;
  @Input() filterApllicable: boolean;

  @Output() reservationSelected: EventEmitter<Reservation | null> = new EventEmitter<Reservation | null>();


  public currentLanguage$: Observable<string>;

  public systemCurrency$: Observable<string>;

  public reservations: Reservation[] = [] ;

  public ngDatasourceUnsubscribe$: Subject<any> = new Subject();

  public dataSource = new MatTableDataSource<Reservation>();

  dColumnsReservations: Array<string>;

  selection = new SelectionModel<any>(true, []);

  searchFilter: string;

  reservationHasBeenSelected: boolean;

  previousRowSelected: any = null;

  selectedRowIndex = '';

  matSortColumn: string;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;


  constructor(private store: Store<AppState>, private appService: AppService ) {

  }

  ngOnInit() {

    this.noReservationSelected();

    this.reservations$
      .pipe(
        takeUntil(this.ngDatasourceUnsubscribe$)
      )
      .subscribe(reserv => {
        this.reservations = reserv;
        console.log(this.reservations);
        this.dataSource.data = this.reservations as Reservation[];
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });

    this.currentLanguage$ = this.store
      .pipe(
        select(selectCurrentLanguageCode)
      );

    this.systemCurrency$ = this.store
      .pipe(
        select(selectSystemCurrency)
      );

    if (this.reservationType === 'All') {
      // eslint-disable-next-line max-len
      this.dColumnsReservations = ['select', 'id', 'Booked_date', 'CheckIn_date', 'CheckOut_date', 'Nights', 'Room_number', 'Name', 'Surname', 'Total', 'Status', 'Source'];
      this.matSortColumn = 'id';
    }

    if (this.reservationType === 'Booked') {
      // eslint-disable-next-line max-len
      this.dColumnsReservations = ['number', 'id', 'CheckIn_date', 'CheckOut_date', 'Occupancy', 'Room_type', 'Total', 'Country', 'Source'];
      this.matSortColumn = 'number';
    }

    if (this.reservationType === 'Arriving') {
      // eslint-disable-next-line max-len
      this.dColumnsReservations = ['number', 'id', 'CheckOut_date', 'Name', 'Occupancy', 'Room_type', 'Total', 'Country', 'Source'];
      this.matSortColumn = 'number';
    }

    if (this.reservationType === 'Departing') {
      // eslint-disable-next-line max-len
      this.dColumnsReservations = ['number', 'id', 'CheckIn_date', 'Room_number', 'Name', 'Occupancy', 'Total', 'Country', 'Source'];
      this.matSortColumn = 'number';
    }
  }

  ngAfterViewInit() {

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filter: any) {
    const filterValue = filter.value;
    this.noReservationSelected();
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  trackByUid(index, item) {
    return item.uid;
  }

  rowchecked($event, row) {
    this.selection.clear();
    $event.stopPropagation();
    if (this.previousRowSelected !== row) {
      this.previousRowSelected = row;
      this.selectedRow(row);
      this.highlight(row);
    } else {
      this.reservationHasBeenSelected = true;
      this.highlight(row);
    }
  }

  selectedRow(newReservation: Reservation) {
    this.reservationHasBeenSelected = true;
    this.reservationSelected.emit(newReservation) ;
  }

  noReservationSelected() {
    this.selection.clear();
    this.reservationHasBeenSelected = false;
    this.reservationSelected.emit(null);
  }

  highlight(row) {
    this.selectedRowIndex = row.id;
  }

  findCurrLanguagePos(someArray: any[], val: string): number {
    return someArray.findIndex(e => e.lang === val);
  }

  ngOnDestroy() {
    this.ngDatasourceUnsubscribe$.next();
    this.ngDatasourceUnsubscribe$.complete();
  }

}
