import { Store } from '@ngrx/store';
import { Component, OnInit, Input } from '@angular/core';
import { NgForm, FormGroup, Form } from '@angular/forms';
import $ from 'jquery';
import { Router } from '@angular/router';
import { AppState } from '../../common/store/reducers';
import { SetCtiteria } from '../../common/store/actions/availabilitysearch.actions';

@Component({
  selector: 'app-booking-form',
  templateUrl: './booking-form.component.html',
  styleUrls: ['./booking-form.component.scss']
})
export class BookingFormComponent implements OnInit {

  @Input() mode: string;
  @Input() fromDate: Date;
  @Input() toDate: Date;
  @Input() adults: number;
  @Input() children: number;

  public currentUrl: string;
  public chkInDate: Date;
  public chkOutDate: Date;
  public minDateCheckIn: Date;
  public minDateCheckOut: Date;
  public maxDateCheckOut: Date;
  public nbrAdults: number;
  public nbrChd: number;
  public nbrRooms: number;

  constructor(private store: Store<AppState>,
              private router: Router) {
  }

  ngOnInit() {
    this.currentUrl = this.router.url;
    this.chkInDate = this.fromDate ? this.fromDate : new Date();
    this.chkOutDate = this.toDate ? this.toDate : new Date();
    this.minDateCheckIn = new Date();
    this.minDateCheckOut = new Date();
    this.minDateCheckIn.setDate(this.chkInDate.getDate());
    this.minDateCheckOut.setDate(this.chkInDate.getDate() + 1);
    this.nbrAdults = this.adults ? this.adults : 2;
    this.nbrChd = this.children ? this.children : 0;
    this.nbrRooms = 1;
  }

  onCheckInDateChange(ciDate: Date) {
    const tempciDate = new Date(ciDate);
    const tempCheckOut = new Date(ciDate);
    tempCheckOut.setDate(tempCheckOut.getDate() + 1);
    this.minDateCheckOut = this.mode === 'web' ? tempCheckOut : null;
    if (this.mode === 'web') {
      this.maxDateCheckOut = new Date();
      this.maxDateCheckOut.setDate(this.minDateCheckOut.getDate() + 30);
    } else {
      this.maxDateCheckOut = null;
    }
    this.chkOutDate = tempCheckOut;
  }

  IncreaseAdultsQuantity() {
    this.nbrAdults++;
  }

  DecreseAdultsQuantity() {
    this.nbrAdults = this.nbrAdults > 0 ? this.nbrAdults - 1 : 0;
  }

  IncreaseChdQuantity() {
    this.nbrChd++;
  }

  DecreseChdQuantity() {
    this.nbrChd = this.nbrChd > 0 ? this.nbrChd - 1 : 0;
  }

  onSubmit(bFrm: NgForm) {
    const searchCriteria = {
      checkIn: bFrm.value.chkInDate,
      checkOut: bFrm.value.chkOutDate,
      adults: bFrm.value.nbrAdults,
      children: bFrm.value.nbrChd
    };

    this.store.dispatch(new SetCtiteria({ searchCriteria }));
    console.log(this.currentUrl);
    if (this.currentUrl.includes( '/rooms')) {
      this.router.navigateByUrl('rooms/availabilitysearch');
    }
  }

}
