import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CountriesState } from '../reducers/countries.reducer';
import * as fromCountries from '../reducers/countries.reducer';

export const selectCountriesState = createFeatureSelector<CountriesState>('countries');


export const selectCountryById = (countryId: string) => createSelector(
  selectCountriesState,
  countriesState => countriesState.entities[countryId]
);


export const selectAllCountries = createSelector(
  selectCountriesState,
  fromCountries.selectAll
);

export const allCountriesLoaded = createSelector(
  selectCountriesState,
  countriesState => countriesState.allCountriesLoaded
);


