import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {NgxSpinnerService} from 'ngx-spinner';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../common/store/reducers';
import {TranslateService} from '@ngx-translate/core';
import { _TRANSLATE } from '../../../../../common/services/translation-marker';
import {HistoryInfo, Reservation} from '../../../../../common/data_model/Reservation';
import {SelectionModel} from '@angular/cdk/collections';

@Component({
  selector: 'app-view-edit-reservation-history',
  templateUrl: './view-edit-reservation-history.component.html',
  styleUrls: ['./view-edit-reservation-history.component.scss'],
})


export class ViewEditReservationHistoryComponent implements OnInit {

  @Input() reservation: Reservation;

  public reservationHistory: HistoryInfo[];

  public dataSource = new MatTableDataSource<HistoryInfo>();

  dColumnsReservationHistory: Array<string>;

  selection = new SelectionModel<any>(true, []);

  searchFilter: string;

  reservationHistoryHasBeenSelected: boolean;

  reservationHistorySelected: HistoryInfo;

  previousRowSelected: any = null;

  selectedRowIndex = -1;

  historyActionBy: string;

  matSortColumn: string;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;


  constructor(
    public store: Store<AppState>,
    public spinner: NgxSpinnerService,
    public translate: TranslateService
  ) {}

  ngOnInit() {

    const reservationid = this.reservation.id;

    this.reservationHistory = this.reservation.History;
    this.prepareTable();

  }

  prepareTable() {
    this.dataSource.data = this.reservationHistory;
    // eslint-disable-next-line max-len
    this.dColumnsReservationHistory = ['select', 'Action_Date', 'Action', 'Action_By'];
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filter: any) {
    const filterValue = filter.value;
    this.noReservationSelected();
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  trackByUid(index, item) {
    return item.uid;
  }

  rowchecked($event, row) {
    this.selection.clear();
    $event.stopPropagation();
    if (this.previousRowSelected !== row) {
      this.previousRowSelected = row;
      this.selectedRow(row);
      this.highlight(row);
    } else {
      this.reservationHistoryHasBeenSelected = true;
      this.highlight(row);
    }
  }

  selectedRow(newReservationHistory: HistoryInfo) {
    this.reservationHistoryHasBeenSelected = true;
    this.reservationHistorySelected = newReservationHistory ;
  }

  noReservationSelected() {
    this.selection.clear();
    this.reservationHistoryHasBeenSelected = false;
    this.reservationHistorySelected = null;
  }

  highlight(row) {
    this.selectedRowIndex = row.id;
  }

  findCurrLanguagePos(someArray: any[], val: string): number {
    return someArray.findIndex(e => e.lang === val);
  }

  translAction(pos: number) {
    const historyActionEntry = this.reservationHistory[pos].Action;
    return this.translate.instant(_TRANSLATE(historyActionEntry));
  }

  translActionBy(pos: number) {
    const historyActionByEntry = this.reservationHistory[pos].Action_By;
    let translationResult;
    if (historyActionByEntry === 'Customer') {
      translationResult = this.translate.instant(_TRANSLATE('Common.ByCustomer'));
      this.historyActionBy = '';
    } else {
      translationResult = this.translate.instant(_TRANSLATE('Common.ByUser'));
      this.historyActionBy = this.reservationHistory[pos].Action_By;
    }

    return translationResult;
  }
}
