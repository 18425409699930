import { Action } from '@ngrx/store';
import {BookingSource, BookingSourceSubCategory} from '../../data_model/BookingSources';




export enum BookingSourcesActionTypes {
  BookingSourceRequested = '[Reservations] Booking Source Requested',
  BookingSourceLoaded = '[Reservations API] Booking Source Loaded',
  AllBookingSourcesRequested = '[Reservations] All Booking Sources Requested',
  AllBookingSourcesLoaded = '[Reservations API] All Booking Sources Loaded',
}

export class BookingSourceRequested implements Action {

  readonly type = BookingSourcesActionTypes.BookingSourceRequested;

  constructor(public payload: { BookingSourceCode: string }) {

  }

}


export class BookingSourceLoaded implements Action {

  readonly type = BookingSourcesActionTypes.BookingSourceLoaded;

  constructor(public payload: { bookingSource: BookingSource }) {

  }

}


export class AllBookingSourcesRequested implements Action {

  readonly type = BookingSourcesActionTypes.AllBookingSourcesRequested;

}


export class AllBookingSourcesLoaded implements Action {

  readonly type = BookingSourcesActionTypes.AllBookingSourcesLoaded;

  constructor(public payload: { bookingSources: BookingSource[] }) {

  }
}


export type BookingSourcesActions =
  BookingSourceRequested |
  BookingSourceLoaded |
  AllBookingSourcesRequested |
  AllBookingSourcesLoaded;



