import { SidenavMenu } from './sidenav-menu.model';

export const sidenavMenuItemsPublic = [
  new SidenavMenu(1, 'Menu.Home', '/', null, null, false, 0),
  new SidenavMenu(61, 'Menu.Aboutus', '/about', null, null, false, 0),
  new SidenavMenu(62, 'Menu.Rooms', '/rooms', null, null, false, 0),
  new SidenavMenu(63, 'Menu.EatNDrink', '/404', null, null, false, 0),
  new SidenavMenu(80, 'Menu.Contactus', '/contact', null, null, false, 0),
  new SidenavMenu(90, 'Login.Signin', '/sign-in', null, null, false, 0)
];

export const sidenavMenuItemsPortal = [
  new SidenavMenu(1, 'Menu.Admin.Title', '/sysadmin', null, null, false, 0),
  new SidenavMenu(2, 'Menu.Dashboard', '/dashboard', null, null, false, 0),
  new SidenavMenu(61, 'Menu.Calendar', '/calendar', null, null, false, 0),
  new SidenavMenu(62, 'Menu.Reservations', '/reservations', null, null, false, 0),
  new SidenavMenu(63, 'Menu.RatesNAvailability', '/ratesnavailability', null, null, false, 0),
  new SidenavMenu(80, 'Menu.Guests', '/guests', null, null, false, 0),
  new SidenavMenu(90, 'Menu.Setup', '/', null, null, true, 0),
  new SidenavMenu(91, 'Menu.CoreInfo.Title', '/coreinfo', null, null, false, 90),
  new SidenavMenu(92, 'Menu.Account', '/account', null, null, false, 90),
  new SidenavMenu(93, 'Menu.My-Profile', '/profile', null, null, false, 90)
];



