import { Action } from '@ngrx/store';
import {BookingSource, BookingSourceSubCategory} from '../../data_model/BookingSources';




export enum BookingSourcesSubcategoryActionTypes {

  SourceSubcategoryRequested = '[Reservations] Source Subcategory Requested',
  SourceSubcategoryLoaded = '[Reservations API] Source Subcategory Loaded',
  SourceSubcategoriesForCategoryRequested = '[Reservations] Source Subcategories for a specific Category Requested',
  SourceSubcategoriesForCategoryLoaded = '[Reservations API] Source Subcategories for a specific Category Loaded',
  AllSourceSubcategoriesRequested = '[Reservations] All Source Subcategories Requested',
  AllSourceSubcategoriesLoaded = '[Reservations API] All Source Subcategories Loaded',
  SourceSubcategoriesCancelled = '[Reservations API] Source Subcategories Cancelled'
}


export class SourceSubcategoryRequested implements Action {

  readonly type = BookingSourcesSubcategoryActionTypes.SourceSubcategoryRequested;

  constructor(public payload: { BookingSourceSubCategoryCode: string }) {

  }
}



export class SourceSubcategoryLoaded implements Action {

  readonly type = BookingSourcesSubcategoryActionTypes.SourceSubcategoryLoaded;

  constructor(public payload: { BookingSourceSubcategory: BookingSourceSubCategory }) {

  }

}



export class SourceSubcategoriesForCategoryRequested implements Action {

  readonly type = BookingSourcesSubcategoryActionTypes.SourceSubcategoriesForCategoryRequested;

  constructor(public payload: { BookingSourceCode: string }) {

  }

}


export class SourceSubcategoriesForCategoryLoaded implements Action {

  readonly type = BookingSourcesSubcategoryActionTypes.SourceSubcategoriesForCategoryLoaded;

  constructor(public payload: { sourceSubcategories: BookingSourceSubCategory[] }) {

  }

}


export class AllSourceSubcategoriesRequested implements Action {

  readonly type = BookingSourcesSubcategoryActionTypes.AllSourceSubcategoriesRequested;

}


export class AllSourceSubcategoriesLoaded implements Action {

  readonly type = BookingSourcesSubcategoryActionTypes.AllSourceSubcategoriesLoaded;

  constructor(public payload: { sourceSubcategories: BookingSourceSubCategory[] }) {

  }

}


export class SourceSubcategoriesCancelled implements Action {

  readonly type = BookingSourcesSubcategoryActionTypes.SourceSubcategoriesCancelled;

}

export type BookingSourcesSubCategoryActions =
  SourceSubcategoryRequested |
  SourceSubcategoryLoaded |
  SourceSubcategoriesForCategoryRequested |
  SourceSubcategoriesForCategoryLoaded |
  AllSourceSubcategoriesRequested |
  AllSourceSubcategoriesLoaded |
  SourceSubcategoriesCancelled;
