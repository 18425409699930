import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { finalize, tap, take } from 'rxjs/operators';
import {FileUploadService} from './file-upload.service';


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})

export class FileUploadComponent implements OnInit {

  @Input() displayFullMode: boolean;
  @Input() companyKey: string | null;
  @Input() path: string;
  @Input() addInFileName: string;

  @Output() selectedFiles = new EventEmitter<FileList>();

  // BeforeUploading the new photo
  beforeUploadingNewPhoto = true;

  // Main task
  task: AngularFireUploadTask;

  // Progress monitoring
  color = 'green';
  mode: 'determinate';
  percentage: Observable<number>;

  snapshot: Observable<any>;

  // Download URL
  downloadURL: string;
  fileURL: Observable<string>;

  // State for dropzone CSS toggling
  isHovering: boolean;

  constructor(fupload: FileUploadService) {}

  ngOnInit() {

  }

  determineClass() {
    return {
      'dropzone': this.displayFullMode,
      'dropzone-mini': !this.displayFullMode
    };
  }


  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  detectFiles(selectedFiles: FileList) {
    this.selectedFiles.emit(selectedFiles)  ;
  }


  // Determines if the upload task is active
  isActive(snapshot) {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
  }


}
