import { Injectable } from '@angular/core';
import { FirestoreService } from '../../core/firestore.service';
import { first, map } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { Currency } from '../data_model/Currency';
import {HotelRoomType} from '../data_model/HotelRoomType';
import {TaxFee} from '../data_model/TaxesFees';
import {ProductService} from '../data_model/OtherProductsNServices';


@Injectable()

export class PaymentService {

  CompanyId: string;
  currencies: Observable<any[]>;
  currenciesCode: Observable<any[]>;

  constructor(private _fsService: FirestoreService) {}

  getCurrencies(): Observable<any[]> {
    return this._fsService.colWithIds$<any[]>('/Currencies').pipe(
      first()
    );
  }

  getCurrencyCodeByRef(currencyRef: any) {
    return this._fsService.doc$<any>(currencyRef).pipe(
      first(),
      map((currency) => currency = currency.Code)
    );
  }


  processPayment(token: any, CompanyId: string) {
    return this._fsService.add('Companies/' + CompanyId + '/Payments', {token: token.id});
  }

  public getTaxesFeesForCompany (companyId: string): Observable<TaxFee[]> {
    return this._fsService.colWithIds$<TaxFee[]>('Company/' + companyId + '/Taxes_Fees/');
  }

  public getProductsServicesForCompany (companyId: string): Observable<ProductService[]> {
    return this._fsService.colWithIds$<ProductService[]>('Company/' + companyId + '/Products_Services/');
  }

}
