import { createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import { BedType } from '../../data_model/BedType';
import { BedTypesActionTypes, BedTypesActions } from '../actions/bedtypes.actions';
import { AppState } from '.';


export interface BedTypesState extends EntityState<BedType> {
  allBedTypesLoaded: boolean;
}

function sortById(bt1: BedType, bt2: BedType) {
  return +bt1.id - +bt2.id;
}

export const bedtypesAdapter: EntityAdapter<BedType> = createEntityAdapter<BedType>({
  sortComparer: sortById
});

export const initialBedTypesState: BedTypesState = bedtypesAdapter.getInitialState({
  allBedTypesLoaded: false
});

export function bedtypesReducer( state = initialBedTypesState, action: BedTypesActions): BedTypesState {

    switch (action.type) {

      case BedTypesActionTypes.BedTypeLoaded:
        return bedtypesAdapter.addOne(action.payload.bedtype, state);

      case BedTypesActionTypes.AllBedTypesLoaded:
        return bedtypesAdapter.addAll(action.payload.bedtypes, {...state, allBedTypesLoaded: true});


      default:
        return state;
    }
}


export const {
  selectAll,
  selectIds,
  selectEntities,
  selectTotal
} = bedtypesAdapter.getSelectors();
