import { SupportService } from './common/services/support.service';
import { CurrenciesEffects } from './common/store/effects/currencies.effects';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AgmCoreModule } from '@agm/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { NgxGalleryModule } from 'ngx-gallery';

import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { CustomSerializer } from './common/store/utils';
import { reducers, metaReducers } from './common/store/reducers';

import { OverlayContainer } from '@angular/cdk/overlay';
import { CustomOverlayContainer } from './theme/utils/custom-overlay-container';

import { routing } from './app.routing';
import { InvalidlogindialogComponent } from './pages/sign-in/invalidlogindialog.component';
import { AppComponent } from './app.component';
import { PagesComponent } from './pages/pages.component';
// import { AuthPagesComponent } from './authpages/authpages.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { ComingSoonComponent } from './pages/coming-soon/coming-soon.component';
import { TopMenuComponent } from './theme/components/top-menu/top-menu.component';
import { MenuComponent } from './theme/components/menu/menu.component';
import { SidenavMenuComponent } from './theme/components/sidenav-menu/sidenav-menu.component';
import { BreadcrumbComponent } from './theme/components/breadcrumb/breadcrumb.component';

import { environment } from '../environments/environment';
import { AppSettings } from './app.settings';
import { AppService } from './app.service';
import { FileUploadService} from './shared/file-upload/file-upload.service';
import { DialogService } from './common/services/dialog.service';
import { CompanyService } from './common/services/company.service';
import { RoomsService } from './common/services/rooms.service';
import { InventoryService } from './common/services/inventory.service';
import { ReservationsService } from './common/services/reservations.service';
import { AppInterceptor } from './theme/utils/app-interceptor';
// import { OptionsComponent } from './theme/components/options/options.component';
// import { FooterComponent } from './theme/components/footer/footer.component';
import { AuthEffects } from './common/store/effects/auth.effects';
import { CompanyEffects } from './common/store/effects/company.effects';
import { RoomsEffects } from './common/store/effects/rooms.effects';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { LayoutModule } from '@angular/cdk/layout';
import { AmenitiesEffects } from './common/store/effects/amenities.effects';
import { BedTypesEffects } from './common/store/effects/bedtypes.effects';
import { PropertyTypesEffects } from './common/store/effects/propertytypes.effects';
import { RoomTypesEffects } from './common/store/effects/roomtypes.effects';
import { LanguagesEffects } from './common/store/effects/languages.effects';
import { UsersEffects } from './common/store/effects/users.effects';
import { RolesEffects } from './common/store/effects/roles.effects';
import { SharedUtilsModule } from './common/modules/sharedutils.module';
import { IdsEffects } from './common/store/effects/ids.effects';
import { HotelRoomTypesEffects } from './common/store/effects/hotelroomtypes.effects';
import { CountriesEffects } from './common/store/effects/countries.effects';
import { OperationPeriodsEffects } from './common/store/effects/operationperiods.effects';
import { IntervalsEffects } from './common/store/effects/intervals.effects';
import { TaxesFeesEffects } from './common/store/effects/taxesfees.effects';
import { ProductsServicesEffects } from './common/store/effects/productsservices.effects';
import { ReservationsEffects } from './common/store/effects/reservations.effects';
import { BookingSourcesEffects } from './common/store/effects/bookingsources.effects';
import { PaymentService } from './common/services/payment.service';
import {BookingsourcessubcategoriesEffects} from './common/store/effects/bookingsourcessubcategories.effects';


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
   imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxSpinnerModule,
    NgxGalleryModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAxSgHvMfaSAo5M-H8pr97o02gNzWJcRxY'
     }),
     TranslateModule.forRoot({
       loader: {
         provide: TranslateLoader,
         useFactory: HttpLoaderFactory,
         deps: [HttpClient]
       }
     }),
    CoreModule,
    SharedModule,
    SharedUtilsModule,
    routing,
    StoreModule.forRoot(reducers, { metaReducers }),
      !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot(
      [
        LanguagesEffects,
        IdsEffects,
        CurrenciesEffects,
        CountriesEffects,
        AmenitiesEffects,
        BedTypesEffects,
        PropertyTypesEffects,
        RoomTypesEffects,
        AuthEffects,
        UsersEffects,
        RolesEffects,
        CompanyEffects,
        HotelRoomTypesEffects,
        RoomsEffects,
        OperationPeriodsEffects,
        IntervalsEffects,
        TaxesFeesEffects,
        ProductsServicesEffects,
        ReservationsEffects,
        BookingSourcesEffects,
        BookingsourcessubcategoriesEffects
      ]
    ),
    StoreRouterConnectingModule.forRoot({ stateKey: 'router' }),
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    LayoutModule
  ],
  declarations: [
    AppComponent,
    PagesComponent,
    // AuthPagesComponent,
    NotFoundComponent,
    ComingSoonComponent,
    TopMenuComponent,
    MenuComponent,
    SidenavMenuComponent,
    BreadcrumbComponent,
    // OptionsComponent,
    // FooterComponent,
    InvalidlogindialogComponent
  ],
  providers: [
    AppSettings,
    SupportService,
    FileUploadService,
    DialogService,
    RoomsService,
    InventoryService,
    ReservationsService,
    CompanyService,
    PaymentService,
    AppService,
    {
      provide: OverlayContainer,
      useClass: CustomOverlayContainer
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptor,
      multi: true
    },
    {
      provide: RouterStateSerializer,
      useClass: CustomSerializer
    }
  ],
  entryComponents: [
    InvalidlogindialogComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
