import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CurrenciesState } from '../reducers/currencies.reducer';
import * as fromCurrencies from '../reducers/currencies.reducer';

export const selectCurrenciesState = createFeatureSelector<CurrenciesState>('currencies');


export const selectCurrencyById = (currencyId: string) => createSelector(
  selectCurrenciesState,
  currenciesState => currenciesState.entities[currencyId]
);


export const selectAllCurrencies = createSelector(
  selectCurrenciesState,
  fromCurrencies.selectAll
);

export const allCurrenciesLoaded = createSelector(
  selectCurrenciesState,
  currenciesState => currenciesState.allCurrenciesLoaded
);


