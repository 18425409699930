import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { Store, select } from '@ngrx/store';
import { AppState } from '../../../common/store/reducers';
import { isLoggedIn, isLoggedOut } from '../../../common/store/selectors/auth.selectors';
import { Company } from '../../../common/data_model/Company';
import { selectCompany } from '../../../common/store/selectors/company.selectors';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})


export class FooterComponent implements OnInit {

  public company$: Observable<Company>;
  public isLoggedIn$: Observable<boolean>;
  public isLoggedOut$: Observable<boolean>;
  public zoom = 18;

  constructor(private store: Store<AppState>) {

  }

  ngOnInit() {
    this.company$ = this.store.pipe(
      select(selectCompany)
    );

    this.isLoggedIn$ = this.store.pipe(
      select(isLoggedIn)
    );

    this.isLoggedOut$ = this.store.pipe(
      select(isLoggedOut)
    );
  }

  subscribe() { }

}
