import { Injectable } from '@angular/core';

import { withLatestFrom, mergeMap, map, filter } from 'rxjs/operators';

import {select, Store} from '@ngrx/store';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { AppState } from '../reducers';
import { BedTypesActionTypes, AllBedTypesRequested, AllBedTypesLoaded } from '../actions/bedtypes.actions';
import { RoomsService } from '../../services/rooms.service';

import { allBedTypesLoaded } from '../selectors/bedtypes.selectors';


@Injectable()
export class BedTypesEffects {

  @Effect()
  loadAllBedTypes$ = this.actions$
    .pipe(
        ofType<AllBedTypesRequested>(BedTypesActionTypes.AllBedTypesRequested),
        withLatestFrom(this.store.pipe(select(allBedTypesLoaded))),
        filter(([action, amenitiesLoaded]) => !amenitiesLoaded),
        mergeMap(action => this.roomsService.getAllBedTypes()),
        map(bedtypes => new AllBedTypesLoaded({bedtypes}))
    );

  constructor(private actions$: Actions,
              private store: Store<AppState>,
              private roomsService: RoomsService) {}

}
