import { Action } from '@ngrx/store';
import { Language } from '../../data_model/Language';



export enum LanguagesActionTypes {
  AllLanguagesRequested = '[Setup] All Languages Requested',
  AllLanguagesLoaded = '[Language API] All Languages Loaded',
  CurrentLanguageSet = '[TopMenu] Current UI Language Set',
}


export class AllLanguagesRequested implements Action {

  readonly type = LanguagesActionTypes.AllLanguagesRequested;

}


export class AllLanguagesLoaded implements Action {

  readonly type = LanguagesActionTypes.AllLanguagesLoaded;

  constructor(public payload: { languages: Language[] }) {

  }

}

export class CurrentLanguageSet implements Action {

  readonly type = LanguagesActionTypes.CurrentLanguageSet;

  constructor(public payload: { langCode: string }) {

  }

}


export type  LanguagesActions =
  AllLanguagesRequested |
  AllLanguagesLoaded |
  CurrentLanguageSet ;

