import { CurrenciesState, currenciesReducer } from './currencies.reducer';
import {
  ActionReducerMap,
  MetaReducer
} from '@ngrx/store';
import { environment } from '../../../../environments/environment';
import { storeFreeze } from 'ngrx-store-freeze';
import { routerReducer, RouterReducerState} from '@ngrx/router-store';
import { RouterStateUrl } from '../utils';
import { authReducer, AuthState } from './auth.reducer';
import { UsersState, usersReducer } from './users.reducer';
import { RolesState, rolesReducer } from './roles.reducer';
import { companyReducer, CompanyState } from './company.reducer';
import { roomsReducer, RoomsState } from './rooms.reducer';
import { amenitiesReducer, AmenitiesState } from './amenities.reducer';
import { bedtypesReducer, BedTypesState } from './bedtypes.reducer';
import { PropertyTypesState, propertytypesReducer } from './propertytypes.reducer';
import { RoomTypesState, roomtypesReducer } from './roomtypes.reducer';
import { LanguagesState, languagesReducer } from './languages.reducer';
import { IdsState, idsReducer } from './ids.reducer';
import { HotelRoomTypesState, HotelRoomTypesReducer } from './hotelroomtypes.reducer';
import { CountriesState, countriesReducer } from './countries.reducer';
import { operationPeriodsReducer, OperationPeriodsState } from './operationperiods.reducer';
import { intervalsReducer, IntervalsState } from './intervals.reducer';
import { taxesFeesReducer, TaxesFeesState } from './taxesfees.reducer';
import { productsServicesReducer, ProductsServicesState } from './productsservices.reducer';
import { AvailabilitySearchState, availabilitySearchReducer } from './availabilitysearch.reducer';
import {reservationsReducer, ReservationsState} from './reservations.reducer';
import { bookingSourcesReducer, BookingSourcesState } from './bookingsources.reducer';
import {bookingSourcesSubCategoriesReducer, BookingSourcesSubCategoriesState} from './bookingsourcessubcategories.reducer';



export interface AppState {
  languages: LanguagesState;
  ids: IdsState;
  currencies: CurrenciesState;
  countries: CountriesState;
  auth: AuthState;
  roles: RolesState;
  users: UsersState;
  company: CompanyState;
  rooms: RoomsState;
  hotelroomtypes: HotelRoomTypesState;
  amenities: AmenitiesState;
  bedtypes: BedTypesState;
  propertytypes: PropertyTypesState;
  roomtypes: RoomTypesState;
  operationPeriods: OperationPeriodsState;
  intervals: IntervalsState;
  taxesFees: TaxesFeesState;
  productsServices: ProductsServicesState;
  availabilitySearch: AvailabilitySearchState;
  reservations: ReservationsState;
  bookingsources: BookingSourcesState;
  bookingsourcessubcategories: BookingSourcesSubCategoriesState;
  router: RouterReducerState<RouterStateUrl>;
}

export const reducers: ActionReducerMap<AppState> = {
  languages: languagesReducer,
  ids: idsReducer,
  currencies: currenciesReducer,
  countries: countriesReducer,
  auth: authReducer,
  users: usersReducer,
  roles: rolesReducer,
  company: companyReducer,
  rooms: roomsReducer,
  hotelroomtypes: HotelRoomTypesReducer,
  amenities: amenitiesReducer,
  bedtypes: bedtypesReducer,
  propertytypes: propertytypesReducer,
  roomtypes: roomtypesReducer,
  operationPeriods: operationPeriodsReducer,
  intervals: intervalsReducer,
  taxesFees: taxesFeesReducer,
  productsServices: productsServicesReducer,
  availabilitySearch: availabilitySearchReducer,
  reservations: reservationsReducer,
  bookingsources: bookingSourcesReducer,
  bookingsourcessubcategories: bookingSourcesSubCategoriesReducer,
  router: routerReducer
};


export const metaReducers: MetaReducer<AppState>[] =
  !environment.production ? [storeFreeze] : [];
