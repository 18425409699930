import { Component, OnInit, OnDestroy, HostListener, ViewChild, AfterViewInit } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';

import { Observable, Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';
import { AppState } from '../common/store/reducers';
import { Settings, AppSettings } from '../app.settings';
import { isLoggedIn, isLoggedOut, loggedInUser} from '../common/store/selectors/auth.selectors';
import { selectCompany, selectHasWebSiteAndIsSetup } from '../common/store/selectors/company.selectors';

import { Company, AccountInfo } from '../common/data_model/Company';
import { SidenavMenuService } from '../theme/components/sidenav-menu/sidenav-menu.service';
import { User } from '../common/data_model/User';



@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
  providers: [ SidenavMenuService ]
})

export class PagesComponent implements OnInit, AfterViewInit, OnDestroy {
  public isLoggedIn$: Observable<boolean>;
  public isLoggedOut$: Observable<boolean>;
  public company$: Observable<Company>;
  public hasPublicWebsiteAndIsSetup$: Observable<boolean>;
  private modeSubscription: Subscription;
  private isLoggedInPortal = false;
  private isSystemAdmin = false;
  private hasPublicWebsite = false;
  public menuColors: Array<string>;

  public showBackToTop = false;
  public sidenavMenuItems: Array<any>;
  @ViewChild('sidenav', { static: true }) sidenav: any;

  public settings: Settings;

  private redirectToUrl = '/comingsoon';

  constructor(
    private store: Store<AppState>,
    public appSettings: AppSettings,
    public sidenavMenuService: SidenavMenuService,
    public router: Router
  ) {
    this.settings = this.appSettings.settings;

  }

  ngOnInit() {
    this.isLoggedIn$ = this.store.pipe(
      select(isLoggedIn)
    );

    this.isLoggedOut$ = this.store.pipe(
      select(isLoggedOut)
    );

    this.company$ = this.store.pipe(
      select(selectCompany)
    );

    this.hasPublicWebsiteAndIsSetup$ = this.store.pipe(
      select(selectHasWebSiteAndIsSetup)
    );

    this.modeSubscription = this.store
      .pipe(
        select(isLoggedIn)
      )
      .subscribe( portalMode => {
        if (portalMode) {
          this.menuColors = ['#F8F8F8', '#1e1b1d'];
        } else {
          this.menuColors = ['$primary', 'mat-color($primary, 100)'];
        }
        this.isLoggedInPortal = portalMode;
        this.sidenavMenuItems = this.sidenavMenuService.getSidenavMenuItems(this.isLoggedInPortal);
      });
  }

  public getMenubarBgrnd(): string {
    return this.menuColors[0];
  }

  public getMenubarFgrnd(): string {
    return this.menuColors[1];
  }

  public changeTheme(theme) {
    this.settings.theme = theme;
  }

  public stopClickPropagate(event: any) {
    event.stopPropagation();
    event.preventDefault();
  }

  public search() {}


  public scrollToTop() {
    const scrollDuration = 200;
    const scrollStep = -window.pageYOffset  / (scrollDuration / 20);
    const scrollInterval = setInterval(() => {
      if (window.pageYOffset !== 0) {
         window.scrollBy(0, scrollStep);
      } else {
        clearInterval(scrollInterval);
      }
    }, 10);
    if (window.innerWidth <= 768) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      });
    }
  }


  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    ($event.target.documentElement.scrollTop > 300) ? this.showBackToTop = true : this.showBackToTop = false;
  }

  ngAfterViewInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.sidenav.close();
      }
    });
    this.sidenavMenuService.expandActiveSubMenu(this.sidenavMenuService.getSidenavMenuItems(this.isLoggedInPortal));
  }

  public closeSubMenus() {
    if (window.innerWidth < 960) {
      this.sidenavMenuService.closeAllSubMenus();
    }
  }

  private navigateToAppropriateUrl() {
    this.router.navigate([this.redirectToUrl]);
  }

  ngOnDestroy() {
    if (this.modeSubscription) {
      this.modeSubscription.unsubscribe();
    }
  }

}
