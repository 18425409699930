import { Component, OnInit} from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { Store, select } from '@ngrx/store';
import { AppState } from '../../../common/store/reducers';
import { isLoggedIn, isLoggedOut } from '../../../common/store/selectors/auth.selectors';
import { selectCompanyAccountInfo } from '../../../common/store/selectors/company.selectors';
import { AccountInfo } from '../../../common/data_model/Company';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  public accountInfo$: Observable<AccountInfo>;
  public isLoggedIn$: Observable<boolean>;
  public isLoggedOut$: Observable<boolean>;

  constructor(private store: Store<AppState>) { }

  ngOnInit() {
    this.accountInfo$ = this.store.pipe(
      select(selectCompanyAccountInfo)
    );

    this.isLoggedOut$ = this.store.pipe(
      select(isLoggedOut)
    );

    this.isLoggedIn$ = this.store
      .pipe(
        select(isLoggedIn)
      );
  }

  openMegaMenu() {
    const pane = document.getElementsByClassName('cdk-overlay-pane');
    [].forEach.call(pane, function (el) {
        if (el.children.length > 0) {
          if (el.children[0].classList.contains('mega-menu')) {
            el.classList.add('mega-menu-pane');
          }
        }
    });
  }

}
