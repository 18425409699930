import { Routes, RouterModule, PreloadAllModules  } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { ComingSoonComponent } from './pages/coming-soon/coming-soon.component';
import { AuthGuard, SystemAdminGuard, PublicWebSiteGuard, DummyTrueGuard } from './common/store/guards/guards';
// import { AuthPagesComponent } from './authpages/authpages.component';

export const routes: Routes = [
    {
        path: '',
        component: PagesComponent,
        children: [
            {
              path: '',
              loadChildren: 'app/pages/home/home.module#HomeModule'
            },
            {
              path: 'about',
              loadChildren: 'app/pages/about/about.module#AboutModule',
              data: {
                breadcrumb: 'About Us'
              }
            },
            {
              path: 'rooms',
              canActivate: [DummyTrueGuard],
              runGuardsAndResolvers: 'always',
              loadChildren: 'app/pages/rooms/rooms.module#RoomsModule',
              data: {
                breadcrumb: 'All Rooms'
              },
            },
            {
              path: 'eatndrink',
              loadChildren: 'app/pages/eatndrink/eatndrink.module#EatnDrinkModule',
              data: {
                breadcrumb: 'About Us'
              }
            },
            {
              path: 'contact',
              loadChildren: 'app/pages/contact/contact.module#ContactModule',
              data: {
                breadcrumb: 'Contact'
              }
            },
            {
              path: 'sign-in',
              loadChildren: 'app/pages/sign-in/sign-in.module#SignInModule',
              data: {
                breadcrumb: 'Sign In '
              }
            },
            {
              path: 'account',
              loadChildren: 'app/pages/account/account.module#AccountModule',
              data: {
                breadcrumb: 'Account Settings'
              }
            },
            {
              path: 'dashboard',
              // redirectTo: 'auth/dashboard',
              canActivate: [AuthGuard],
              loadChildren: 'app/pages/auth/dashboard/dashboard.module#DashboardModule',
              data: {
                breadcrumb: 'HOTAC - Dashboard'
              }
            },
            {
              path: 'calendar',
              // redirectTo: 'auth/dashboard',
              canActivate: [AuthGuard],
              loadChildren: 'app/pages/auth/calendar/calendar.module#CalendarModule',
              data: {
                breadcrumb: 'HOTAC - Calendar'
              }
            },
            {
              path: 'reservations',
              canActivate: [AuthGuard],
              loadChildren: 'app/pages/auth/reservations/reservations.module#ReservationsModule',
              data: {
                breadcrumb: 'HOTAC - Reservations'
              }
            },
            {
              path: 'coreinfo',
              // redirectTo: 'auth/dashboard',
              canActivate: [AuthGuard],
              loadChildren: 'app/pages/auth/setup/coreinfo/coreinfo.module#CoreinfoModule',
              data: {
                breadcrumb: 'HOTAC - Setup - Core Info'
              }
            },
            {
              path: 'sysadmin',
              // redirectTo: 'auth/dashboard',
              canActivate: [AuthGuard],
              // canActivateChild: [SystemAdminGuard],
              loadChildren: 'app/pages/auth/sysadmin/sysadmin.module#SysAdminModule',
              data: {
                breadcrumb: 'HOTAC - WAD Admin'
              }
            },
            {
              path: 'ratesnavailability',
              canActivate: [AuthGuard],
              loadChildren: 'app/pages/auth/ratesnavailability/ratesnavailability.module#RatesNAvailabilityModule',
              data: {
                breadcrumb: 'HOTAC - Rates And Availability'
              }
            },
            {
              path: 'comingsoon',
              component: ComingSoonComponent
            },
        ]
    },
    {
      path: '**',
      component: NotFoundComponent
    }
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, {
  onSameUrlNavigation: 'reload',
  preloadingStrategy: PreloadAllModules,  // <- comment this line for activate lazy load
   // useHash: true
});
