import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, tap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { defer } from 'rxjs/observable/defer';

import { Actions, Effect, ofType } from '@ngrx/effects';

import { AuthActionTypes, Login, Logout } from '../actions/auth.actions';

import { getCircularReplacer } from '../utils';
import { CompanyActionTypes, SetCompanyAction } from '../actions/company.actions';
import { mergeMap } from 'rxjs-compat/operator/mergeMap';
import { AppState } from '../reducers';
import { select, Store } from '@ngrx/store';
import { CompanyService } from '../../services/company.service';
import { AllRoomsRequested } from '../actions/rooms.actions';
import { combineLatest } from 'rxjs';
import {AllRoomTypesRequested} from '../actions/roomtypes.actions';



@Injectable()
export class CompanyEffects {

  @Effect()
  setCompany$ = this.actions$
    .pipe(
      ofType<SetCompanyAction>(CompanyActionTypes.SetCompanyAction),
      map(() => new AllRoomTypesRequested())
    );

  constructor(private actions$: Actions,
    private router: Router,
    private store: Store<AppState>,
    private companyService: CompanyService) { }

}

