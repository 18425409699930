import { Action } from '@ngrx/store';
import { Id } from '../../data_model/Id';




export enum IdsActionTypes {
  IdsRequested = '[Setup] Ids Collection Requested',
  IdsLoaded = '[App API] Ids Collection Loaded',
  SetNewCompanyId = '[SysAdmin Company Clone] Set New Company Id'
}

export class IdsRequested implements Action {

  readonly type = IdsActionTypes.IdsRequested;

  constructor(public payload: { idId: string }) {

  }

}


export class IdsLoaded implements Action {

  readonly type = IdsActionTypes.IdsLoaded;

  constructor(public payload: { ids: Id }) {

  }

}

export class SetNewCompanyId implements Action {

  readonly type = IdsActionTypes.SetNewCompanyId;

  constructor(public payload: { entId: string, newCompId: number }) {

  }

}


export type  IdsActions =
  IdsRequested |
  IdsLoaded |
  SetNewCompanyId ;

