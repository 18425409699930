import { createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import { PropertyType } from '../../data_model/PropertyType';
import { PropertyTypesActionTypes, PropertyTypesActions } from '../actions/propertytypes.actions';
import { AppState } from '.';


export interface PropertyTypesState extends EntityState<PropertyType> {
  allPropertyTypesLoaded: boolean;
}

function sortById(pt1: PropertyType, pt2: PropertyType) {
  return +pt1.id - +pt2.id;
}

export const propertytypesAdapter: EntityAdapter<PropertyType> = createEntityAdapter<PropertyType>({
  sortComparer: sortById
});

export const initialPropertyTypesState: PropertyTypesState = propertytypesAdapter.getInitialState({
  allPropertyTypesLoaded: false
});

export function propertytypesReducer( state = initialPropertyTypesState, action: PropertyTypesActions): PropertyTypesState {

    switch (action.type) {

      case PropertyTypesActionTypes.PropertyTypeLoaded:
        return propertytypesAdapter.addOne(action.payload.propertytype, state);

      case PropertyTypesActionTypes.AllPropertyTypesLoaded:
        return propertytypesAdapter.addAll(action.payload.propertytypes, {...state, allPropertyTypesLoaded: true});


      default:
        return state;
    }
}


export const {
  selectAll,
  selectIds,
  selectEntities,
  selectTotal
} = propertytypesAdapter.getSelectors();
