import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BookingSourcesState } from '../reducers/bookingsources.reducer';
import * as fromBookingSourcesSubCategories from '../reducers/bookingsourcessubcategories.reducer';
import {BookingSourcesSubCategoriesState} from '../reducers/bookingsourcessubcategories.reducer';
import {selectBookingSourcesState} from './bookingsources.selectors';

// eslint-disable-next-line max-len
export const selectBookingSourcesSubCategoriesState = createFeatureSelector<BookingSourcesSubCategoriesState>('bookingsourcessubcategories');


export const selectAllBookingSourcesSubCategories = createSelector(
  selectBookingSourcesSubCategoriesState,
  fromBookingSourcesSubCategories.selectAll
);

export const allBookingSourcesSubCategoriesLoaded = createSelector(
  selectBookingSourcesState,
  bookingSourcesSubCategoriesState => bookingSourcesSubCategoriesState.allBookingSourcesLoaded
);


