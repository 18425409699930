import { Injectable } from '@angular/core';

import { withLatestFrom, mergeMap, map, filter } from 'rxjs/operators';

import {select, Store} from '@ngrx/store';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { AppState } from '../reducers';

import {
  ReservationRequested,
  ReservationLoaded,
  ReservationsActionTypes,
  ReservationsLoaded,
  ReservationsRequested, ReservationsForPeriodRequested, ReservationsForPeriodLoaded
} from '../actions/reservations.actions';

import { ReservationsService } from '../../services/reservations.service';


@Injectable()
export class ReservationsEffects {

  @Effect()
  loadAllReservations$ = this.actions$
    .pipe(
        ofType<ReservationsRequested>(ReservationsActionTypes.ReservationsRequested),
        // eslint-disable-next-line max-len
        mergeMap(actions => this.reservationsService.getAllReservationsForCompany(actions.payload.companyId, actions.payload.filterCriteria)),
        map(reservations => new ReservationsLoaded({ reservations }))
    );

  loadAllReservationsForPeriod$ = this.actions$
    .pipe(
      ofType<ReservationsForPeriodRequested>(ReservationsActionTypes.ReservationsForPeriodRequested),
      // eslint-disable-next-line max-len
      mergeMap(actions => this.reservationsService.getAllReservationsForPeriodForCompany(actions.payload.companyId, actions.payload.fromDate, actions.payload.toDate)),
      map(reservations => new ReservationsForPeriodLoaded({ reservations }))
    );


  @Effect()
  loadReservation$ = this.actions$
    .pipe(
      ofType<ReservationRequested>(ReservationsActionTypes.ReservationRequested),
      mergeMap(action => this.reservationsService.getReservationByIdForCompany(action.payload.companyId, action.payload.reservationId)),
      map(reservation => new ReservationLoaded({ reservation }))
    );

  constructor(private actions$: Actions,
              private store: Store<AppState>,
              private reservationsService: ReservationsService) {}

}
