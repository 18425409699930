import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import * as firebase from 'firebase';
import 'firebase/firestore';
import { LanguageService } from '../common/services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { Company } from '../common/data_model/Company';


@Injectable()
export class AuthService {

  authState: any = null;
  userRef: AngularFirestoreDocument<any>;
  isAuthenticated = false;
  UILanguages: any[] = [];

  constructor(private afAuth: AngularFireAuth,
              private afs: AngularFirestore,
              private languageService: LanguageService,
              private translate: TranslateService,
              private router: Router) {

    this.afAuth.authState.subscribe((auth) => {
      this.authState = auth;
    });

    this.languageService.getAllLanguages()
      .subscribe(lang => {
        this.UILanguages = lang;
      });
  }

  getLanguageRefByLanguageCode (languageCode: string): any {
    let langRef;
    this.UILanguages.forEach((language, index) => {
      if (language.Id === languageCode) {
        langRef = this.afs.doc('Languages/' + language.id).ref;
      }
    });
    return langRef;
  }

  // Returns true if user is logged in
  get authenticated(): boolean {
    return this.authState !== null;
  }

  // Returns current user data
  get currentUser(): any {
    return this.authenticated ? this.authState : null;
  }

  // Returns
  get currentUserObservable(): any {
    return this.afAuth.authState;
  }

  // Returns current user UID
  get currentUserId(): string {
    return this.authenticated ? this.authState.uid : '';
  }

  // Anonymous User
  get currentUserAnonymous(): boolean {
    return this.authenticated ? this.authState.isAnonymous : false;
  }

  // Returns current user display name or Guest
  get currentUserDisplayName(): string {
    if (!this.authState) {
      return 'Guest';
    } else if (this.currentUserAnonymous) {
      return 'Anonymous';
    } else {
      return this.authState['displayName'] || 'Unknown';
    }
  }


  // Returns current user photoUrl
  get currentUserPhotoUrl(): string {
    if (!this.authState) {
      // eslint-disable-next-line max-len
      return 'https://firebasestorage.googleapis.com/v0/b/medighborhood.appspot.com/o/Users%2FAvatars%2Fuseravatar.png?alt=media&token=1317fcce-b662-4134-81e7-a258deff6846';
    } else if (this.authState['photoURL'] === null) {
        // eslint-disable-next-line max-len
        return 'https://firebasestorage.googleapis.com/v0/b/medighborhood.appspot.com/o/Users%2FAvatars%2Fuseravatar.png?alt=media&token=1317fcce-b662-4134-81e7-a258deff6846';
    } else {
        return this.authState['photoURL'];
    }
  }

  //// Social Auth ////
  githubLogin() {
    const provider = new firebase.auth.GithubAuthProvider();
    return this.socialSignIn(provider);
  }

  googleLogin() {
    const provider = new firebase.auth.GoogleAuthProvider();
    return this.socialSignIn(provider);
  }

  facebookLogin() {
    const provider = new firebase.auth.FacebookAuthProvider();
    return this.socialSignIn(provider);
  }

  twitterLogin() {
    const provider = new firebase.auth.TwitterAuthProvider();
    return this.socialSignIn(provider);
  }

  private socialSignIn(provider) {
    return this.afAuth.auth.signInWithPopup(provider)
      .then((credential) => {
        this.authState = credential.user;
        // this.updateUserData();
      })
      .catch(error => console.log(error));
  }


  //// Anonymous Auth ////
  anonymousLogin() {
    return this.afAuth.auth.signInAnonymously()
      .then((user) => {
        this.authState = user;
      })
      .catch(error => console.log(error));
  }

  //// Email/Password Auth ////
  emailSignUp(email: string, password: string, companyId: string) {
    return this.afAuth.auth.createUserWithEmailAndPassword(email, password)
      .then((user) => {
        this.authState = user;
        this.createUserData(this.authState.user.uid, email, password, companyId);
      });
  }

  emailLogin(email: string, password: string) {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password)
      .then((user) => {
        this.authState = user;
        // this.updateUserData();
      })
      .catch(error => {
        console.log(error);
        throw error;
      });
  }

  // Sends email allowing user to reset password
  resetPassword(email: string) {
    const fbAuth = firebase.auth();

    return fbAuth.sendPasswordResetEmail(email)
      .then(() => console.log('email sent'))
      .catch((error) => console.log(error));
  }


  //// Sign Out ////
  public signOut(): void {
    this.afAuth.auth.signOut();
  }


  //// Helpers ////

  private createUserData(userId: string, email: string, password: string, companyId: string) {
    // Creates user in firestore

    return this.afs.collection('/Users').doc(userId).set({
      Active: true,
      Display_name: '',
      First_Name: '',
      Language: this.getLanguageRefByLanguageCode(this.translate.currentLang),
      Last_Name: '',
      Password: password,
      Role: this.afs.doc('/Roles/safU2seEfCbEGxVUir8B').ref,
      CompanyAdmin: true,
      Company: this.afs.doc('Companies/' + companyId).ref,
      Doctor: '',
      eMail: email,
      photoUrl: '',
      tagline: ''
    })
      .then(() => console.log('User created'))
      .catch(error => console.log(error));

  }

  public updateUserBasicData( dispName: string, photo: string, email: string, password: string ) {
    const fbUser = this.currentUser;
    fbUser.updateProfile({
      displayName: dispName,
      photoURL: photo
    });
    fbUser.updateEmail( email );
    fbUser.updatePassword( password );
  }

  private updateUserData(password: string, companyId: string): void {
    // Writes user name and email to realtime db
    // useful if your app displays information about users or for admin features
    const path = 'Users/' + this.currentUserId; // Endpoint on firestore
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(path);
    const companyRef: AngularFirestoreDocument<any> = this.afs.doc('Companies/' + companyId);

    const data = {
      email: this.authState.email,
      name: this.authState.displayName,
      password: password,
      Company: companyRef.ref
    };

    userRef.update(data)
      .catch(error => console.log(error));

  }
}
