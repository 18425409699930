import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppService } from '../../app.service';
import { Product } from '../../app.models';
import {Room} from '../../common/data_model/Room';

@Component({
  selector: 'app-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.scss']
})
export class ControlsComponent implements OnInit {
  @Input() room: Room;
  @Input() type: string;
  @Output() onOpenProductDialog: EventEmitter<any> = new EventEmitter();
  @Output() onQuantityChange: EventEmitter<any> = new EventEmitter<any>();
  public count = 1;
  public align = 'center center';
  constructor(public appService: AppService, public snackBar: MatSnackBar) { }

  ngOnInit() {
    if ( this.room) {
     // console.log(this.product);
    }
    this.layoutAlign();
  }

  public layoutAlign() {
    if (this.type === 'all') {
      this.align = 'space-between center';
    } else if (this.type === 'wish') {
      this.align = 'start center';
    } else {
      this.align = 'center center';
    }
  }



  public increment(count) {
    // if (this.count < this.room.availibilityCount){
    //   this.count++;
    // let obj = {
    //    productId: this.product.id,
    //    soldQuantity: this.count,
    //    total: this.count * this.product.newPrice
    //  }
    //  this.changeQuantity(obj);
    // }
    // else{
    //  this.snackBar.open('You can not choose more items than available. In stock ' + this.count + ' items.', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    // }
  }

  public decrement(count) {
  //  if (this.count > 1) {
  //    this.count--;
  //    const obj = {
  //      productId: this.product.id,
  //      soldQuantity: this.count,
  //      total: this.count * this.product.newPrice
  //    };
  //    this.changeQuantity(obj);
  //  }
  }


}
