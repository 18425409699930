import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { FirestoreService } from '../../core/firestore.service';

import * as _ from 'lodash';

import {Inventory, SearchAvailabilityCriteria} from '../data_model/Inventory';
import {AvailabilitySearchResults, CalendarReservation, GuestIndividual, Reservation, ReservationsFilters} from '../data_model/Reservation';
import {AppState} from '../store/reducers';
import {select, Store} from '@ngrx/store';
import {selectReservationById} from '../store/selectors/reservations.selectors';
import {take} from 'rxjs/operators';
import {ReservationSaved} from '../store/actions/reservations.actions';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';



@Injectable()
export class ReservationsService {

  private ratePlanInventory: Inventory;

  constructor(
    private _fsSrvc: FirestoreService,
    private db: AngularFirestore,
    private store: Store<AppState>,
    private fb: FormBuilder) {
  }

  public calcOvernights(fDate: Date, tDate: Date): number {
    // eslint-disable-next-line max-len
    return Math.floor((Date.UTC(tDate.getFullYear(), tDate.getMonth(), tDate.getDate()) - Date.UTC(fDate.getFullYear(), fDate.getMonth(), fDate.getDate())) / (1000 * 60 * 60 * 24));
  }

  public createReservationForm(res: Reservation): FormGroup {
    const resForm = this.fb.group({
      Dates: this.fb.group({
        Booked_date: [new Date()],
        CheckIn_date: [new Date()],
        CheckOut_date: [new Date()],
        Nights: [0],
        Preferred_Arrival_Time: [new Date()]
      }),
      Extras: this.fb.array([]),
      Occupancy: this.fb.group({
        Adults: [0],
        Children: [0],
        Infants: [0]
      }),
      Payments: this.fb.array([]),
      Price: this.fb.group({
        Accommodation: [0],
        Accommodation_taxes: this.fb.array([this.fb.group({
          Tax_Code: [''],
          Tax_Amount: [0],
          Tax_IncludedInPrice: [true]
        })]),
        Taxes_subtotal: [0],
        Accommodation_subtotal: [0],
        Extras: this.fb.array([this.fb.group({
          Extras_Code: [''],
          Extras_Amount: [0]
        })]),
        Extras_subtotals: [0],
        Total: [0]
      }),
      Primary_contact: this.fb.group({
        id: [''],
        Address: this.fb.group({
          City: [''],
          Country: ['', Validators.required],
          PC: [''],
          State: [''],
          Street1: [''],
          Street2: [''],
          lat: [0],
          long: [0]
        }),
        Contact: this.fb.group({
          Cell: ['', Validators.required],
          eMail: ['', [Validators.email, Validators.required]],
        }),
        DOB: [new Date()],
        Document: this.fb.group({
          Document_number: [''],
          Document_type: [''],
          Expiry_date: [new Date()],
          Issuing_country: ['']
        }),
        Title: [''],
        Name: ['', Validators.required],
        Surname: ['', Validators.required]
      }),
      Rooms: this.fb.array([]),
      Remarks_Guest: [''],
      Remarks_Hotel: [''],
      Source: this.fb.group({
        Category: ['', Validators.required],
        SubCategory: ['', Validators.required]
      }),
      Status: ['Reservation'],
      History: this.fb.array([this.fb.group({
        Action_Date: [''],
        Action: [''],
        Action_By: ['']
      })])
    });

    resForm.patchValue(res);

    const rooms = this.fb.array([]);

    res.Rooms.forEach(room => {
      console.log(room);
      (rooms as FormArray).push(this.fb.group({
        Adults: room.Adults,
        Children: room.Children,
        Infants: room.Infants,
        Guests: room.Guests,
        Room_type: room.Room_type,
        Rate_plan: room.Rate_plan,
        Room_number: room.Room_number,
        Occupied: room.Occupied,
        Price: room.Price
      }));
    });

    resForm.removeControl('Rooms');
    resForm.addControl('Rooms', rooms);

    const history = this.fb.array([]);
    res.History.forEach(historyEntry => {
      (history as FormArray).push(this.fb.group({
        Action_Date: historyEntry.Action_Date,
        Action: historyEntry.Action,
        Action_By: historyEntry.Action_By
      }));
    });

    resForm.removeControl('History');
    resForm.addControl('Hisatory', history);

    return resForm;
  }

  saveReservation(companyId: string, reservation: Reservation): Promise<any> {
    // Get new reservation Id and store the new Reservation
    let newReservationPath = 'Company/' + companyId + '/Reservations';
    const newResId = this.db.createId();
    reservation.id = newResId;
    newReservationPath = newReservationPath + '/' + newResId;
    const newRec = this.db.doc(newReservationPath).set(reservation);
    return newRec;
  }

  updateReservation(companyId: string, reservation: Reservation): Promise<any> {
    const reservationPath = 'Company/' + companyId + '/Reservations/' + reservation.id;
    return this._fsSrvc.update(reservationPath, reservation) ;
  }

  async updateReservationfromCalendarReservation(companyId: string, calendarReservation: CalendarReservation): Promise<any> {
    const reservationId = calendarReservation.id;
    const reservationPath = 'Company/' + companyId + '/Reservations/' + calendarReservation.id;
    let updatedReservation ;

    this.store
      .pipe(
        select(selectReservationById(reservationId)),
        take(1)
      )
      .subscribe(res => {
        updatedReservation = _.cloneDeep(res);
        updatedReservation.Dates.CheckIn_date = new Date(calendarReservation.start);
        updatedReservation.Dates.CheckOut_date = new Date(calendarReservation.end);
        updatedReservation.Dates.Nights =
          this.calcOvernights(new Date(calendarReservation.start.toString()), new Date(calendarReservation.end.toString()));
        updatedReservation.Rooms[calendarReservation.position].Room_number = calendarReservation.Room_number;

        this.updateReservation(companyId, updatedReservation)
          .then(() => {
            this.store.dispatch(new ReservationSaved({ reservation: updatedReservation }));
          });
      });

    return await this.dummyPromiseResolve();

  }

  dummyPromiseResolve(): Promise<any> {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve('');
      }, 1000);
    });
  }

  getAllReservationsForCompany(companyId: string, filterCriteria: ReservationsFilters): Observable<Reservation[]> {

    console.log(companyId);
    console.log(filterCriteria);
    return this._fsSrvc.colWithIds$<Reservation>('Company/' + companyId + '/Reservations', ref => {

      let qry = ref;

      if ( filterCriteria.date_booked !== null ) {
        qry = qry.where('Dates.Booked_date.setHours(0, 0, 0, 0)', '==', filterCriteria.date_booked);
      }

      if ( filterCriteria.date_booked !== null ) {
        qry = qry.where('Dates.Booked_date', '==', filterCriteria.date_booked);
      }

      if (filterCriteria.check_in_date !== null ) {
        qry = qry.where('Dates.CheckIn_date', '==', filterCriteria.check_in_date);
      }

      if ( filterCriteria.check_out_date !== null ) {
        qry = qry.where('Dates.CheckOut_date', '==', filterCriteria.check_out_date);
      }

      // TODO Implement logic for stay date

      if ( filterCriteria.status !== '') {
        qry = qry.where('Status', '==', filterCriteria.status);
      }

      if ( filterCriteria.room_type !== '') {
        qry = qry.where('Rooms.Room_type', 'array-contains', filterCriteria.room_type);
      }

      if ( filterCriteria.source.category !== '') {
        qry = qry.where('Source', '==', filterCriteria.source.category);
      }

      // TODO Implement logic for booking source sub-category

      return qry;
    });
  }

  getAllReservationsForPeriodForCompany(companyId: string, fromDate: Date, toDate: Date): Observable<Reservation[]> {
    return this._fsSrvc.colWithIds$<Reservation>('Company/' + companyId + '/Reservations', ref => ref
      .where('Dates.CheckIn_date', '>=', new Date(fromDate))
      .where('Dates.CheckIn.date', '<=', new Date(toDate))
    );
  }

  getReservationByIdForCompany(companyId: string, reservationId: string): Observable<Reservation> {
    return this._fsSrvc.doc$<Reservation>('Company/' + companyId + '/Reservations/' + reservationId);
  }

  getReservationBalanceDue(reservation: Reservation): number {
    const totalAmount = reservation.Price.Total;

    let paymentsSubtotal = 0;
    reservation.Payments.forEach(resPayment => {
      paymentsSubtotal += resPayment.Amount;
    });

    return totalAmount - paymentsSubtotal;
  }

}



