import { Injectable } from '@angular/core';

import { withLatestFrom, mergeMap, map, filter } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { AppState } from '../reducers';
import { RolesActionTypes, AllRolesRequested, AllRolesLoaded } from '../actions/roles.actions';
import { allRolesLoaded } from '../selectors/roles.selectors';
import { UsersService } from '../../services/users.service';




@Injectable()
export class RolesEffects {

  @Effect()
  loadAllRoles$ = this.actions$
    .pipe(
      ofType<AllRolesRequested>(RolesActionTypes.AllRolesRequested),
      withLatestFrom(this.store.pipe(select(allRolesLoaded))),
      filter(([action, RolesLoaded]) => !RolesLoaded),
      mergeMap(action => this.usersService.getAllRoles()),
      map(Roles => new AllRolesLoaded({ Roles }))
    );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private usersService: UsersService) { }

}
