import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BedTypesState } from '../reducers/bedtypes.reducer';
import * as fromBedTypes from '../reducers/bedtypes.reducer';

export const selectBedTypesState = createFeatureSelector<BedTypesState>('bedtypes');


export const selectBedTypeById = (bedtypeId: string) => createSelector(
  selectBedTypesState,
  bedtypesState => bedtypesState.entities[bedtypeId]
);


export const selectAllBedTypes = createSelector(
  selectBedTypesState,
  fromBedTypes.selectAll
);

export const allBedTypesLoaded = createSelector(
  selectBedTypesState,
  bedtypesState => bedtypesState.allBedTypesLoaded
);


