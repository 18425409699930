import { createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import { UsersActions, UsersActionTypes } from '../actions/users.actions';
import { User} from '../../data_model/User';


export interface UsersState extends EntityState<User> {
}

function sortById(r1: User, r2: User) {
  return r1.id.localeCompare(r2.id);
}

export const usersAdapter: EntityAdapter<User> = createEntityAdapter<User>({
  sortComparer: sortById
});

export const initialUsersState: UsersState = usersAdapter.getInitialState();

export function usersReducer( state = initialUsersState, action: UsersActions): UsersState {

  switch (action.type) {

    case UsersActionTypes.UserLoaded:
      return usersAdapter.addOne(action.payload.user, state);

    case UsersActionTypes.UsersLoaded:
      return usersAdapter.addAll(action.payload.users, state);

    case UsersActionTypes.UsersOfCompanyLoaded:
      return usersAdapter.addMany(action.payload.users, state);

    case UsersActionTypes.UserSaved:
      return usersAdapter.updateOne(action.payload.user, state);

    default:
      return state;
  }
}


export const {
  selectAll,
  selectIds,
  selectEntities,
  selectTotal
} = usersAdapter.getSelectors();
