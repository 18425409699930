import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RolesState } from '../reducers/roles.reducer';
import * as fromRoles from '../reducers/roles.reducer';

export const selectRolesState = createFeatureSelector<RolesState>('roles');


export const selectRoleyById = (roleId: string) => createSelector(
  selectRolesState,
  RlsState => RlsState.entities[roleId]
);


export const selectAllRoles = createSelector(
  selectRolesState,
  fromRoles.selectAll
);

export const allRolesLoaded = createSelector(
  selectRolesState,
  RlsState => RlsState.allRolesLoaded
);

