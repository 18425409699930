import { Action } from '@ngrx/store';
import { BedType } from '../../data_model/BedType';



export enum BedTypesActionTypes {
  BedTypeRequested = '[Setup] BedType Requested',
  BedTypeLoaded = '[Rooms API] BedType Loaded',
  AllBedTypesRequested = '[Setup] All BedTypes Requested',
  AllBedTypesLoaded = '[Rooms API] All BedTypes Loaded'
}

export class BedTypeRequested implements Action {

  readonly type = BedTypesActionTypes.BedTypeRequested;

  constructor(public payload: { bedtypeId: string }) {

  }

}


export class BedTypeLoaded implements Action {

  readonly type = BedTypesActionTypes.BedTypeLoaded;

  constructor(public payload: { bedtype: BedType }) {

  }

}


export class AllBedTypesRequested implements Action {

  readonly type = BedTypesActionTypes.AllBedTypesRequested;

}


export class AllBedTypesLoaded implements Action {

  readonly type = BedTypesActionTypes.AllBedTypesLoaded;

  constructor(public payload: { bedtypes: BedType[] }) {

  }

}


export type  BedTypesActions =
  BedTypeRequested |
  BedTypeLoaded |
  AllBedTypesRequested |
  AllBedTypesLoaded ;

