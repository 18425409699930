import { createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {IntervalsActions, IntervalsActionTypes} from '../actions/intervals.actions';

import { Interval } from '../../data_model/Inventory';

export interface IntervalsState extends EntityState<Interval> {
  allIntervalsForOpPeriodLoaded: boolean;
}

function sortByISOName(int1: Interval, int2: Interval) {
  return +int1.id.localeCompare(int2.id);
}

export const intervalsAdapter: EntityAdapter<Interval> = createEntityAdapter<Interval>({
  sortComparer: sortByISOName
});

export const initialIntervalsState: IntervalsState = intervalsAdapter.getInitialState({
  allIntervalsForOpPeriodLoaded: false
});

export function intervalsReducer( state = initialIntervalsState, action: IntervalsActions): IntervalsState {

    switch (action.type) {

      case IntervalsActionTypes.IntervalLoaded:
        return intervalsAdapter.addOne(action.payload.interval, state);

      case IntervalsActionTypes.AllIntervalsForOpPeriodLoaded:
        return intervalsAdapter.addAll(action.payload.intervals, {...state, allIntervalsForOpPeriodLoaded: true});


      default:
        return state;
    }
}


export const {
  selectAll,
  selectIds,
  selectEntities,
  selectTotal
} = intervalsAdapter.getSelectors();
