import { createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import { Id } from '../../data_model/Id';
import { IdsActionTypes, IdsActions } from '../actions/ids.actions';
import { IdsState } from './ids.reducer';




export interface IdsState extends EntityState<Id> {
}

function sortById(i1: Id, i2: Id) {
  return +i1.id - +i2.id;
}

export const idsAdapter: EntityAdapter<Id> = createEntityAdapter<Id>({
  sortComparer: sortById
});

export const initialIdsState: EntityState<Id> = idsAdapter.getInitialState();

export function idsReducer( state = initialIdsState, action: IdsActions): IdsState {

    switch (action.type) {

      case IdsActionTypes.IdsLoaded:
        return idsAdapter.addOne(action.payload.ids, state);

      case IdsActionTypes.SetNewCompanyId:
        return idsAdapter.updateOne({id: action.payload.entId, changes: {Company: action.payload.newCompId}}, state);

      default:
        return state;
    }
}


export const {
  selectAll,
  selectIds,
  selectEntities,
  selectTotal
} = idsAdapter.getSelectors();
