import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RoomsState } from '../reducers/rooms.reducer';
import { selectHotelId } from './company.selectors';
import * as fromRooms from '../reducers/rooms.reducer';

export const selectRoomsState = createFeatureSelector<RoomsState>('rooms');


export const selectRoomByName = (roomName: string) => createSelector(
  selectRoomsState,
  roomsState => roomsState.entities[roomName]
);

export const selectAllRooms = createSelector(
  selectRoomsState,
  fromRooms.selectAll
);

export const allRoomsLoaded = createSelector(
  selectRoomsState,
  roomsState => roomsState.allRoomsLoaded
);










