import { Injectable } from '@angular/core';

import { withLatestFrom, mergeMap, map, filter } from 'rxjs/operators';

import {select, Store} from '@ngrx/store';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { AppState } from '../reducers';
import { AmenitiesActionTypes, AllAmenitiesRequested, AllAmenitiesLoaded } from '../actions/amenities.actions';
import { RoomsService } from '../../services/rooms.service';

import { allAmenitiesLoaded } from '../selectors/amenities.selectors';


@Injectable()
export class AmenitiesEffects {

  @Effect()
  loadAllAmenities$ = this.actions$
    .pipe(
        ofType<AllAmenitiesRequested>(AmenitiesActionTypes.AllAmenitiesRequested),
        withLatestFrom(this.store.pipe(select(allAmenitiesLoaded))),
        filter(([action, amenitiesLoaded]) => !amenitiesLoaded),
        mergeMap(action => this.roomsService.getAllAmenities()),
        map(amenities => new AllAmenitiesLoaded({amenities}))
    );

  constructor(private actions$: Actions,
              private store: Store<AppState>,
              private roomsService: RoomsService) {}

}
