import { Action } from '@ngrx/store';
import { Company } from '../../data_model/Company';



export enum CompanyActionTypes {
  SetCompanyAction = '[App Init] Set Company Data'
}


export class SetCompanyAction implements Action {

  readonly type = CompanyActionTypes.SetCompanyAction;

  constructor(public payload: { company: Company }) {

  }

}

export type CompanyActions = SetCompanyAction;
