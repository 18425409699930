import { Pipe, PipeTransform } from '@angular/core';
import { FirestoreService } from '../../core/firestore.service';
import { Observable } from 'rxjs';

@Pipe({
  name: 'doc'
})
export class DocPipe implements PipeTransform {

  constructor(private _afsSrvc: FirestoreService) {}

  transform(value: any): Observable<any> {
    return this._afsSrvc.doc$(value);
  }

}
