import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { VgCoreModule } from 'videogular2/core';
import { VgControlsModule } from 'videogular2/controls';
import { VgOverlayPlayModule } from 'videogular2/overlay-play';
import { VgBufferingModule } from 'videogular2/buffering';

import { NgDragDropModule } from 'ng-drag-drop';

import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { BooleanToIconPipe } from '../pipes/boolean-to-icon.pipe';
import { TruncatePipe } from '../pipes/truncate.pipe';
import { FileSizePipe } from '../pipes/file-size.pipe';
import { DocPipe } from '../pipes/doc.pipe';
import { SpecialityDataPipe } from '../pipes/speciality-data.pipe';
import { TsToDatePipe } from '../pipes/ts-to-date.pipe';
import { FireFormDirective } from '../directives/fire-form.directive';
import { TimestampToDatePipe } from '../pipes/timestamp-to-date.pipe';
import { CompanyService } from '../services/company.service';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    NgDragDropModule.forRoot(),
    TranslateModule,
    HttpClientModule
  ],
  entryComponents: [

  ],
  declarations: [
    BooleanToIconPipe,
    TruncatePipe,
    FileSizePipe,
    DocPipe,
    SpecialityDataPipe,
    TsToDatePipe,
    FireFormDirective, TimestampToDatePipe,
  ],

  providers: [
    CompanyService
  ],

  exports: [
    TranslateModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    BooleanToIconPipe,
    TruncatePipe,
    FileSizePipe,
    DocPipe,
    SpecialityDataPipe,
    TsToDatePipe,
    FireFormDirective,
    TimestampToDatePipe
  ]
})
export class SharedUtilsModule {

}
