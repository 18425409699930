import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { OperationPeriod } from '../../data_model/Inventory';
import { OperationPeriodsActions, OperationPeriodsActionTypes } from '../actions/operationperiods.actions';
import {any} from 'codelyzer/util/function';


function timestampToDate(timestampObj: any): Date {
  return new Date(timestampObj.seconds * 1000 + timestampObj.nanoseconds / 1e6);
}

export interface OperationPeriodsState extends EntityState<OperationPeriod> {
  allOperationperiodsLoaded: boolean;
}

function sortByFromDate(op1: OperationPeriod, op2: OperationPeriod) {
  const d1 = timestampToDate(op1.From_date);
  const d2 = timestampToDate(op2.From_date);
  return Math.floor((Date.UTC(d1.getFullYear(), d1.getMonth(), d1.getDate()) - Date.UTC(d2.getFullYear(), d2.getMonth(), d2.getDate())) / (1000 * 60 * 60 * 24));
}

export const operationPeriodsAdapter: EntityAdapter<OperationPeriod> = createEntityAdapter<OperationPeriod>({
  sortComparer: sortByFromDate
});

export const initialOperationPeriodsState: OperationPeriodsState = operationPeriodsAdapter.getInitialState({
  allOperationperiodsLoaded: false
});

export function operationPeriodsReducer( state = initialOperationPeriodsState, action: OperationPeriodsActions): OperationPeriodsState {

    switch (action.type) {

      case OperationPeriodsActionTypes.OperationPeriodLoaded:
        return operationPeriodsAdapter.addOne(action.payload.operationPeriod, state);

      case OperationPeriodsActionTypes.AllOperationPeriodsLoaded:
        return operationPeriodsAdapter.addAll(action.payload.operationPeriods, {...state, allOperationperiodsLoaded: true});


      default:
        return state;
    }
}


export const {
  selectAll,
  selectIds,
  selectEntities,
  selectTotal
} = operationPeriodsAdapter.getSelectors();
