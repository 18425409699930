import { Action } from '@ngrx/store';
import { CompanyActionTypes, CompanyActions } from '../actions/company.actions';
import { Company, AboutUsDescr, AccountInfo, Address, Setup, EatNDrink, SystemSettings } from '../../data_model/Company';
import { Banner } from '../../data_model/Banner';
import { HotelFeatureImage } from '../../data_model/HotelFeatureImage';
import { Language } from '../../data_model/Language';


export interface CompanyState {
  About_us: Array<AboutUsDescr>;
  Account_Info: AccountInfo;
  Address: Address;
  Banner_Images: Array<Banner>;
  Eat_N_Drink: EatNDrink;
  Feature_images: Array<HotelFeatureImage>;
  Room_Feature_Image_url: string;
  Front_Page: string;
  Legal_Name: string;
  Logo_Url: string;
  Property_Type: string;
  Reg_Nbr: string;
  Setup: Setup;
  System_Settings: SystemSettings;
  Tax_Office: string;
  Telephone: string;
  Trade_Name: string;
  eMail: string;
  id: string;
  website_url: string;
}

// @ts-ignore
export const initialCompanyState: CompanyState = {
    id: '',
    Legal_Name: '',
    Trade_Name: '',
    Property_Type: '',
    Reg_Nbr: '',
    Setup: {
      AllSetup: false,
      FirstTimeEntered: false,
      step1: {
        system_profile: false,
        profile: false,
        accommodation_types: false,
        availability_matrix: false,
        other_products_services: false,
        policies: false,
        reservation_sources: false,
        taxes_fees: false
      },
      step2: {
        allotment: false,
        channels: false,
        mapping: false
      }
    },
    System_Settings: {
      Color_scheme: 'hotac',
      Currency: '\Currencies\EUR',
      UI_Languages: [{
        id: '1',
        code: 'en',
        name: 'English',
        flag: 'assets/images/flags/gb.svg',
        priority: 0
      }]
    },
    Front_Page: '',
    Tax_Office: '',
    Logo_Url: '',
    website_url: '',
    About_us: [
      {
        lang: 'en',
        text: ''
      }
    ],
    Eat_N_Drink: {
      Image_Url: '',
      Description: [
        {
          lang: 'en',
          text: ''
        }
      ],
      Menu: [
        {
          Dish_Image_Url: '',
          Dish_Name: [
            {
              lang: 'en',
              text: ''
            }
          ],
          Dish_Content: [
            {
              lang: 'en',
              text: ''
            }
          ],
          Dish_Price: 0
        }
      ]
    },
    Address: {
      Street1: '',
      Street2: '',
      City: '',
      State: '',
      PC: '',
      Country: '',
      lat: 0,
      long: 0
    },
    Telephone: '',
    eMail: '',
    Account_Info: {
      Account_Active: true,
      Account_set_up: true,
      Currency: '',
      Elorus_Contact_id: '',
      Has_Backoffice: false,
      Has_Public_Website: false,
      ProductId: '',
      Registration_Date: new Date(),
      Subscribed_Rooms_Nbr: 0,
      Price_Per_Room: 0,
      Renewal_Frequency_Days: 0
    },
    Banner_Images: [
      {
        image_url: '',
        subtitle: [
          {
            lang: 'en',
            text: ''
          }
        ],
        title: [
          {
            lang: 'en',
            text: ''
          }
        ],
      }
    ],
    Feature_images: [{
      image_url: '',
      subtitle: [{
        lang: 'en',
        text: ''
      }],
      title: [{
        lang: 'en',
        text: ''
      }],
  }],
    // eslint-disable-next-line max-len
    Room_Feature_Image_url: 'https://firebasestorage.googleapis.com/v0/b/hotac-1.appspot.com/o/WAD%2Froom_feature_image.jpg?alt=media&token=c3a666d4-cd9f-41e0-92eb-f411714b019a'
};

export function companyReducer(state = initialCompanyState, action: CompanyActions): CompanyState {
  switch (action.type) {

    case CompanyActionTypes.SetCompanyAction:
      return {
        About_us: action.payload.company.About_us,
        Account_Info: action.payload.company.Account_Info,
        Address: action.payload.company.Address,
        Banner_Images: action.payload.company.Banner_Images,
        Feature_images: action.payload.company.Feature_images,
        Eat_N_Drink: action.payload.company.Eat_N_Drink,
        Room_Feature_Image_url: action.payload.company.Room_Feature_Image_url,
        Front_Page: action.payload.company.Front_Page,
        Legal_Name: action.payload.company.Legal_Name,
        Logo_Url: action.payload.company.Logo_Url,
        Property_Type: action.payload.company.Property_Type,
        Reg_Nbr: action.payload.company.Reg_Nbr,
        Setup: action.payload.company.Setup,
        System_Settings: action.payload.company.System_Settings,
        Tax_Office: action.payload.company.Tax_Office,
        Telephone: action.payload.company.Telephone,
        Trade_Name: action.payload.company.Trade_Name,
        eMail: action.payload.company.eMail,
        id: action.payload.company.id,
        website_url: action.payload.company.website_url,
      };

    default:
      return state;
  }
}
