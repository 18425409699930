import { Injectable } from '@angular/core';

import { withLatestFrom, mergeMap, map, filter } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { AppState } from '../reducers';

import {
  HotelRoomTypeRequested,
  HotelRoomTypeLoaded,
  HotelRoomTypesActionTypes,
  HotelRoomTypesLoaded,
  HotelRoomTypesRequested
} from '../actions/hotelroomtypes.actions';

import { RoomsService } from '../../services/rooms.service';
import { allHotelRoomTypesLoaded } from '../selectors/hotelroomtypes.selectors';


@Injectable()
export class HotelRoomTypesEffects {

  @Effect()
  loadAllHotelRoomTypes$ = this.actions$
    .pipe(
      ofType<HotelRoomTypesRequested>(HotelRoomTypesActionTypes.HotelRoomTypesRequested),
      /* withLatestFrom(this.store.pipe(select(allHotelRoomTypesLoaded))),
      filter(([actn, allResLoaded]) => !allResLoaded), */
      mergeMap(actions => this.roomsService.getAllHotelRoomTypesForCompany(actions.payload.companyId)),
      map(hotelRoomTypes => new HotelRoomTypesLoaded({ hotelRoomTypes }))
    );


  @Effect()
  loadHotelRoomType$ = this.actions$
    .pipe(
      ofType<HotelRoomTypeRequested>(HotelRoomTypesActionTypes.HotelRoomTypeRequested),
      mergeMap(action => this.roomsService.getHotelRoomTypeByCode(action.payload.companyId, action.payload.hotelRoomTypeId)),
      map(hotelRoomType => new HotelRoomTypeLoaded({ hotelRoomType }))
    );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private roomsService: RoomsService) { }

}
