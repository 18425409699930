import { Action } from '@ngrx/store';
import { PropertyType } from '../../data_model/PropertyType';



export enum PropertyTypesActionTypes {
  PropertyTypeRequested = '[Setup] PropertyType Requested',
  PropertyTypeLoaded = '[Rooms API] PropertyType Loaded',
  AllPropertyTypesRequested = '[Setup] All PropertyTypes Requested',
  AllPropertyTypesLoaded = '[Rooms API] All PropertyTypes Loaded'
}

export class PropertyTypeRequested implements Action {

  readonly type = PropertyTypesActionTypes.PropertyTypeRequested;

  constructor(public payload: { propertytypeId: string }) {

  }

}


export class PropertyTypeLoaded implements Action {

  readonly type = PropertyTypesActionTypes.PropertyTypeLoaded;

  constructor(public payload: { propertytype: PropertyType }) {

  }

}


export class AllPropertyTypesRequested implements Action {

  readonly type = PropertyTypesActionTypes.AllPropertyTypesRequested;

}


export class AllPropertyTypesLoaded implements Action {

  readonly type = PropertyTypesActionTypes.AllPropertyTypesLoaded;

  constructor(public payload: { propertytypes: PropertyType[] }) {

  }

}


export type  PropertyTypesActions =
  PropertyTypeRequested |
  PropertyTypeLoaded |
  AllPropertyTypesRequested |
  AllPropertyTypesLoaded ;

