import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PropertyTypesState } from '../reducers/propertytypes.reducer';
import * as fromPropertyTypes from '../reducers/propertytypes.reducer';

export const selectPropertyTypesState = createFeatureSelector<PropertyTypesState>('propertytypes');


export const selectPropertyTypeById = (propertytypeId: string) => createSelector(
  selectPropertyTypesState,
  propertytypesState => propertytypesState.entities[propertytypeId]
);


export const selectAllPropertyTypes = createSelector(
  selectPropertyTypesState,
  fromPropertyTypes.selectAll
);

export const allPropertyTypesLoaded = createSelector(
  selectPropertyTypesState,
  propertytypesState => propertytypesState.allPropertyTypesLoaded
);


