import {Injectable, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { SidenavMenu } from './sidenav-menu.model';
import { sidenavMenuItemsPublic, sidenavMenuItemsPortal } from './sidenav-menu';

import { TranslateService } from '@ngx-translate/core';
import { _TRANSLATE } from '../../../common/services/translation-marker';
import {AppState} from '../../../common/store/reducers';
import {select, Store} from '@ngrx/store';
import {loggedInUserIsSystemAdmin} from '../../../common/store/selectors/auth.selectors';
import {take, tap} from 'rxjs/operators';
import { selectCompanyAccountInfo } from '../../../common/store/selectors/company.selectors';


@Injectable()
export class SidenavMenuService implements  OnInit {

  constructor(private location: Location,
              private store: Store<AppState>) { }

  public sidenavMenuItems: any;
  public sidenavMenuItemsTransl: any;
  private userSystemAdmin = false;
  private has_public_website = false;

  ngOnInit() {
    this.store.pipe(
      select(loggedInUserIsSystemAdmin),
      tap(isSystemAdin => this.userSystemAdmin = isSystemAdin),
      take(1)
    )
    .subscribe();

    this.store.pipe(
      select(selectCompanyAccountInfo),
      tap(accInfo => this.has_public_website = accInfo.Has_Public_Website),
      take(1)
    )
    .subscribe();
  }

    public getSidenavMenuItems(portalMenu: boolean): Array<SidenavMenu> {
      this.sidenavMenuItems = portalMenu ? sidenavMenuItemsPortal : sidenavMenuItemsPublic;
      if (!portalMenu && !this.has_public_website) {
        // this.sidenavMenuItems.splice(0, this.sidenavMenuItems.length - 1);
      }
      if (portalMenu && this.userSystemAdmin) {
        this.sidenavMenuItems.splice(0, 0, new SidenavMenu(1, 'Menu.Admin.Title', '/sysadmin', null, null, false, 0));
      }
      this.sidenavMenuItemsTransl = [];
      this.sidenavMenuItems.forEach(menuItem => {
        this.sidenavMenuItemsTransl.push({
          id: menuItem.id,
          title: _TRANSLATE(menuItem.title),
          routerLink: menuItem.routerLink,
          href: menuItem.href,
          target: menuItem.target,
          hasSubMenu: menuItem.hasSubMenu,
          parentId: menuItem.parentId
        });

      });
      return this.sidenavMenuItemsTransl;
    }


    public expandActiveSubMenu(menu: Array<SidenavMenu>) {
        const url = this.location.path();
        const routerLink = decodeURIComponent(url);
        const activeMenuItem = menu.filter(item => item.routerLink === routerLink);
        if (activeMenuItem[0]) {
            let menuItem = activeMenuItem[0];
            while (menuItem.parentId !== 0) {
                const parentMenuItem = menu.filter(item => item.id === menuItem.parentId)[0];
                menuItem = parentMenuItem;
                this.toggleMenuItem(menuItem.id);
            }
        }
    }

    public toggleMenuItem(menuId) {
        const menuItem = document.getElementById('menu-item-' + menuId);
        const subMenu = document.getElementById('sub-menu-' + menuId);
        if (subMenu) {
            if (subMenu.classList.contains('show')) {
                subMenu.classList.remove('show');
                menuItem.classList.remove('expanded');
            } else {
                subMenu.classList.add('show');
                menuItem.classList.add('expanded');
            }
        }
    }

    public closeOtherSubMenus(menu: Array<SidenavMenu>, menuId) {
        const currentMenuItem = menu.filter(item => item.id === menuId)[0];
        menu.forEach(item => {
            // eslint-disable-next-line max-len
            if ((item.id !== menuId && item.parentId === currentMenuItem.parentId) || (currentMenuItem.parentId == 0 && item.id != menuId) ) {
                const subMenu = document.getElementById('sub-menu-' + item.id);
                const menuItem = document.getElementById('menu-item-' + item.id);
                if (subMenu) {
                    if (subMenu.classList.contains('show')) {
                        subMenu.classList.remove('show');
                        menuItem.classList.remove('expanded');
                    }
                }
            }
        });
    }

    public closeAllSubMenus() {
      this.sidenavMenuItems.forEach(item => {
            const subMenu = document.getElementById('sub-menu-' + item.id);
            const menuItem = document.getElementById('menu-item-' + item.id);
            if (subMenu) {
                if (subMenu.classList.contains('show')) {
                    subMenu.classList.remove('show');
                    menuItem.classList.remove('expanded');
                }
            }
        });
    }

}
