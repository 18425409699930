import { createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import { Currency } from '../../data_model/Currency';
import { CurrenciesActionTypes, CurrenciesActions } from '../actions/currencies.actions';
import { AppState } from '.';


export interface CurrenciesState extends EntityState<Currency> {
  allCurrenciesLoaded: boolean;
}

function sortByCode(c1: Currency, c2: Currency) {
  return +c1.Code - +c2.Code;
}

export const currenciesAdapter: EntityAdapter<Currency> = createEntityAdapter<Currency>({
  sortComparer: sortByCode
});

export const initialCurrenciesState: CurrenciesState = currenciesAdapter.getInitialState({
  allCurrenciesLoaded: false
});

export function currenciesReducer( state = initialCurrenciesState, action: CurrenciesActions): CurrenciesState {

    switch (action.type) {

      case CurrenciesActionTypes.CurrencyLoaded:
        return currenciesAdapter.addOne(action.payload.currency, state);

      case CurrenciesActionTypes.AllCurrenciesLoaded:
        return currenciesAdapter.addAll(action.payload.currencies, {...state, allCurrenciesLoaded: true});


      default:
        return state;
    }
}


export const {
  selectAll,
  selectIds,
  selectEntities,
  selectTotal
} = currenciesAdapter.getSelectors();
