import { Action } from '@ngrx/store';
import { Country } from '../../data_model/Country';



export enum CountriesActionTypes {
  CountryRequested = '[Setup] Country Requested',
  CountryLoaded = '[Rooms API] Country Loaded',
  AllCountriesRequested = '[Setup] All Countries Requested',
  AllCountriesLoaded = '[Rooms API] All Countries Loaded'
}

export class CountryRequested implements Action {

  readonly type = CountriesActionTypes.CountryRequested;

  constructor(public payload: { countryId: string }) {

  }

}


export class CountryLoaded implements Action {

  readonly type = CountriesActionTypes.CountryLoaded;

  constructor(public payload: { country: Country }) {

  }

}


export class AllCountriesRequested implements Action {

  readonly type = CountriesActionTypes.AllCountriesRequested;

}


export class AllCountriesLoaded implements Action {

  readonly type = CountriesActionTypes.AllCountriesLoaded;

  constructor(public payload: { countries: Country[] }) {

  }

}


export type CountriesActions =
  CountryRequested |
  CountryLoaded |
  AllCountriesRequested |
  AllCountriesLoaded ;

