import { Injectable } from '@angular/core';

import { withLatestFrom, mergeMap, map, filter } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { AppState } from '../reducers';
import { CountriesActionTypes, AllCountriesRequested, AllCountriesLoaded } from '../actions/countries.actions';
import { SupportService } from '../../services/support.service';

import { allCountriesLoaded } from '../selectors/countries.selectors';


@Injectable()
export class CountriesEffects {

  @Effect()
  loadAllCountries$ = this.actions$
    .pipe(
        ofType<AllCountriesRequested>(CountriesActionTypes.AllCountriesRequested),
        withLatestFrom(this.store.pipe(select(allCountriesLoaded))),
        filter(([action, allCountriesLoaded]) => !allCountriesLoaded),
        mergeMap(action => this.supportService.getCountriesList()),
        map(countries => new AllCountriesLoaded({countries}))
    );

  constructor(private actions$: Actions,
              private store: Store<AppState>,
              private supportService: SupportService) {}

}
