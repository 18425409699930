import { Component, ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

import { UserDialogComponent } from './../../../pages/account/users/user-dialog/user-dialog.component';

import { Observable, Subject } from 'rxjs';
import { map, tap, takeUntil} from 'rxjs/operators';
import { select, Store} from '@ngrx/store';
import { AppState } from '../../../common/store/reducers';
import { isLoggedIn, isLoggedOut } from '../../../common/store/selectors/auth.selectors';
import { AuthService } from '../../../core/auth.service';
import { RoomsService } from '../../../common/services/rooms.service';
import { UsersService } from '../../../common/services/users.service';
import { Logout } from '../../../common/store/actions/auth.actions';
import { TranslateService } from '@ngx-translate/core';
import { selectAllLanguages, selectCurrentLanguageCode } from '../../../common/store/selectors/languages.selectors';
import { CurrentLanguageSet } from '../../../common/store/actions/languages.actions';
import { User } from '../../../common/data_model/User';
import { Language } from '../../../common/data_model/Language';
import { AccountInfo, Company } from '../../../common/data_model/Company';
import { selectCompanyAccountInfo, selectHasWebSiteAndIsSetup } from '../../../common/store/selectors/company.selectors';
import { selectHotelUILanguages, selectSystemCurrency, selectCompany } from './../../../common/store/selectors/company.selectors';
import { loggedInUser, loggedInUserIsSystemAdmin, loggedInUserRole } from '../../../common/store/selectors/auth.selectors';


@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.css'],
})

export class TopMenuComponent implements OnInit , OnDestroy{

  public company$: Observable<Company>;
  public accountInfo$: Observable<AccountInfo>;
  public isLoggedIn$: Observable<boolean>;
  public isLoggedOut$: Observable<boolean>;
  public user$: Observable<User>;
  public userIsSystemAdmin$: Observable<boolean>;
  public uiLanguages$: Observable<Language[]>;
  public systemCurrency$: Observable<string>;
  public currentUiLanguage$: Observable<string>;
  public hasPublicWebsiteAndIsSetup$: Observable<boolean>;

  public ngUserUnsubscribe$: Subject<any> = new Subject();

  public user: User | null;

  public lang: any;

  constructor(
    private store: Store<AppState>,
    public dialog: MatDialog,
    private authService: AuthService,
    private usersService: UsersService,
    private translate: TranslateService
  ) {

    // This language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');
  }

  ngOnInit() {
    this.company$ = this.store.pipe(
      select(selectCompany)
    );

    this.uiLanguages$ = this.store.pipe(
      select(selectHotelUILanguages)
    );

    this.currentUiLanguage$ = this.store.pipe(
      select(selectCurrentLanguageCode)
    );

    this.systemCurrency$ = this.store.pipe(
      select(selectSystemCurrency)
    );

    this. accountInfo$ = this.store.pipe(
        select(selectCompanyAccountInfo)
    );

    this.user$ = this.store.pipe(
      select(loggedInUser)
    );

    this.isLoggedIn$ = this.store.pipe(
      select(isLoggedIn)
    );

    this.isLoggedOut$ = this.store.pipe(
      select(isLoggedOut)
    );


    this.userIsSystemAdmin$ = this.store.pipe(
      select(loggedInUserIsSystemAdmin)
    );

    this.hasPublicWebsiteAndIsSetup$ = this.store.pipe(
      select(selectHasWebSiteAndIsSetup)
    );

    this.store.pipe(
      select(loggedInUser),
      takeUntil(this.ngUserUnsubscribe$)
    )
      .subscribe(usr => {
        this.user = typeof usr === 'undefined' ? null : usr;
      });

  }

  openMegaMenu() {
    const pane = document.getElementsByClassName('cdk-overlay-pane');
    [].forEach.call(pane, (el) => {
      if (el.children.length > 0) {
        if (el.children[0].classList.contains('mega-menu')) {
          el.classList.add('mega-menu-pane');
        }
      }
    });
  }

  public changeLang(lang, langC: string) {
    this.lang = lang;
    this.translate.use(this.lang.code);

    this.store.dispatch(new CurrentLanguageSet({ langCode: langC }));
  }

  findCurrLanguagePos(someArray: any[], currCode: string): number {
    return someArray.findIndex(e => e.code === currCode);
  }


  public showProfile() {
    const dialogRef = this.dialog.open(UserDialogComponent, {
      width: '90%',
      height: '90%',
      data: {
        mode: 'viewMode',
        user: this.user
      }
    });
  }


  public logout() {
    this.authService.signOut();
    // this.userService.changeTheme('brown');
    this.store.dispatch(new Logout());
  }

  ngOnDestroy() {
    this.ngUserUnsubscribe$.next();
    this.ngUserUnsubscribe$.complete();
  }

}
