import { Action } from '@ngrx/store';
import { TaxFee } from '../../data_model/TaxesFees';



export enum TaxesFeesActionTypes {
  TaxFeeRequested = '[Setup] TaxFee Requested',
  TaxFeeLoaded = '[Payments API] TaxFee Loaded',
  AllTaxesFeesRequested = '[Setup] All TaxesFees Requested',
  AllTaxesFeesLoaded = '[Payments API] All TaxesFees Loaded'
}

export class TaxFeeRequested implements Action {

  readonly type = TaxesFeesActionTypes.TaxFeeRequested;

  constructor(public payload: { TaxFeeId: string }) {

  }

}


export class TaxFeeLoaded implements Action {

  readonly type = TaxesFeesActionTypes.TaxFeeLoaded;

  constructor(public payload: { taxFee: TaxFee }) {

  }
}


export class AllTaxesFeesRequested implements Action {

  readonly type = TaxesFeesActionTypes.AllTaxesFeesRequested;

  constructor(public payload: { companyId: string }) {
  }

}


export class AllTaxesFeesLoaded implements Action {

  readonly type = TaxesFeesActionTypes.AllTaxesFeesLoaded;

  constructor(public payload: { taxesFees: TaxFee[] }) {

  }

}


export type TaxesFeesActions =
  TaxFeeRequested |
  TaxFeeLoaded |
  AllTaxesFeesRequested |
  AllTaxesFeesLoaded ;

