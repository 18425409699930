import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ReservationsState } from '../reducers/reservations.reducer';
import * as fromReservations from '../reducers/reservations.reducer';

function timestampToDate(timestampObj: any): Date {
  return timestampObj.hasOwnProperty('nanoseconds') ?
    new Date(timestampObj.seconds * 1000 + timestampObj.nanoseconds / 1e6) :
    timestampObj;
}

export const selectReservationsState = createFeatureSelector<ReservationsState>('reservations');


export const selectAllReservations = createSelector (
  selectReservationsState,
  fromReservations.selectAll
);


export const selectReservationById = (reservationId: string) => createSelector(
  selectReservationsState,
  reservationsState => reservationsState.entities[reservationId]
);

export const selectReservationByIdRooms = (reservationId: string) => createSelector(
  selectReservationById(reservationId),
  reservation => reservation.Rooms
);


export const selectAllReservationsBookedOnSpecificDate = (bookedDate: Date) => createSelector (
  selectAllReservations,
  rsrvs => rsrvs.filter(rsrv =>
    new Date(timestampToDate(rsrv.Dates.Booked_date)).setHours(0, 0, 0, 0) === new Date(bookedDate).setHours(0, 0, 0, 0)
  )
);

export const selectAllReservationsArrivingOnSpecificDate = (checkInDate: Date) => createSelector (
  selectAllReservations,
  rsrvs => rsrvs.filter(rsrv =>
    new Date(timestampToDate(rsrv.Dates.CheckIn_date)).setHours(0, 0, 0, 0) === new Date(checkInDate).setHours(0, 0, 0, 0)
  )
);

export const selectAllReservationsDepartingOnSpecificDate = (checkOutDate: Date) => createSelector (
  selectAllReservations,
  rsrvs => rsrvs.filter(rsrv =>
    new Date(timestampToDate(rsrv.Dates.CheckOut_date)).setHours(0, 0, 0, 0) === new Date(checkOutDate).setHours(0, 0, 0, 0)
  )
);


export const selectAllReservationsForDateInterval = (fromDate: Date, toDate: Date) => createSelector (
  selectAllReservations,
  rsrvs => rsrvs.filter(rsrv =>
    (new Date(timestampToDate(rsrv.Dates.CheckIn_date)).setHours(0, 0, 0, 0) >= new Date(fromDate).setHours(0, 0, 0, 0))
      && (new Date(timestampToDate(rsrv.Dates.CheckIn_date)).setHours(0, 0, 0, 0) <= new Date(toDate).setHours(0, 0, 0, 0))
  )
);





