import { Action } from '@ngrx/store';
import { Currency } from '../../data_model/Currency';



export enum CurrenciesActionTypes {
  CurrencyRequested = '[Setup] Currency Requested',
  CurrencyLoaded = '[Rooms API] Currency Loaded',
  AllCurrenciesRequested = '[Setup] All Currencies Requested',
  AllCurrenciesLoaded = '[Rooms API] All Currencies Loaded'
}

export class CurrencyRequested implements Action {

  readonly type = CurrenciesActionTypes.CurrencyRequested;

  constructor(public payload: { currencyId: string }) {

  }

}


export class CurrencyLoaded implements Action {

  readonly type = CurrenciesActionTypes.CurrencyLoaded;

  constructor(public payload: { currency: Currency }) {

  }

}


export class AllCurrenciesRequested implements Action {

  readonly type = CurrenciesActionTypes.AllCurrenciesRequested;

}


export class AllCurrenciesLoaded implements Action {

  readonly type = CurrenciesActionTypes.AllCurrenciesLoaded;

  constructor(public payload: { currencies: Currency[] }) {

  }

}


export type CurrenciesActions =
  CurrencyRequested |
  CurrencyLoaded |
  AllCurrenciesRequested |
  AllCurrenciesLoaded ;

