import { UserDialogComponent } from './../pages/account/users/user-dialog/user-dialog.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule } from '@angular/common/http';
import { NgDragDropModule } from 'ng-drag-drop';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { MatStepperModule } from '@angular/material/stepper';

import { NgxSpinnerModule } from 'ngx-spinner';

import { TranslateModule } from '@ngx-translate/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true,
  suppressScrollX: true
};

import { PipesModule } from '../theme/pipes/pipes.module';
import { AgmCoreModule } from '@agm/core';

import { SharedUtilsModule } from '../common/modules/sharedutils.module';

import { RatingComponent } from './rating/rating.component';
import { ControlsComponent } from './controls/controls.component';
import { MainCarouselComponent } from './main-carousel/main-carousel.component';
import { BrandsCarouselComponent } from './brands-carousel/brands-carousel.component';
import { RoomsCarouselComponent } from './rooms-carousel/rooms-carousel.component';
import { RoomDialogComponent } from './rooms-carousel/room-dialog/room-dialog.component';
import { BannerComponent } from './banner/banner.component';
import { BannersComponent } from './banners/banners.component';
import { CategoryListComponent } from './category-list/category-list.component';
import { FileUploadComponent} from './file-upload/file-upload.component';
import { CfmDialogComponent } from './cfm-dialog/cfm-dialog.component';
import { OptionsComponent } from '../theme/components/options/options.component';
import { FooterComponent } from '../theme/components/footer/footer.component';
import { BookingFormComponent } from '../shared/booking-form/booking-form.component';
import { NewReservationComponent} from './new-reservation/new-reservation.component';
import { ViewReservationsListBriefComponent } from './view-reservations-list-brief/view-reservations-list-brief.component';
import { ReservationDialogComponent } from '../pages/auth/reservations/reservation-dialog/reservation-dialog.component';
import { ViewEditReservationMainComponent } from '../pages/auth/reservations/view-reservation/view-reservation-main/view-edit-reservation-main.component';
import { ViewEditReservationRoomsComponent } from '../pages/auth/reservations/view-reservation/view-reservation-rooms/view-edit-reservation-rooms.component';
import { ViewEditReservationComponent } from '../pages/auth/reservations/view-reservation/view-edit-reservation.component';
import {ViewEditReservationHistoryComponent} from '../pages/auth/reservations/view-reservation/view-reservation-history/view-edit-reservation-history.component';
import {ViewEditReservationNotesComponent} from '../pages/auth/reservations/view-reservation/view-reservation-notes/view-edit-reservation-notes.component';
import {MatExpansionModule} from '@angular/material/expansion';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SwiperModule,
    FlexLayoutModule,
    HttpClientModule,
    NgDragDropModule.forRoot(),
    BsDatepickerModule.forRoot(),
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    MatStepperModule,
    NgxSpinnerModule,
    PerfectScrollbarModule,
    PipesModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDe_oVpi9eRSN99G4o6TwVjJbFBNr58NxE'
    }),
    TranslateModule,
    SharedUtilsModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SwiperModule,
    FlexLayoutModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    MatStepperModule,
    NgxSpinnerModule,
    PerfectScrollbarModule,
    PipesModule,
    RatingComponent,
    ControlsComponent,
    MainCarouselComponent,
    BrandsCarouselComponent,
    RoomsCarouselComponent,
    RoomDialogComponent,
    BannersComponent,
    BannerComponent,
    CategoryListComponent,
    FileUploadComponent,
    AgmCoreModule,
    OptionsComponent,
    FooterComponent,
    BookingFormComponent,
    NewReservationComponent,
    TranslateModule,
    ViewReservationsListBriefComponent,
    ViewEditReservationComponent,
    ViewEditReservationMainComponent,
    ViewEditReservationRoomsComponent,
    ViewEditReservationNotesComponent,
    ViewEditReservationHistoryComponent

  ],
  declarations: [
    RatingComponent,
    ControlsComponent,
    MainCarouselComponent,
    BrandsCarouselComponent,
    RoomsCarouselComponent,
    RoomDialogComponent,
    BannersComponent,
    BannerComponent,
    CategoryListComponent,
    FileUploadComponent,
    CfmDialogComponent,
    UserDialogComponent,
    ReservationDialogComponent,
    OptionsComponent,
    FooterComponent,
    BookingFormComponent,
    NewReservationComponent,
    ViewReservationsListBriefComponent,
    ViewEditReservationComponent,
    ViewEditReservationMainComponent,
    ViewEditReservationRoomsComponent,
    ViewEditReservationNotesComponent,
    ViewEditReservationHistoryComponent
  ],
  entryComponents: [
    CfmDialogComponent, UserDialogComponent, RoomDialogComponent, ReservationDialogComponent
  ],
  providers: [
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG }
  ]
})
export class SharedModule { }
