import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PropertyTypesState } from '../reducers/propertytypes.reducer';
import * as fromLanguages from '../reducers/languages.reducer';
import { LanguagesState } from '../reducers/languages.reducer';

export const selectLanguagesState = createFeatureSelector<LanguagesState>('languages');


export const selectLanguageByCode = (languageCode: string) => createSelector(
  selectLanguagesState,
  languagesState => languagesState.entities[languageCode]
);


export const selectAllLanguages = createSelector(
  selectLanguagesState,
  fromLanguages.selectAll
);

export const selectCurrentLanguageCode = createSelector(
  selectLanguagesState,
  languagesState => languagesState.currentLanguagecode
);


