import { createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import { Language } from '../../data_model/Language';
import { LanguagesActionTypes, LanguagesActions } from '../actions/languages.actions';


export interface LanguagesState extends EntityState<Language> {
  currentLanguagecode: string;
}

function sortByPriority(l1: Language, l2: Language) {
  return +l1.priority - +l2.priority;
}

export const languagesAdapter: EntityAdapter<Language> = createEntityAdapter<Language>({
  sortComparer: sortByPriority
});

export const initialLanguagesState: LanguagesState = languagesAdapter.getInitialState({
  currentLanguagecode: 'en'
});

export function languagesReducer(state = initialLanguagesState, action: LanguagesActions): LanguagesState {

    switch (action.type) {

      case LanguagesActionTypes.AllLanguagesLoaded:
        return languagesAdapter.addAll(action.payload.languages, {...state, allLanguagesLoaded: undefined});

      case LanguagesActionTypes.CurrentLanguageSet:
        return { ...state, currentLanguagecode: action.payload.langCode };

      default:
        return state;
    }
}


export const {
  selectAll,
  selectIds,
  selectEntities,
  selectTotal
} = languagesAdapter.getSelectors();
