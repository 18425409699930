import { Injectable } from '@angular/core';

import { mergeMap, map, filter } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { AppState } from '../reducers';

import {AllTaxesFeesLoaded, AllTaxesFeesRequested, TaxesFeesActionTypes} from '../actions/taxesfees.actions';

import {PaymentService} from '../../services/payment.service';


@Injectable()
export class TaxesFeesEffects {

  @Effect()
  loadAllTaxesFees$ = this.actions$
    .pipe(
        ofType<AllTaxesFeesRequested>(TaxesFeesActionTypes.AllTaxesFeesRequested),
        mergeMap(action => this.paymentService.getTaxesFeesForCompany(action.payload.companyId)),
        map(taxesFees => new AllTaxesFeesLoaded({taxesFees}))
    );

  constructor(private actions$: Actions,
              private store: Store<AppState>,
              private paymentService: PaymentService) {}

}
