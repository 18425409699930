import { Injectable } from '@angular/core';

import { withLatestFrom, mergeMap, map, filter } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { AppState } from '../reducers';
import { CurrenciesActionTypes, AllCurrenciesRequested, AllCurrenciesLoaded } from '../actions/currencies.actions';
import { SupportService } from '../../services/support.service';

import { allCurrenciesLoaded } from '../selectors/currencies.selectors';


@Injectable()
export class CurrenciesEffects {

  @Effect()
  loadAllCurrencies$ = this.actions$
    .pipe(
        ofType<AllCurrenciesRequested>(CurrenciesActionTypes.AllCurrenciesRequested),
        withLatestFrom(this.store.pipe(select(allCurrenciesLoaded))),
        filter(([action, allCurrenciesLoaded]) => !allCurrenciesLoaded),
        mergeMap(action => this.supportService.getCurrenciesList()),
        map(currencies => new AllCurrenciesLoaded({currencies}))
    );

  constructor(private actions$: Actions,
              private store: Store<AppState>,
              private supportService: SupportService) {}

}
