import { ProductFeature } from './../data_model/ProductFeature';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { FirestoreService } from '../../core/firestore.service';
import { Country } from '../data_model/Country';
import { Currency } from '../data_model/Currency';
import { Role } from '../data_model/Role';
import {BookingSource, BookingSourceSubCategory} from '../data_model/BookingSources';



@Injectable({
  providedIn: 'root'
})
export class SupportService {

  constructor(
    private _fsService: FirestoreService
  ) { }


  public getCountriesList(): Observable<Country[]> {
    return this._fsService.colWithIds$<Country[]>('Countries');
  }

  public getCurrenciesList(): Observable<Currency[]> {
    return this._fsService.colWithIds$<Currency[]>('Currencies');
  }


  public getRolesList(): Observable<Role[]> {
    return this._fsService.colWithIds$<Role[]>('Roles');
  }

  public getProductFeatures(currLanguageCode: string): Observable<ProductFeature[]> {
    return this._fsService.colWithIds$<ProductFeature[]>('Product_Features',
      ref => ref
        .where('Features', 'array-contains', currLanguageCode));
  }

  public getBookingSourcesList(): Observable<BookingSource[]> {
    return this._fsService.colWithIds$<BookingSource[]>('Booking_Sources');
  }

  public getBookingSourcesSubCategoriesForCategoryCode(categoryCode: string): Observable<BookingSourceSubCategory[]> {
    return this._fsService.colWithIds$<BookingSourceSubCategory[]>('Booking_Sources_Sub_Categories', ref => ref
      .where('BSCode', '==', categoryCode)
    );
  }

  public getBookingSourcesSubCategoriesList(): Observable<BookingSourceSubCategory[]> {
    return this._fsService.colWithIds$<BookingSourceSubCategory[]>('Booking_Sources_Sub_Categories');
  }

}

