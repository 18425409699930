import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProductsServicesState } from '../reducers/productsservices.reducer';
import * as fromProductsServices from '../reducers/productsservices.reducer';
import {filter} from 'rxjs/operators';



export const selectProductsServicesState = createFeatureSelector<ProductsServicesState>('productsServices');


export const selectProductServiceById = (productServiceId: string) => createSelector(
  selectProductsServicesState,
  productsServicesState => productsServicesState.entities[productServiceId]
);


export const selectAllProductsServices = createSelector(
  selectProductsServicesState,
  fromProductsServices.selectAll
);


export const allProductsServicesLoaded = createSelector(
  selectProductsServicesState,
  productsServicesState => productsServicesState.allProductsServicesLoaded
);


