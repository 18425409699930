import { Component, OnChanges, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { SidenavMenuService } from './sidenav-menu.service';

@Component({
  selector: 'app-sidenav-menu',
  templateUrl: './sidenav-menu.component.html',
  styleUrls: ['./sidenav-menu.component.scss'],
  providers: [ SidenavMenuService ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SidenavMenuComponent implements OnChanges {
  @Input() menuItems;
  @Input() menuParentId;
  parentMenu: Array<any>;

  constructor(private sidenavMenuService: SidenavMenuService ) { }

  ngOnChanges() {
    this.parentMenu = this.menuItems.filter(item => item.parentId === this.menuParentId);
  }

  onClick(menuId) {
    this.sidenavMenuService.toggleMenuItem(menuId);
    this.sidenavMenuService.closeOtherSubMenus(this.menuItems, menuId);
  }

}
