import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TaxesFeesState } from '../reducers/taxesfees.reducer';
import * as fromTaxesFees from '../reducers/taxesfees.reducer';


export const selectTaxesFeesState = createFeatureSelector<TaxesFeesState>('taxesFees');


export const selectTaxFeeById = (taxFeeId: string) => createSelector(
  selectTaxesFeesState,
  taxesFeesState => taxesFeesState.entities[taxFeeId]
);


export const selectAllTaxesFees = createSelector(
  selectTaxesFeesState,
  fromTaxesFees.selectAll
);

export const allTaxesFeesLoaded = createSelector(
  selectTaxesFeesState,
  taxesFeesState => taxesFeesState.allTaxesFeesLoaded
);


