import { selectAllHotelRoomTypes } from './../store/selectors/hotelroomtypes.selectors';
import { AppService } from './../../app.service';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import {map, filter, tap, switchMap, take} from 'rxjs/operators';
import { BehaviorSubject, noop, of, combineLatest} from 'rxjs';

import { AngularFirestore } from '@angular/fire/firestore';
import { FirestoreService } from '../../core/firestore.service';

import { HotelRoomType, RatePlan } from '../data_model/HotelRoomType';
import { Interval, Inventory, OperationPeriod } from '../data_model/Inventory';
import {RoomsService} from './rooms.service';
import { Store, select } from '@ngrx/store';
import { AppState } from '../store/reducers';


@Injectable()
export class InventoryService {

  private ratePlanInventory: Inventory;

  constructor(
    private _fsSrvc: FirestoreService,
    private db: AngularFirestore,
    private appService: AppService,
    private roomsService: RoomsService,
    private store: Store<AppState>) {
  }


  public getAllHotelRoomTypesForCompany(companyId: string): Observable<HotelRoomType[]> {
    return this._fsSrvc.colWithIds$<HotelRoomType[]>('Company/' + companyId + '/Room_Types/')
      .pipe(
        tap(hrT => console.log(hrT))
      );
  }

  public getAllRatePlansForCompany(companyId: string): Observable<RatePlan[]> {
    return this._fsSrvc.colWithIds$<HotelRoomType[]>('Company/' + companyId + '/Room_Types/')
      .pipe(
        map((res, idx) => res[idx].Rate_plans)
      );
  }

  public getAllRatePlansForCompanyExceptOfRoomType(companyId: string, rtId: string): Observable<RatePlan[]> {
    return this._fsSrvc.colWithIds$<RatePlan[]>('Company/' + companyId + '/Room_Types/')
      .pipe(
        filter((resHrt, idxHrt) => resHrt[idxHrt].id !== rtId),
        map((resRP, idxRP) => resRP[idxRP].Rate_plans),
      );
  }

  public getOperationPeriodsList(companyId: string): Observable<OperationPeriod[]> {
    return this._fsSrvc.colWithIds$<OperationPeriod[]>('Company/' + companyId + '/Operation_Periods');
  }

  public getOperationPeriodsforDateRange(companyId: string, fromD: Date, toD: Date): Observable<OperationPeriod> {
    return this._fsSrvc.colWithIds$<OperationPeriod>('Company/' + companyId + '/Operation_Periods', ref => ref
      .where('From_date', '<=', fromD)
      .orderBy('From_date')
    )
      .pipe(
       // filter((res, idx) => res[idx].To_date <= toD),
        map(ops => ops = ops[0])
      );
  }

  public InventoryUpdate( companyId: string, opPeriodId: string, interval: Interval): boolean {

    let allTasksOk;
    let allPeriodsOk = true;
    let allRatePlansOk = true;

    let hotelRoomTypesArr = [];

    this.store.pipe(
      select(selectAllHotelRoomTypes),
      take(1)
    )
    .subscribe(hrt => {
      hotelRoomTypesArr = hrt;
    });

    // Prepare exclusion periods array
    const exclPeriodsArray = [];

    interval.Exclusion_periods.forEach(excPeriod => {
      const fromExclDate = this.appService.timestampToDate(excPeriod.Exclude_from_date);
      const toExclDate = this.appService.timestampToDate(excPeriod.Exclude_to_date);

      // eslint-disable-next-line max-len
      if (fromExclDate.toString() !== '' && toExclDate.toString() !== '') {
        // eslint-disable-next-line max-len
        const nbrOfExclDays = Math.floor((Date.UTC(toExclDate.getFullYear(), toExclDate.getMonth(), toExclDate.getDate()) - Date.UTC(fromExclDate.getFullYear(), fromExclDate.getMonth(), fromExclDate.getDate())) / (1000 * 60 * 60 * 24));

        const currExclDate = fromExclDate;
        currExclDate.setDate(currExclDate.getDate() - 1);

        for (let j = 0; j <= nbrOfExclDays; j++) {

          currExclDate.setDate(currExclDate.getDate() + 1);

          // Check if the day of this date is within the allowed stay days
          const currExclDateDay = currExclDate.getDay();

          let currExclDateisInExcludeStayDays;

          switch (currExclDateDay) {
            case 0:
              currExclDateisInExcludeStayDays = excPeriod.Exclude_stay_days.Sun;
              break;

            case 1:
              currExclDateisInExcludeStayDays = excPeriod.Exclude_stay_days.Mon;
              break;

            case 2:
              currExclDateisInExcludeStayDays = excPeriod.Exclude_stay_days.Tue;
              break;

            case 3:
              currExclDateisInExcludeStayDays = excPeriod.Exclude_stay_days.Wed;
              break;

            case 4:
              currExclDateisInExcludeStayDays = excPeriod.Exclude_stay_days.Thu;
              break;

            case 5:
              currExclDateisInExcludeStayDays = excPeriod.Exclude_stay_days.Fri;
              break;

            case 6:
              currExclDateisInExcludeStayDays = excPeriod.Exclude_stay_days.Sat;
              break;
          }

          if (currExclDateisInExcludeStayDays) {
            currExclDate.setHours(0, 0, 0, 0);
            exclPeriodsArray.push(currExclDate.toDateString());
          }
        }
      }
    });


    interval.Applicable_periods.forEach((applPeriod, applPerIdx) => {
      const fromApplDate = this.appService.timestampToDate(applPeriod.From_date);
      const toApplDate = this.appService.timestampToDate(applPeriod.To_date);

      // eslint-disable-next-line max-len
      const nbrOfDays = Math.floor((Date.UTC(toApplDate.getFullYear(), toApplDate.getMonth(), toApplDate.getDate()) - Date.UTC(fromApplDate.getFullYear(), fromApplDate.getMonth(), fromApplDate.getDate()) ) / (1000 * 60 * 60 * 24));

      const currDate = fromApplDate;
      currDate.setDate(currDate.getDate() - 1);

      for (let i = 0; i <= nbrOfDays; i++) {

        currDate.setDate(currDate.getDate() + 1);
        currDate.setHours(0, 0, 0, 0);

        // Check if the day of this date is within the allowed stay days
        const currDateDay = currDate.getDay();

        let currDateisInStayDays;

        switch (currDateDay) {
          case 0:
            currDateisInStayDays = applPeriod.Stay_days.Sun;
            break;

          case 1:
            currDateisInStayDays = applPeriod.Stay_days.Mon;
            break;

          case 2:
            currDateisInStayDays = applPeriod.Stay_days.Tue;
            break;

          case 3:
            currDateisInStayDays = applPeriod.Stay_days.Wed;
            break;

          case 4:
            currDateisInStayDays = applPeriod.Stay_days.Thu;
            break;

          case 5:
            currDateisInStayDays = applPeriod.Stay_days.Fri;
            break;

          case 6:
            currDateisInStayDays = applPeriod.Stay_days.Sat;
            break;
        }

        // Check if this date does not exist in the exclusion periods Array
        const currDateNotInExclusionPeriods = !exclPeriodsArray.includes(currDate.toDateString());

        if (currDateisInStayDays && currDateNotInExclusionPeriods) {

          // Calculate the different Roomm Types and their Availability

          // eslint-disable-next-line no-var
          var roomsAvailability = [];
          let roomsAvailabilityArrIdex = 0;

          interval.Applicable_rate_plans.forEach(rP => {
            const rT = rP.Hotel_Room_Type;

            const alreadyInArr = (roomsAvailability.length > 1) && (roomsAvailability.findIndex(e => e.Room_type === rT) > -1);

            if (!alreadyInArr) {
              const hrtArrPos = hotelRoomTypesArr.findIndex(e => e.Type === rT);
              roomsAvailability[roomsAvailabilityArrIdex] = ({
                Room_type: rT,
                Available: hotelRoomTypesArr[hrtArrPos].Units
              });
              roomsAvailabilityArrIdex++;
            }
          });

          const intervalInventory = {
            Applicable_date: currDate,
            Rooms_availability: roomsAvailability
          };

          const dbpath = 'Company/' + companyId + '/Operation_Periods/' + opPeriodId + '/Inventory';
          this._fsSrvc.newDocRef(dbpath, intervalInventory)
            .then(ref => {
              const newDbPath = dbpath + '/' + ref.id + '/Rate_Plans';

              interval.Applicable_rate_plans.forEach(ratePlan => {
                this._fsSrvc.add(newDbPath, ratePlan)
                  .then(res => noop(),
                    (error) => allRatePlansOk = false
                  );
              });
            },
              (err) => allPeriodsOk = false
            );
        }
      }
    });

    setTimeout(() => {
      allTasksOk = allPeriodsOk && allRatePlansOk;
    }, 3000);

    return allTasksOk;
  }

  private checkAllProcessed (currentIdx: number, TotalNbr: number): boolean {
    return currentIdx >= TotalNbr;
  }

  public getAllIntervalsForOpPeriod(companyId: string, opPeriodId: string): Observable<Interval[]> {
    return this._fsSrvc.colWithIds$<Interval[]>('Company/' + companyId + '/Operation_Periods/' + opPeriodId + '/Intervals');
  }


  public getInventoryForCalendarPeriod(companyId: string, opPeriodId: string, fromDate: Date, toDate: Date): Observable<Inventory[]> {

      const fmDate = new Date(fromDate);
      // fmDate.setDate(fmDate.getDate() - 1);

      const tDate = new Date(toDate);
      // tDate.setDate(tDate.getDate() - 1);

      return this._fsSrvc
        .colWithIds$<Inventory[]>(`Company/${companyId}/Operation_Periods/${opPeriodId}/Inventory`, ref => ref
          .where('Applicable_date', '>=', fmDate )
          .where( 'Applicable_date', '<', tDate )
          .orderBy('Applicable_date')
        )
        .pipe(
          switchMap(inventoryDates => {
            const res = inventoryDates.map(invDay => {
              return this._fsSrvc
                .colWithIds$<RatePlan[]>(`Company/${companyId}/Operation_Periods/${opPeriodId}/Inventory/${invDay.id}/Rate_Plans`)
                .pipe(
                  map(ratePlans => Object.assign(invDay, {Rate_plans: ratePlans}))
                );
            });
            return combineLatest(res);
          })
        );
  }



  findCurrRatePlanPos(someArray: any[], val: string): number {
    return someArray.findIndex(e => e.Code === val);
  }

  findCurrRoomTypePos(someArray: any[], val: string): number {
    return someArray.findIndex(e => e.Room_type === val);
  }


  // eslint-disable-next-line max-len
  public UpdateInventoryAvailability(mode: string, companyId: string, opPeriodId: string, inventoryArr: Inventory[], roomTypeId: string, ratePlanCode: string) {
    const baseUrl = 'Company/' + companyId + '/Operation_Periods/' + opPeriodId + '/Inventory/';

    inventoryArr.forEach(invDay => {
      const roomTypePos = this.findCurrRoomTypePos(invDay.Rooms_availability, roomTypeId);

      const ratePlanPos = this.findCurrRatePlanPos(invDay.Rate_plans, ratePlanCode);

      const inventoryId = invDay.id;

      const ratePlanToUpdateId = invDay.Rate_plans[ratePlanPos].id;

      this.db.firestore.runTransaction(async transaction => {
        const inventoryDayRef = this.db.doc(baseUrl + inventoryId).ref;

        const snap = await transaction.get(inventoryDayRef);

        const inventoryDay = snap.data() as Inventory;

        const inventoryDayRoomsAvailability = inventoryDay.Rooms_availability;

        inventoryDayRoomsAvailability[roomTypePos].Available =
          mode === 'Remove' ? inventoryDayRoomsAvailability[roomTypePos].Available - 1
                            : inventoryDayRoomsAvailability[roomTypePos].Available + 1 ;

        transaction.update(inventoryDayRef, { Rooms_availability: inventoryDayRoomsAvailability });

        return inventoryDayRoomsAvailability;

      });

      this.db.firestore.runTransaction(async transaction => {
        const ratePlanRef = this.db.doc(baseUrl + inventoryId + '/Rate_Plans/' + ratePlanToUpdateId).ref;

        const snap = await transaction.get(ratePlanRef);

        const ratePlan = snap.data() as RatePlan;

        const ratePlanAvail =
          mode === 'Remove' ? ratePlan.Alloted_for_sale = ratePlan.Alloted_for_sale - 1
            : ratePlan.Alloted_for_sale = ratePlan.Alloted_for_sale + 1;

        transaction.update(ratePlanRef, { Alloted_for_sale: ratePlanAvail });

        return ratePlanAvail;

      });
    });
  }


}



