import {Component, ViewEncapsulation, OnInit, Inject, AfterViewInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { RoomsService } from '../../../common/services/rooms.service';
import { Room } from '../../../common/data_model/Room';
import { HotelRoomType} from '../../../common/data_model/HotelRoomType';
import { Observable } from 'rxjs';
import { Language } from '../../../common/data_model/Language';
import {select, Store} from '@ngrx/store';
import {selectHotelUILanguages} from '../../../common/store/selectors/company.selectors';
import {AppState} from '../../../common/store/reducers';
import {selectCurrentLanguageCode} from '../../../common/store/selectors/languages.selectors';

@Component({
  selector: 'app-room-dialog',
  templateUrl: './room-dialog.component.html',
  styleUrls: ['./room-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RoomDialogComponent implements OnInit, AfterViewInit {
  public currentlanguage$: Observable<string>;
  public config: SwiperConfigInterface = {};
  constructor(public store: Store<AppState>,
              public roomsService: RoomsService,
              public dialogRef: MatDialogRef<RoomDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public hotelRoomType: HotelRoomType) { }

  ngOnInit() {
    this.currentlanguage$ = this.store
      .pipe(
        select(selectCurrentLanguageCode)
      );
  }

  ngAfterViewInit() {
    this.config = {
      slidesPerView: 1,
      spaceBetween: 0,
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,
      loop: false,
      preloadImages: false,
      lazy: true,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      }
    };
  }

  findCurrLanguagePos(someArray: any[], langCode: string): number {
    return someArray.findIndex(e => e.lang === langCode);
  }

  public close(): void {
    this.dialogRef.close();
  }
}


