import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AmenitiesState } from '../reducers/amenities.reducer';
import * as fromAmenities from '../reducers/amenities.reducer';

export const selectAmenitiesState = createFeatureSelector<AmenitiesState>('amenities');


export const selectAmenityById = (amenityId: string) => createSelector(
  selectAmenitiesState,
  amenitiesState => amenitiesState.entities[amenityId]
);


export const selectAllAmenities = createSelector(
  selectAmenitiesState,
  fromAmenities.selectAll
);

export const allAmenitiesLoaded = createSelector(
  selectAmenitiesState,
  amenitiesState => amenitiesState.allAmenitiesLoaded
);


