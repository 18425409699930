import { Action } from '@ngrx/store';
import { ProductService } from '../../data_model/OtherProductsNServices';



export enum ProductsServicesActionTypes {
  ProductServiceRequested = '[Setup] ProductService Requested',
  ProductServiceLoaded = '[Payments API] ProductService Loaded',
  AllProductsServicesRequested = '[Setup] All ProductsServices Requested',
  AllProductsServicesLoaded = '[Payments API] All ProductsServices Loaded'
}

export class ProductServiceRequested implements Action {

  readonly type = ProductsServicesActionTypes.ProductServiceRequested;

  constructor(public payload: { producServiceId: string }) {

  }

}


export class ProductServiceLoaded implements Action {

  readonly type = ProductsServicesActionTypes.ProductServiceLoaded;

  constructor(public payload: { productService: ProductService }) {

  }
}


export class AllProductsServicesRequested implements Action {

  readonly type = ProductsServicesActionTypes.AllProductsServicesRequested;

  constructor(public payload: { companyId: string }) {
  }

}


export class AllProductsServicesLoaded implements Action {

  readonly type = ProductsServicesActionTypes.AllProductsServicesLoaded;

  constructor(public payload: { productsServices: ProductService[] }) {

  }

}

export class AllOtherProductsServicesLoaded implements Action {

  readonly type = ProductsServicesActionTypes.AllProductsServicesLoaded;

  constructor(public payload: { productsServices: ProductService[] }) {

  }

}


export type ProductsServicesActions =
  ProductServiceRequested |
  ProductServiceLoaded |
  AllProductsServicesRequested |
  AllProductsServicesLoaded ;

