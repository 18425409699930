import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InvalidlogindialogComponent } from '../../pages/sign-in/invalidlogindialog.component';

@Injectable()
export class DialogService {

  constructor(public dialog: MatDialog) { }

  dialogResult = '';

  openDialog(msg: string) {
    const dialogRef = this.dialog.open(InvalidlogindialogComponent, {
      width: '400px',
      data: msg
    });

    dialogRef.afterClosed().subscribe(result => {
      this.dialogResult = result;
    });
  }

}
