import { createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {ReservationsActions, ReservationsActionTypes} from '../actions/reservations.actions';
import {Reservation} from '../../data_model/Reservation';


export interface ReservationsState extends EntityState<Reservation> {
}

function sortById(r1: Reservation, r2: Reservation) {
  return r1.id.localeCompare(r2.id);
}

export const reservationsAdapter: EntityAdapter<Reservation> = createEntityAdapter<Reservation>({
  sortComparer: sortById
});

export const initialReservationsState: ReservationsState = reservationsAdapter.getInitialState();

export function reservationsReducer( state = initialReservationsState, action: ReservationsActions): ReservationsState {

  switch (action.type) {

    case ReservationsActionTypes.ReservationLoaded:
      return reservationsAdapter.addOne(action.payload.reservation, state);

    case ReservationsActionTypes.ReservationsLoaded:
      return reservationsAdapter.addAll(action.payload.reservations, state);

    case ReservationsActionTypes.ReservationSaved:
      return reservationsAdapter.updateOne(action.payload.reservation, state);

    default:
      return state;
  }
}


export const {
  selectAll,
  selectIds,
  selectEntities,
  selectTotal
} = reservationsAdapter.getSelectors();
