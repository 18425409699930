import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  AfterViewInit,
  OnDestroy,
} from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { Settings, AppSettings } from "./app.settings";
import { AppState } from "./common/store/reducers";
import { Store } from "@ngrx/store";
import { CompanyService } from "./common/services/company.service";
import { Subject } from "rxjs/Subject";
import { take, takeUntil, map } from "rxjs/operators";
import { Company } from "./common/data_model/Company";
import { AllCurrenciesRequested } from "./common/store/actions/currencies.actions";
import { AllLanguagesRequested } from "./common/store/actions/languages.actions";
import { AllCountriesRequested } from "./common/store/actions/countries.actions";
import { SetCompanyAction } from "./common/store/actions/company.actions";
import { Logout } from "./common/store/actions/auth.actions";
import { IdsRequested } from "./common/store/actions/ids.actions";
import { HotelRoomTypesRequested } from "./common/store/actions/hotelroomtypes.actions";
import { AllOperationPeriodsRequested } from "./common/store/actions/operationperiods.actions";
import { AllTaxesFeesRequested } from "./common/store/actions/taxesfees.actions";
import { AllProductsServicesRequested } from "./common/store/actions/productsservices.actions";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  loading = false;
  public settings: Settings;
  private urlCompanyParam: string;
  private hostUrl: string;
  public company: Company;
  private compId: string;
  private posCompanyFound = -1;
  private paramName: string;
  private paramValue: any;
  private initialNavigationUrl: string;
  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(
    private appSettings: AppSettings,
    private router: Router,
    private store: Store<AppState>,
    private companyService: CompanyService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.settings = this.appSettings.settings;
    this.matIconRegistry.addSvgIcon(
      "hotel-hair-dryer",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/amenities/hotel-hair-dryer.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "hotel-iron",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/amenities/hotel-iron.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "hotel-air-conditioning",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/amenities/hotel-air-conditioning.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "hotel-shower",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/amenities/hotel-shower.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "hotel-coffee",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/amenities/hotel-coffee.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "hotel-dumbbell",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/amenities/hotel-dumbbell.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "hotel-spa",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/amenities/hotel-spa.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "hotel-safebox",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/amenities/hotel-safebox.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "hotel-restaurant",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/amenities/hotel-restaurant.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "hotel-bathtub",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/amenities/hotel-bathtub.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "hotel-room-service",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/amenities/hotel-room-service.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "hotel-soap",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/amenities/hotel-soap.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "hotel-swimming-pool",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/amenities/hotel-swimming-pool.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "hotel-maid",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/amenities/hotel-maid.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "hotel-taxi",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/amenities/hotel-taxi.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "hotel-no-smoking",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/amenities/hotel-no-smoking.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "hotel-phone",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/amenities/hotel-phone.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "hotel-television",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/amenities/hotel-television.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "hotel-wifi",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/amenities/hotel-wifi.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "hotel-door",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/amenities/hotel-door.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "hotel-receptionist",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/amenities/hotel-receptionist.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "hotel-towel",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/amenities/hotel-towel.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "hotel-minibar",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/amenities/hotel-minibar.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "hotel-lamp",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/amenities/hotel-lamp.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "hotel-lotions",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/amenities/hotel-lotions.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "hotel-parking-sign",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/amenities/hotel-parking-sign.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "hotel-bed-single",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/amenities/hotel-bed-single.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "hotel-bed-tween",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/amenities/hotel-bed-tween.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "hotel-bed-double",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/amenities/hotel-bed-double.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "hotel-bed-queen",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/amenities/hotel-bed-queen.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "hotel-bed-king",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/amenities/hotel-bed-king.svg"
      )
    );
  }

  ngOnInit() {
    this.checkSubscrOk();
  }

  ngAfterViewInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });
  }

  private checkSubscrOk() {
    // this.hostUrl = window.location.hostname;
    // this.urlCompanyParam = this.getAllUrlParams(window.location.href);
    // const the_Url = (window.location !== window.parent.location) ? document.referrer : document.location.href;

    this.hostUrl =
      window.location !== window.parent.location
        ? document.referrer
        : document.location.href;
    this.urlCompanyParam = this.getAllUrlParams(this.hostUrl);

    if (
      !this.hostUrl.includes("localhost:") &&
      !this.hostUrl.includes("hotac.wadportal.webapplicationsdevelopment.biz")
    ) {
      this.companyService
        .getAllCompanies()
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe((comps) => {
          if (this.findcompanyByUrl(comps, this.hostUrl)) {
            this.company = comps[this.posCompanyFound];
            document.title = this.company.Trade_Name;
            this.store.dispatch(
              new SetCompanyAction({ company: this.company })
            );
            this.store.dispatch(new AllLanguagesRequested());
            this.store.dispatch(new IdsRequested({ idId: "1" }));
            this.store.dispatch(new AllCurrenciesRequested());
            this.store.dispatch(new AllCountriesRequested());
            this.store.dispatch(
              new HotelRoomTypesRequested({ companyId: this.company.id })
            );
            this.store.dispatch(
              new AllOperationPeriodsRequested({ companyId: this.company.id })
            );
            this.store.dispatch(
              new AllTaxesFeesRequested({ companyId: this.company.id })
            );
            this.store.dispatch(
              new AllProductsServicesRequested({ companyId: this.company.id })
            );
            this.checkIfHasPublicWebsiteAndHasBeenSetUp(this.company);
          } else {
            this.router.navigate(["/sign-in"]);
          }
        });
    } else {
      this.companyService
        .getCompanyById(this.urlCompanyParam)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe((company) => {
          if (typeof company !== "undefined") {
            this.store.dispatch(new SetCompanyAction({ company }));
            this.store.dispatch(new AllLanguagesRequested());
            this.store.dispatch(new IdsRequested({ idId: "1" }));
            this.store.dispatch(new AllCurrenciesRequested());
            this.store.dispatch(new AllCountriesRequested());
            this.store.dispatch(
              new HotelRoomTypesRequested({ companyId: company.id })
            );
            this.store.dispatch(
              new AllOperationPeriodsRequested({ companyId: company.id })
            );
            this.store.dispatch(
              new AllTaxesFeesRequested({ companyId: company.id })
            );
            this.store.dispatch(
              new AllProductsServicesRequested({ companyId: company.id })
            );
            this.checkIfHasPublicWebsiteAndHasBeenSetUp(company);
          } else {
            this.router.navigate(["/sign-in"]);
          }
        });
    }
    this.router.navigate([""]); // redirect other pages to homepage on browser refresh
  }

  findcompanyByUrl(compArray: Array<Company>, compUrl: string): boolean {
    console.log(compArray, compUrl);
    let found = false;
    compArray.forEach((compny, idx) => {
      const regUrl = compny.website_url;
      const regUrl_httpPos = regUrl.indexOf("http://");
      const regUrl_httpsPos = regUrl.indexOf("https://");
      const regUrl_wwwPos = regUrl.indexOf("www");
      const regUrl_has_http = regUrl_httpPos !== -1;
      const regUrl_has_https = regUrl_httpsPos !== -1;
      const regUrl_has_www = regUrl_wwwPos !== -1;

      let regUrl_startPos;

      if (regUrl_has_www) {
        regUrl_startPos = regUrl_wwwPos + 4;
      } else {
        if (regUrl_has_http || regUrl_has_https) {
          regUrl_startPos = regUrl_has_https ? 8 : 7;
        } else {
          regUrl_startPos = 0;
        }
      }

      const registeredUrl =
        regUrl.substring(regUrl_startPos).indexOf("/") === -1
          ? regUrl.substring(regUrl_startPos)
          : regUrl
              .substring(regUrl_startPos)
              .substring(0, regUrl.substring(regUrl_startPos).length - 1);

      // ------------------------------------------

      const arrUrl = compUrl;
      const arrUrl_httpPos = arrUrl.indexOf("http://");
      const arrUrl_httpsPos = arrUrl.indexOf("https://");
      const arrUrl_wwwPos = arrUrl.indexOf("www");
      const arrUrl_has_http = arrUrl_httpPos !== -1;
      const arrUrl_has_https = arrUrl_httpsPos !== -1;
      const arrUrl_has_www = arrUrl_wwwPos !== -1;

      let arrUrl_startPos;

      if (arrUrl_has_www) {
        arrUrl_startPos = arrUrl_wwwPos + 4;
      } else {
        if (arrUrl_has_http || arrUrl_has_https) {
          arrUrl_startPos = arrUrl_has_https ? 8 : 7;
        } else {
          arrUrl_startPos = 0;
        }
      }

      const arrivedUrl =
        arrUrl.substring(arrUrl_startPos).indexOf("/") === -1
          ? arrUrl.substring(arrUrl_startPos)
          : arrUrl
              .substring(arrUrl_startPos)
              .substring(0, arrUrl.substring(arrUrl_startPos).length - 1);

      console.log("Registered Url = " + registeredUrl);
      console.log("Arrived Url = " + arrivedUrl);
      console.log(
        "Urls " + registeredUrl === arrivedUrl ? "do" : "do not" + " match"
      );

      if (registeredUrl === arrivedUrl) {
        this.posCompanyFound = idx;
        found = true;
      }
    });
    return found;
  }

  checkIfHasPublicWebsiteAndHasBeenSetUp(comp: Company) {
    this.initialNavigationUrl =
      comp.Account_Info.Has_Public_Website && comp.Setup.step1.profile
        ? ""
        : "/comingsoon";
    this.router.navigate([this.initialNavigationUrl]);
  }

  getAllUrlParams(url: string): string {
    // get query string from url (optional) or window
    let queryString = url ? url.split("?")[1] : window.location.search.slice(1);

    // we'll store the parameters here
    this.compId = "0";

    // if query string exists
    if (queryString) {
      // stuff after # is not part of query string, so get rid of it
      queryString = queryString.split("#")[0];

      // split our query string into its component parts
      const arr = queryString.split("&");

      for (let i = 0; i < arr.length; i++) {
        // separate the keys and the values
        const a = arr[i].split("=");

        // in case params look like: list[]=thing1&list[]=thing2
        this.paramName = a[0].replace(/\[\d*\]/, function (v) {
          const paramNum = v.slice(1, -1);
          return "";
        });

        // set parameter value (use 'true' if empty)
        this.paramValue = typeof a[1] === "undefined" ? true : a[1];

        // (optional) keep case consistent
        this.paramName = this.paramName.toLowerCase();
        // this.paramValue = this.paramValue.toLowerCase();

        if (this.paramName === "companyid") {
          this.compId = this.paramValue;
        }
      }
    }

    return this.compId;
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
